import Resource from '@/api/resource'

export enum AppConfigEnum {
  LANGUAGE_SUPPORT = 'language_support',
  APP_VERSION = 'app_version',
  REPORTS = 'reports',
}

export interface IDynamicConfigs {
  id: string
  name: AppConfigEnum
  value: string
  createdAt: Date
  updatedAt: Date
  companyId: string
}

class DynamicConfigsResource extends Resource {
  constructor() {
    super('dynamic-app-configs')
  }
}

export { DynamicConfigsResource as default }
