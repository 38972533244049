import Resource from '@/api/resource'
import request from '@/utils/request'
import { IntegrationTypeEnum } from '@/views/recruiter/models/integration-type.enum'
import { CalendarEventCreateOrUpdateResponseDto, CreateEventCalendarDto } from '@/views/interview-scheduling/models'

class CalendarResource extends Resource {
  constructor() {
    super('calendar')
  }

  getBookingLinksForCompany(query) {
    return request({
      url: '/' + this.uri + '/booking-link?' + query,
      method: 'GET'
    })
  }

  getEventsByUser() {
    return request({
      url: '/' + this.uri + '/bookings',
      method: 'GET'
    })
  }

  createBookingLink(payload) {
    return request({
      url: '/' + this.uri + '/booking-link',
      method: 'post',
      data: payload
    })
  }

  getCalendarIntegrationAuthURL(userId: string, integrationType: IntegrationTypeEnum) {
    return request({
      url: '/' + this.uri + `/integration/calendar/add/${userId}/${integrationType}`,
      method: 'get'
    })
  }

  deleteBookingLink(id: number) {
    return request({
      url: '/' + this.uri + '/booking-link/' + id,
      method: 'delete'
    })
  }

  updateBookingLink(id, payload) {
    return request({
      url: '/' + this.uri + '/booking-link/' + id,
      method: 'patch',
      data: payload
    })
  }

  calendarIntegrationCallback(userId: string, query: string, integrationType: IntegrationTypeEnum) {
    return request({
      url: '/' + this.uri + `/integration/calendar/callback/${userId}/${integrationType}${query}`,
      method: 'post'
    })
  }

  getScheduleById(id: number) {
    return request({
      url: '/' + this.uri + '/schedule/' + id,
      method: 'get'
    })
  }

  cancelBooking(body) {
    return request({
      url: '/' + this.uri + '/booking/cancel/' + body.id,
      method: 'post',
      data: body
    })
  }

  getAllCalendarEvents(userId, startDate, endDate) {
    return request({
      url: '/' + this.uri + '/interview-scheduling/list?userId=' + userId + '&startDate=' + startDate + '&endDate=' + endDate,
      method: 'get'
    })
  }

  createCalendarEvent(userId: string, applicationId: string, isOrganizerPartOfInterview: boolean, payload: CreateEventCalendarDto): Promise<CalendarEventCreateOrUpdateResponseDto> {
    return request({
      url: '/' + this.uri + '/interview-scheduling/create?userId=' + userId + '&applicationId=' + applicationId + '&isOrganizerPartOfInterview=' + isOrganizerPartOfInterview,
      data: payload,
      method: 'post'
    }).then((response) => response.data as CalendarEventCreateOrUpdateResponseDto)
  }

  updateCalendarEvent(userId: string, eventId: string, isOrganizerPartOfInterview: boolean, payload: CreateEventCalendarDto): Promise<CalendarEventCreateOrUpdateResponseDto> {
    return request({
      url: '/' + this.uri + '/interview-scheduling/update?userId=' + userId + '&eventId=' + eventId + '&isOrganizerPartOfInterview=' + isOrganizerPartOfInterview,
      data: payload,
      method: 'put'
    }).then((response) => response.data as CalendarEventCreateOrUpdateResponseDto)
  }

  deleteCalendarEvent(userId: string, eventId: string) {
    return request({
      url: '/' + this.uri + '/interview-scheduling/delete?userId=' + userId + '&eventId=' + eventId,
      method: 'delete'
    })
  }

  removeGoogleCalendarIntegration() {
    return request({
      url: '/' + this.uri + '/integration/google-calendar/remove',
      method: 'post'
    })
  }

  removeOutlookCalendarIntegration() {
    return request({
      url: '/' + this.uri + '/integration/outlook-calendar/remove',
      method: 'post'
    })
  }

  removeUsersIntegration(payload) {
    return request({
      url: '/' + this.uri + '/integration/remove',
      method: 'post',
      data: payload
    })
  }
}

export { CalendarResource as default }
