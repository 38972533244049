import { RouteConfig } from 'vue-router'

export const WILDCARD_ROUTE: RouteConfig[] = [
  {
    path: '*',
    redirect: (to) => {
      console.log('Wildcard route triggered', to)
      return '/404'
    }
  }
]
