export const JobReportsArabic = {
  title: 'تقارير الوظائف',
  description: 'انظر بعناية إلى جميع المقاييس المتعلقة بوظيفتك',
  filterByDate: 'تصفية حسب تاريخ التقديم',
  reset: 'إعادة تعيين',
  pipeLinePerformance: {
    title: 'أداء الخط الزمني',
    description: 'تقدم المرشحين الذين قدموا من {startDate} إلى {endDate}',
    downloadInExcel: 'تنزيل تقارير الوظائف في ملف Excel',
    totalTimeToHire: 'الوقت الكلي للتوظيف'
  },
  originsAndSources: {
    title: 'المصادر والمنابع',
    description: 'أين كانوا المرشحون الذين قدموا من {startDate} إلى {endDate} ',
    sourceName: 'اسم المصدر',
    numberOfCandidates: 'عدد المرشحين'
  },
  archivalReasons: {
    title: 'أسباب الأرشفة',
    description: 'أسباب الأرشفة للمرشحين الذين قدموا من {startDate} إلى {endDate}',
    totalArchivedCandidates: 'إجمالي عدد المرشحين المؤرشفين:'
  },
  candidateExperienceRating: {
    title: 'تقييم تجربة المرشح',
    description: 'هذا هو تقييم بوابة المرشح للوظيفة',
    rating: '(تم تقييمها من قبل {reviewsCount} مرشح)'
  }
}

export const JobReportsEnglish = {
  title: 'Job Reports',
  description: 'Have an in-depth look at all the metrics related to your job',
  filterByDate: 'Filter by apply date',
  reset: 'Reset',
  pipeLinePerformance: {
    title: 'Pipeline performance',
    description: 'Progression of candidates who applied from {startDate} to {endDate}',
    downloadInExcel: 'Download job reports in excel sheet.',
    totalTimeToHire: 'Total Time To Hire'
  },
  originsAndSources: {
    title: 'Origins and Sources',
    description: 'Where did the candidates who applied from {startDate} to {endDate} ',
    sourceName: 'SOURCE NAME',
    numberOfCandidates: 'NUMBER OF CANDIDATES'
  },
  archivalReasons: {
    title: 'ARCHIVAL REASON',
    description: 'Archival reasons of candidates who applied from {startDate} to {endDate}',
    totalArchivedCandidates: 'Total archived candidates:'
  },
  candidateExperienceRating: {
    title: 'Candidate experience rating',
    description: 'This is the candidate portal rating for the job',
    rating: '(rated by {reviewsCount} candidates)'
  }
}
