export const BookingLinkEnglish = {
  backToLink: 'Back to interview booking links',
  addInterview: 'Add interview booking link',
  linkName: 'Link name',
  linkNote: 'This is only used internally to identify the booking link.',
  linkNamePlaceholder: 'e.g Sales phone screen',
  eventName: 'Event name',
  eventNote: 'This will be used as the event title on the calendar.',
  eventNamePlaceholder: 'e.g Interview with candidate name',
  duration: 'Duration',
  durationNote: 'Add duration in minutes',
  meetingDesc: 'Meeting Description',
  meetingDescPlaceholder: 'What to expect from the call or what is this call about...',
  selectInterviewer: 'Select Interviewer',
  addNewMem: 'Add new team member',
  refreshList: 'Refresh users list',
  notValidUserDesc: 'You haven\'t integrated your calendar. | This user hasn\'t integrated the calendar. Please ask them to integrate their calendar with the ATS.',
  settings: 'settings',
  timezone: 'Timezone',
  timezonePlaceholder: 'Select interviewer timezone',
  eventType: 'Choose type of event',
  videoConference: 'Video Conference',
  inPerson: 'In person Meeting',
  phoneScreen: 'Phone Screen',
  addAddress: 'Add organizer\'s address',
  googleMeetNotSetup: 'Google Meet not set up. Set up to continue',
  setUp: 'Set up',
  linkSettings: 'Link settings',
  minimumSchedule: 'Minimum scheduling notice',
  minimumScheduleDesc: 'The shortest advance time required for arranging the event.',
  bufferTime: 'Buffer time',
  bufferTimeDesc: 'The extra time allocated between schedules meetings.',
  beforeEvent: 'Before event',
  afterEvent: 'After event',
  offeredTimeRange: 'Offered time range',
  offeredTimeRangeDesc: 'Limit how far in the future this interview can be booked',
  calendarDays: 'calendar days',
  businessDays: 'business days',
  intoTheFuture: 'into the future',
  bookingFrequency: 'Limit booking frequency',
  bookingFrequencyDesc: 'Limit how many times this interview can be booked',
  perDay: 'per day',
  perWeek: 'per week',
  perMonth: 'per month',
  perYear: 'per year',
  updatedMessage: 'Successfully updated the event',
  createdMessage: 'Successfully created the event',
  formRules: {
    linkName: 'Please input Link name',
    interviewer: 'Please select the Interviewer',
    timezone: 'Please select the Timezone',
    duration: 'Please select the Duration',
    eventType: 'Please select Event Type'
  },
  notAllowedToCreateBookingLink: 'The selected interviewer do not have all the required calendar permissions. Please ask them to reintegrate their calendar with all permissions.',
  notAllowedToCreateVideoConference: 'The selected interviewer do not have the required permissions to create a video conference.',
  refreshTemplateList: 'Refresh Feedback Templates',
  interviewScorecard: 'Interview Feedback Template',
  chooseAScorecard: 'Choose a feedback to evaluate candidates after the interview.',
  addNewTemplate: 'Add new template',
  interviewScorecardNotNeeded: 'Interview feedback template not needed',
  addInterviewScorecard: 'Add interview feedback template'
}
export const BookingLinkArabic = {
  backToLink: 'العودة إلى روابط حجز المقابلات',
  addInterview: 'أضف رابط المقابلة',
  linkName: 'اسم الرابط',
  linkNote: 'يتم استخدام هذا الاسم خلال عملية تحديد المقابلة فقط لتمييز الرابط.',
  linkNamePlaceholder: 'مثال: مقابلة توظيف لموظف مبيعات',
  eventName: 'عنوان المقابلة',
  eventNote: 'سيتم استخدام هذا الاسم كعنوان للمقابلة في التقويم.',
  eventNamePlaceholder: 'مثال: مقابلة مع (اسم المرشح)',
  duration: 'مدة المقابلة',
  durationNote: 'أضف مدة المقابلة بالدقائق',
  meetingDesc: 'تفاصيل المقابلة',
  meetingDescPlaceholder: 'ما يمكن توقعه من هذه المقابلة أو عن ماذا تدور هذه المقابلة.',
  selectInterviewer: 'اختر الموظف الذي سيُجري المقابلة',
  addNewMem: 'أضف عضوًا جديدًا للفريق',
  refreshList: 'تحديث قائمة المستخدمين',
  notValidUserDesc: 'لم تقم بدمج التقويم الخاص بك. | لم يقم هذا المستخدم بدمج التقويم. يرجى أن تطلب منهم دمج التقويم الخاص بهم مع نظام تتبع المتقدمين.',
  settings: 'الإعدادات',
  timezone: 'النطاق الزمني',
  timezonePlaceholder: 'اختر النطاق الزمني للموظف المحدد لإجراء المقابلة',
  eventType: 'اختر نوع المقابلة',
  videoConference: 'مكالمة فيديو',
  inPerson: 'اجتماع في الشركة',
  phoneScreen: 'مكالمة هاتفية',
  addAddress: 'أضف عنوان الشركة ',
  googleMeetNotSetup: 'لم يتم إعداد Google Meet. قم بالإعداد للمتابعة في حجز المقابلة',
  setUp: 'إعداد',
  linkSettings: 'إعدادات رابط المقابلة',
  minimumSchedule: 'أقل مدة إشعار لجدولة المقابلة',
  minimumScheduleDesc: 'أقل وقت ممكن لترتيب المقابلة.',
  bufferTime: 'الوقت المتاح بين كل مقابلة وأخرى',
  bufferTimeDesc: 'الوقت المخصص بين الاجتماعات المجدولة.',
  beforeEvent: 'قبل المقابلة',
  afterEvent: 'بعد المقابلة',
  offeredTimeRange: 'المدى الزمني المعروض للمقابلة',
  offeredTimeRangeDesc: 'حدد أقصى مدة يمكن فيها حجز هذه المقابلة في المستقبل.',
  calendarDays: 'أيام التقويم',
  businessDays: 'أيام العمل الرسمية',
  intoTheFuture: 'مستقبلًا',
  bookingFrequency: 'عدد مرات تكرار المقابلة',
  bookingFrequencyDesc: 'حدد عدد المرات التي يمكن فيها حجز هذه المقابلة مستقبلًا.',
  perDay: 'يوميًا',
  perWeek: 'أسبوعيًا',
  perMonth: 'شهريًا',
  perYear: 'سنويًا',
  updatedMessage: 'تم تحديث بيانات المقابلة بنجاح',
  createdMessage: 'تم إنشاء المقابلة بنجاح',
  formRules: {
    linkName: 'رجاءً ادخل اسم الرابط',
    interviewer: 'رجاءً اختر موظف لإجراء المقابلة',
    timezone: 'رجاءً اختر النطاق الزمني',
    duration: 'رجاءً اختر مدة المقابلة',
    eventType: 'رجاءً اختر نوع المقابلة'
  },
  notAllowedToCreateBookingLink: 'الموظف الذي اخترته لإجراء المقابلة لا يتيح كل الصلاحيات المطلوبة الخاصة بالتقويم. رجاءً اطلب منه إعداد تقويمه الخاص بإتاحة جميع الصلاحيات.',
  notAllowedToCreateVideoConference: 'الموظف الذي اخترته لإجراء المقابلة لا يتيح كل الصلاحيات المطلوبة لإعداد مقابلة فيديو.',
  refreshTemplateList: 'تحديث قوالب بطاقات التقييم',
  interviewScorecard: 'قالب ردود الفعل المقابلة',
  chooseAScorecard: 'اختر ردود الفعل لتقييم المرشحين بعد المقابلة.',
  addNewTemplate: 'إضافة قالب جديد',
  interviewScorecardNotNeeded: 'قالب ردود الفعل المقابلة ليست هناك حاجة',
  addInterviewScorecard: 'أضف قالب تعليقات المقابلة'
}

export const InterviewBookingEnglish = {
  subTitle: 'Create a booking link to let candidates book interviews directly in free slots of your calendar.',
  tooltipText: 'Click here to learn more about interview booking links',
  eventName: 'EVENT NAME',
  duration: 'DURATION',
  interviewers: 'INTERVIEWERS',
  type: 'MEETING TYPE',
  actions: 'ACTIONS',
  addNewLink: 'Add new link',
  deleteDialogueTitle: 'Are you sure to delete this booking link?',
  deleteMessage: 'Successfully deleted the booking link!',
  noBookingLinksTitle: 'No interview booking links created yet!',
  noBookingLinksDescription: 'All the booking links created will show up here!',
  createNewBookingLink: 'Create new booking link'
}

export const InterviewBookingArabic = {
  subTitle: 'أنشئ رابط حجز للسماح للمرشحين بحجز المقابلات مباشرة في الفتحات الفارغة في جدولك.',
  tooltipText: 'انقر هنا للتعرف على المزيد حول روابط حجز المقابلات',
  eventName: 'اسم الفعالية',
  duration: 'المدة',
  interviewers: 'المقابلون',
  type: 'نوع الاجتماع',
  actions: 'الإجراءات',
  addNewLink: 'إضافة رابط مقابلة جديد',
  deleteDialogueTitle: 'هل أنت متأكد من حذف رابط المقابلة؟',
  deleteMessage: 'تم حذف رابط المقابلة بنجاح',
  noBookingLinksTitle: 'لم يتم إنشاء روابط حجز المقابلات بعد!',
  noBookingLinksDescription: 'جميع روابط الحجز التي تم إنشاؤها ستظهر هنا!',
  createNewBookingLink: 'إنشاء رابط حجز جديد'
}
