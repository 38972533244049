export const ManageScoreCardArabic = {
  title: 'قوالب ملاحظات المقابلة',
  subTitle: 'قم بإنشاء قوالب تعليقات المقابلة لإضافة أسئلة قائمة على المهارات وتقييم المرشحين بعد المقابلة.',
  tooltipText: 'انقر هنا لمعرفة المزيد حول قالب التعليقات على المقابلة',
  newTemplate: 'نموذج جديد',
  table: {
    templateName: 'اسم القالب',
    skills: 'المهارات',
    actions: 'الإجراءات',
    noTemplatesTitle: 'لم يتم إنشاء قوالب تعليقات المقابلة حتى الآن!',
    noTemplatesDescription: 'ستظهر جميع قوالب التعليقات التي تم إنشاؤها هنا!',
    createNewScorecard: 'إنشاء قالب ملاحظات جديد'
  },
  dialogue: {
    create: 'إنشاء نموذج بريد إلكتروني جديد',
    edit: 'تعديل النموذج',
    deleteConfirmation: 'هل أنت متأكد من حذف هذا النموذج؟',
    delete: 'حذف النموذج',
    cannotDelete: 'لا يمكن حذف هذا النموذج'
  },
  successMessages: {
    created: 'تم إنشاء النموذج بنجاح!',
    updated: 'تم تحديث النموذج بنجاح!',
    deleted: 'تم حذف النموذج بنجاح!',
    successfullySubmittedResponse: 'تم تقديم الاستجابة بنجاح',
    successfullyUpdatedResponse: 'تم تحديث الاستجابة بنجاح'
  },
  templateCreateForm: {
    addInterviewScoreCardTemplate: 'أضف قالب تعليقات المقابلة',
    templateName: 'اسم النموذج',
    subTitle: 'سيتم عرض هذا عند تحديد التعليقات أثناء جدولة المقابلة أو إنشاء رابط الحجز.',
    addTemplateName: 'اسم النموذج',
    skillsPlaceholder: 'أضف أسئلة إرشادية للمحاور لمساعدتهم في تقييم هذه المهارة',
    addQuestion: 'إضافة سؤال',
    generateQuestionsUsingAI: 'توليد الأسئلة باستخدام الذكاء الاصطناعي',
    addAnotherSkillOrCompetency: 'إضافة مهارة أو كفاءة أخرى',
    addSkillName: 'إضافة اسم المهارة',
    addSampleAnswer: 'إضافة إجابة نموذجية',
    removeSampleAnswer: 'إزالة الإجابة النموذجية',
    generateScoreCardUsingAI: 'إنشاء قالب تعليقات للمقابلة باستخدام الذكاء الاصطناعي'
  },
  addScoreForm: {
    addInterviewNotes: 'أضف ملاحظات المقابلة هنا (اختياري)',
    proceedToNextStage: 'هل يجب أن ينتقل هذا المرشح إلى المرحلة التالية؟',
    lowest: 'أدنى',
    highest: 'أعلى',
    scorecardTemplate: 'قالب ردود الفعل',
    chooseScorecardTemplate: 'اختر قالب ردود الفعل لتقييم المرشحين بعد المقابلة.',
    viewCandidateProfile: 'عرض الملف الشخصي',
    interviewScorecard: 'ردود الفعل المقابلة',
    sampleAnswer: 'إجابة نموذجية',
    selectScorecard: 'حدد ردود الفعل',
    previousSubmittedScores: 'ردود الفعل السابقة',
    noPreviousScorecard: 'لا توجد تعليقات سابقة',
    viewScores: 'عرض ردود الفعل',
    scoresNotAdded: 'يرجى تقديم ملاحظاتك لجميع الحقول المطلوبة',
    goBackToCandidateProfile: 'العودة إلى ملف المرشح'
  }
}

export const ManageScoreCardEnglish = {
  title: 'Interview feedback templates',
  subTitle: 'Create interview feedback templates to add skill-based questions and evaluate candidates after the interview.',
  tooltipText: 'Click here to learn more about interview feedback template',
  newTemplate: 'New template',
  table: {
    templateName: 'TEMPLATE NAME',
    skills: 'SKILLS',
    actions: 'ACTIONS',
    noTemplatesTitle: 'No interview feedback templates created yet!',
    noTemplatesDescription: 'All the feedback templates created will show up here!',
    createNewScorecard: 'Create new feedback template'
  },
  dialogue: {
    create: 'Create new email template',
    edit: 'Edit template',
    deleteConfirmation: 'Are you sure to delete this template?',
    delete: 'Delete Template',
    cannotDelete: 'Cannot delete this template'
  },
  successMessages: {
    created: 'Template created successfully!',
    updated: 'Template updated successfully!',
    deleted: 'Template deleted successfully!',
    submittedResponse: 'Successfully submitted response',
    updatedResponse: 'Successfully updated response'
  },
  templateCreateForm: {
    addInterviewScoreCardTemplate: 'Add interview feedback template',
    templateName: 'Template name',
    subTitle: 'This will be displayed when selecting a feedback during interview scheduling or booking link creation.',
    addTemplateName: 'Add template name',
    skillsPlaceholder: 'Add guiding questions for the interviewer to help them evaluate this skill',
    addQuestion: 'Add question',
    generateQuestionsUsingAI: 'Generate questions using AI',
    addAnotherSkillOrCompetency: 'Add another skill or competency',
    addSkillName: 'Add Skill Name',
    addSampleAnswer: 'Add Sample Answer',
    removeSampleAnswer: 'Remove Sample Answer',
    generateScoreCardUsingAI: 'Generate interview feedback template using AI'
  },
  addScoreForm: {
    addInterviewNotes: 'Add interview notes here (optional)',
    proceedToNextStage: 'Should this candidate proceed to the next stage?',
    lowest: 'Lowest',
    highest: 'Highest',
    scorecardTemplate: 'Feedback template',
    chooseScorecardTemplate: 'Choose a feedback template to evaluate candidates after the interview.',
    viewCandidateProfile: 'View profile',
    interviewScorecard: 'Interview feedback',
    sampleAnswer: 'Sample Answer',
    selectScorecard: 'Select feedback',
    previousSubmittedScores: 'Previous feedback',
    noPreviousScorecard: 'No previous feedbacks',
    viewScores: 'View feedback',
    scoresNotAdded: 'Please give your feedback for all the required fields',
    goBackToCandidateProfile: 'Go back to candidate profile'
  }
}
