
import { Component, Prop, Vue } from 'vue-property-decorator'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import WcIconExternalLink from '@/components/UILibrary/Icons/WcIconExternalLink.vue'
import WcIconWhatsApp from '@/components/UILibrary/Icons/WcIconWhatsApp.vue'
import WcIconFeedback from '@/components/UILibrary/Icons/WcIconFeedback.vue'
import { UserModule } from '@/store/modules/user'
import LoadingAnimation from '@/components/UILibrary/loading-animation.vue'

@Component({
  name: 'NewChatSection',
  components: { LoadingAnimation, WcIconFeedback, WcIconWhatsApp, WcIconExternalLink, WcTypography }
})
export default class extends Vue {
  @Prop({ required: true, type: Function }) addNewChat!: Function;
  @Prop({ required: true, type: Function }) fetchThread!: Function;
  @Prop({ required: true }) chatThreads!: {name: string, id: string}[]
  @Prop({ required: true }) currentThreadId!: string
  @Prop({ required: true }) loading!: boolean

  openHelpcenter() {
    window.open('https://whitecarrot.notion.site/Whitecarrot-io-Help-Centre-9add74c9165a4a2aa6dec8d57cb64f1b', '_blank')
  }

  openWhatsHelp() {
    // https://api.whatsapp.com/send/?phone=%2B971581746200&text&type=phone_number&app_absent=0
    // https://wa.me/+971581746200
    window.open('https://api.whatsapp.com/send/?phone=%2B971581746200&text&type=phone_number&app_absent=0', '_blank')
  }

  openFeedbackForm() {
    window.open(`https://docs.google.com/forms/d/e/1FAIpQLSev5sWdX2B2bIkBfgq8uQXYkDVKDSmuV-KjtnQ2AOzQLYoFAw/viewform?usp=pp_url&entry.605810767=${UserModule.companyName}&entry.1718894749=${UserModule.email}&entry.356970377=${UserModule.firstName}`, '_blank')
  }
}
