export const CareerEditEnglish = {
  settings: 'Settings',
  careerPage: 'Career page',
  careerAt: 'Careers at',
  noThanks: 'No, Thanks',
  careerUpdateSuccess: 'Careers updated successfully',
  areYouSure: 'Are you sure to delete this?',
  viewOpenJobs: 'View open jobs',
  captionEditor: 'Add body text eg. we are looking for passionate people to join us on our mission.',
  addTitle: 'Add title eg. company story, work with us',
  browserNotSupport: 'Your browser does not support the video tag.',
  addTextSection: 'Add text section',
  addVideo: 'Add video',
  searchByJobTitle: 'Search by job title',
  openJobs: 'Open jobs',
  noOpenJobs: 'No open jobs',
  currentlyNoJobs: 'Currently we don’t have any open jobs. Please come back again to check.',
  noMatchFilters: 'No jobs matching filters',
  couldNotFindAnyJobs: 'We could not find any jobs matching your filters. Try again with different filter.',
  viewAndApply: 'View & Apply',
  videoUploadIcon: 'video-upload-icon',
  banner: {
    addBanner: 'Add banner',
    imageUploadIcon: 'image-upload-icon'
  },
  inputYoutubeURL: 'Please input the YouTube URL'
}
export const CareerEditArabic = {
  settings: 'إعدادات',
  careerPage: 'الصفحة المهنية',
  careerUpdateSuccess: 'تم تحديث الوظائف بنجاح',
  noThanks: 'لا، شكرًا',
  areYouSure: 'هل أنت متأكد أنك تريد حذف هذا؟',
  viewOpenJobs: 'عرض الوظائف المفتوحة',
  captionEditor: 'أضف نصًا أساسيًا على سبيل المثال. نحن نبحث عن أشخاص متحمسين للانضمام إلينا في مهمتنا.',
  addTitle: 'إضافة عنوان على سبيل المثال. قصة الشركة، العمل معنا',
  browserNotSupport: 'متصفحك الحالي لا يدعم تشغيل الفيديو.',
  addTextSection: 'إضافة قسم النص',
  addVideo: 'أضف فيديو',
  searchByJobTitle: 'البحث حسب المسمى الوظيفي',
  openJobs: 'وظائف مفتوحة',
  noOpenJobs: 'لا وظائف مفتوحة',
  currentlyNoJobs: 'حاليا ليس لدينا أي وظائف مفتوحة. يرجى العودة مرة أخرى للتحقق.',
  noMatchFilters: 'لا توجد وظائف مطابقة للمرشحات',
  couldNotFindAnyJobs: 'لم نتمكن من العثور على أي وظائف تطابق عوامل التصفية الخاصة بك. حاول مرة أخرى باستخدام مرشح مختلف.',
  viewAndApply: 'عرض وتطبيق',
  videoUploadIcon: 'أيقونة تحميل الفيديو',
  banner: {
    addBanner: 'إضافة بانر',
    imageUploadIcon: 'أيقونة تحميل الصورة'
  },
  inputYoutubeURL: 'الرجاء إدخال عنوان URL لموقع YouTube'
}
