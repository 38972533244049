export const ManageEmailsArabic = {
  title: 'قوالب البريد الإلكتروني',
  subTitle: 'أنشئ قالب بريد إلكتروني لتوفير الوقت أثناء إرسال البريد الإلكتروني إلى المرشح.',
  tooltipText: 'انقر هنا للتعرف على المزيد حول قوالب البريد الإلكتروني',
  newTemplate: 'قالب جديد',
  cannotDeleteDefaultTemplates: 'لا يمكنك حذف القوالب الافتراضية',
  validation: 'القالب {title} و {subject} مطلوبان.',
  cannotAbleToClone: 'العنوان موجود بالفعل. الرجاء اختيار عنوان مختلف.',
  clone: 'استنساخ',
  table: {
    template: 'القالب',
    subject: 'الموضوع',
    lastActivity: 'آخر نشاط',
    actions: 'الإجراءات'
  },
  dialogue: {
    create: 'إنشاء قالب بريد إلكتروني جديد',
    edit: 'تحرير قالب بريد إلكتروني',
    deleteConfirmation: 'هل أنت متأكد من حذف هذا القالب؟',
    delete: 'حذف القالب',
    clone: 'استنساخ قالب البريد الإلكتروني',
    cannotDelete: 'لا يمكن حذف هذا القالب',
    deleteSuggestion: 'تستخدم بعض مراحل الوظيفة هذا القالب في الرفض أو الدعوة الآلية. يرجى تغيير إعدادات الآلية للمرحلة قبل حذف قالب البريد الإلكتروني هذا.'
  },
  successMessages: {
    created: 'تم إنشاء القالب بنجاح!',
    updated: 'تم تحديث القالب بنجاح!',
    deleted: 'تم حذف القالب بنجاح!'
  },
  companyTemplates: 'قوالب الشركة',
  systemTemplates: 'قوالب النظام'
}

export const ManageEmailsEnglish = {
  title: 'Email templates',
  subTitle: 'Create email template to save time while sending emails to candidate.',
  tooltipText: 'Click here to learn more about emails templates',
  newTemplate: 'New template',
  cannotDeleteDefaultTemplates: 'You cannot delete default templates',
  validation: 'Template {title}  and {subject} are required.',
  cannotAbleToClone: 'Title already exists. Please choose a different title.',
  noEmailTemplatesCreatedYet: 'No email templates created yet!',
  allEmailTemplatesWillShowHere: 'All the email templates created will show up here!',
  createNewEmailTemplate: 'Create new email template',
  clone: 'Clone',
  table: {
    template: 'TEMPLATE',
    subject: 'SUBJECT',
    lastActivity: 'LAST ACTIVITY',
    actions: 'ACTIONS'
  },
  dialogue: {
    create: 'Create new email template',
    edit: 'Edit email template',
    deleteConfirmation: 'Are you sure to delete this template?',
    delete: 'Delete Template',
    clone: 'Clone email template',
    cannotDelete: 'Cannot delete this template',
    deleteSuggestion: 'Some job stages are using this template in rejection or invitation automation. Please change stage automation settings before deleting this email template.'

  },
  successMessages: {
    created: 'Template created successfully!',
    updated: 'Template updated successfully!',
    deleted: 'Template deleted successfully!'
  },
  companyTemplates: 'Company templates',
  systemTemplates: 'System templates'
}
