import { ICollaboratorJob } from '@/views/recruiter/job-role/models'
import { LanguageEnum } from '@/store/modules/language'
import {
  CompanyThirdPartyIntegrationsTypeEnum
} from '../settings/integrations/hrms-integration/models/CompanyThirdPartyIntegrationsItem'

export enum WhiteLabelPartnerEnum {
  BAYZAT = 'BAYZAT',
  WC = 'WC',
}

export enum CompanyIntegrationsEnum {
  GOOGLE = 'GOOGLE',
  OUTLOOK = 'OUTLOOK',
}

export interface IInviteNewMember {
  email: string
  companyId: string
  roleType: string
  firstName: string
  lastName: string
  whiteLabelPartner?: WhiteLabelPartnerEnum
  jobs?: ICollaboratorJob[]
  id?: string
}

export interface INewCompany {
  name: string
  emailDomain: string
  whiteLabelPartner?: string | null
  isAtsCompany?: boolean
  trailPeriodEnds?: string | null
  integrationType?: CompanyIntegrationsEnum | null
  language?: LanguageEnum
  onBoarded: boolean
  hrmsIntegrationType?: CompanyThirdPartyIntegrationsTypeEnum | null
  isTestCompany: boolean
}
