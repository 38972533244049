export enum PermissionsEnum {
  // company branding
  COMPANY_BRANDING_EDIT = 'COMPANY::BRANDING::EDIT',
  COMPANY_BRANDING_LIST = 'COMPANY::BRANDING::LIST',
  // company career page
  COMPANY_CAREER_PAGE_LIST = 'COMPANY::CAREER_PAGE::LIST',
  COMPANY_CAREER_PAGE_EDIT = 'COMPANY::CAREER_PAGE::EDIT',
  // company team members
  COMPANY_TEAM_MEMBERS_LIST = 'COMPANY::TEAM_MEMBERS::LIST',
  COMPANY_TEAM_MEMBERS_ADD = 'COMPANY::TEAM_MEMBERS::ADD',
  COMPANY_TEAM_MEMBERS_EDIT = 'COMPANY::TEAM_MEMBERS::EDIT',
  COMPANY_TEAM_MEMBERS_DELETE = 'COMPANY::TEAM_MEMBERS::DELETE',
  COMPANY_TEAM_MEMBERS_RESENT_PARTNER_INVITE = 'COMPANY::TEAM_MEMBERS::RESENT_PARTNER_INVITE',
  // company locations
  COMPANY_LOCATION_LIST = 'COMPANY::LOCATION::LIST',
  COMPANY_LOCATION_ADD = 'COMPANY::LOCATION::ADD',
  COMPANY_LOCATION_EDIT = 'COMPANY::LOCATION::EDIT',
  COMPANY_LOCATION_DELETE = 'COMPANY::LOCATION::DELETE',
  // company departments
  COMPANY_DEPARTMENT_LIST = 'COMPANY::DEPARTMENT::LIST',
  COMPANY_DEPARTMENT_ADD = 'COMPANY::DEPARTMENT::ADD',
  COMPANY_DEPARTMENT_EDIT = 'COMPANY::DEPARTMENT::EDIT',
  COMPANY_DEPARTMENT_DELETE = 'COMPANY::DEPARTMENT::DELETE',
  // company stage pipeline
  COMPANY_DEFAULT_STAGE_PIPELINE_LIST = 'COMPANY::DEFAULT_STAGE_PIPELINE::LIST',
  COMPANY_DEFAULT_STAGE_PIPELINE_ADD = 'COMPANY::DEFAULT_STAGE_PIPELINE::ADD',
  COMPANY_DEFAULT_STAGE_PIPELINE_EDIT = 'COMPANY::DEFAULT_STAGE_PIPELINE::EDIT',
  COMPANY_DEFAULT_STAGE_PIPELINE_DELETE = 'COMPANY::DEFAULT_STAGE_PIPELINE::DELETE',
  // company archival reasons
  COMPANY_DEFAULT_ARCHIVAL_REASON_LIST = 'COMPANY::DEFAULT_ARCHIVAL_REASON::LIST',
  COMPANY_DEFAULT_ARCHIVAL_REASON_ADD = 'COMPANY::DEFAULT_ARCHIVAL_REASON::ADD',
  COMPANY_DEFAULT_ARCHIVAL_REASON_EDIT = 'COMPANY::DEFAULT_ARCHIVAL_REASON::EDIT',
  COMPANY_DEFAULT_ARCHIVAL_REASON_DELETE = 'COMPANY::DEFAULT_ARCHIVAL_REASON::DELETE',
  // company interview booking links
  COMPANY_BOOKING_LINK_ADD = 'COMPANY::BOOKING_LINK::ADD',
  COMPANY_BOOKING_LINK_EDIT = 'COMPANY::BOOKING_LINK::EDIT',
  COMPANY_BOOKING_LINK_DELETE = 'COMPANY::BOOKING_LINK::DELETE',
  COMPANY_BOOKING_LINK_LIST = 'COMPANY::BOOKING_LINK::LIST',
  // company interview feed-back template
  COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_LIST = 'COMPANY::INTERVIEW_FEEDBACK_TEMPLATE::LIST',
  COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_ADD = 'COMPANY::INTERVIEW_FEEDBACK_TEMPLATE::ADD',
  COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_EDIT = 'COMPANY::INTERVIEW_FEEDBACK_TEMPLATE::EDIT',
  COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_DELETE = 'COMPANY::INTERVIEW_FEEDBACK_TEMPLATE::DELETE',
  COMPANY_AI_SUPPORT_GENERATE_SCORE_CARD = 'COMPANY::AI_SUPPORT::GENERATE_SCORE_CARD',
  COMPANY_VIEW_AND_SUBMIT_SCORES = 'COMPANY::VIEW_AND_SUBMIT_SCORES',
  // company email templates
  COMPANY_EMAIL_TEMPLATES_ADD = 'COMPANY::EMAIL_TEMPLATES::ADD',
  COMPANY_EMAIL_TEMPLATES_EDIT = 'COMPANY::EMAIL_TEMPLATES::EDIT',
  COMPANY_EMAIL_TEMPLATES_DELETE = 'COMPANY::EMAIL_TEMPLATES::DELETE',
  COMPANY_EMAIL_TEMPLATES_LIST = 'COMPANY::EMAIL_TEMPLATES::LIST',
  // company offer letter templates
  COMPANY_OFFER_LETTERS_TEMPLATES_LIST = 'COMPANY::OFFER_LETTERS_TEMPLATES::LIST',
  COMPANY_OFFER_LETTERS_TEMPLATES_ADD = 'COMPANY::OFFER_LETTERS_TEMPLATES::ADD',
  COMPANY_OFFER_LETTERS_TEMPLATES_EDIT = 'COMPANY::OFFER_LETTERS_TEMPLATES::EDIT',
  COMPANY_OFFER_LETTERS_TEMPLATES_DELETE = 'COMPANY::OFFER_LETTERS_TEMPLATES::DELETE',
  // company hrms integrations
  COMPANY_HRMS_INTEGRATIONS_ADD = 'COMPANY::HRMS_INTEGRATIONS::ADD',
  COMPANY_HRMS_INTEGRATIONS_EDIT = 'COMPANY::HRMS_INTEGRATIONS::EDIT',
  COMPANY_HRMS_INTEGRATIONS_DELETE = 'COMPANY::HRMS_INTEGRATIONS::DELETE',
  COMPANY_HRMS_INTEGRATIONS_LIST = 'COMPANY::HRMS_INTEGRATIONS::LIST',
  // agencies
  COMPANY_AGENCY_LIST = 'COMPANY::AGENCY::LIST',
  COMPANY_AGENCY_ADD = 'COMPANY::AGENCY::ADD',
  COMPANY_AGENCY_EDIT = 'COMPANY::AGENCY::EDIT',
  COMPANY_AGENCY_DELETE = 'COMPANY::AGENCY::DELETE',
  // email and calendar integration
  COMPANY_INTEGRATE_CALENDAR_EMAIL_ADD = 'COMPANY::INTEGRATE_CALENDAR_EMAIL::ADD',
  COMPANY_INTEGRATE_CALENDAR_EMAIL_DELETE = 'COMPANY::INTEGRATE_CALENDAR_EMAIL::DELETE',
  COMPANY_INTEGRATE_CALENDAR_EMAIL_LIST = 'COMPANY::INTEGRATE_CALENDAR_EMAIL::LIST',
  // self-profile
  COMPANY_SELF_PROFILE = 'COMPANY::SELF_PROFILE',
  // notifications preferences
  USER_NOTIFICATION_PREFERENCES_EDIT = 'USER::NOTIFICATION_PREFERENCES::EDIT',
  USER_NOTIFICATION_PREFERENCES_LIST = 'USER::NOTIFICATION_PREFERENCES::LIST',
}
