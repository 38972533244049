import { ElForm } from 'element-ui/types/form'

export const isValidEmail = (str: string) => String(str)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

export const validateToEmails = (arr: string[]): boolean => {
  let isValid = true
  arr.forEach((email) => {
    if (!isValidEmail(email)) {
      arr.pop()
      isValid = false
    }
  })

  return isValid
}

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path)

export const isValidHttpUrlString = (rule: any, value: string, callback: Function) => {
  let url
  try {
    url = new URL(value)
  } catch (_) {
    callback(new Error('Please enter a valid url'))
  }
  if (url) {
    const isValid = url.protocol === 'http:' || url.protocol === 'https:'
    if (!isValid) callback(new Error('Please enter a valid url'))
    else callback()
  } else {
    callback(new Error('Please enter a valid url'))
  }
}

export const validateYoutubeUrl = (url?: string) => {
  try {
    const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
    const r = url?.match(rx)
    return !!r
  } catch (_) {
    return false
  }
}

export const isValidUrl = (url: string) => {
  try {
    const u = new URL(url)
    return !!u
  } catch (_) {
    return false
  }
}

export const strictEmailValidator = (errorMessage?: string) => ({
  validator: (rule, value, callback) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (regex.test(value)) {
      callback()
    } else {
      if (errorMessage) {
        callback(new Error(errorMessage))
      } else {
        callback(new Error('Please enter a valid email address'))
      }
    }
  },
  trigger: ['blur']
})

export const lenientEmailValidator = (errorMessage: string) => ({
  validator: (rule, value, callback) => {
    if (!value) {
      callback()
    }
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error(errorMessage))
    }
  },
  trigger: ['blur']
})

export const emailRules = {
  email: [
    { required: true, message: 'Please enter valid email', trigger: 'blur', type: 'email' },
    strictEmailValidator
  ]
}

export const strictPhoneNumberValidation = {
  validator: (rule, value, callback) => {
    const regex = /^\+?[\d\s\-().]{5,}$/
    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error('Please enter a valid phone number'))
    }
  },
  trigger: ['blur']
}

export const lenientPhoneNumberValidation = {
  validator: (rule, value, callback) => {
    if (!value) {
      callback()
    }
    const regex = /^\+?[\d\s\-().]{5,}$/
    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error('Please enter a valid phone number'))
    }
  },
  trigger: ['blur']
}

export const strictLinkedinValidation = {
  validator: (rule, value, callback) => {
    const regex = /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/gm
    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error('Please enter a valid linkedin url'))
    }
  },
  trigger: ['blur']
}

export const lenientLinkedinValidation = (errorMessage: string, isValidationRequired: boolean) => ({
  validator: (rule, value, callback) => {
    if (!isValidationRequired) {
      callback()
    }
    if (value === 'linkedin.com/in/') {
      callback()
    }
    // Updated regex to include country-specific subdomains before 'linkedin.com'
    const regex = /^(https?:\/\/)?(www\.)?([a-z]{2}\.)?linkedin\.com\/in\/.{3,100}\/?$/

    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error(errorMessage))
    }
  }
})

export const strictUrlLinkValidation = {
  validator: (rule, value, callback) => {
    const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g
    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error('Please enter a valid link'))
    }
  },
  trigger: ['blur']
}

export const lenientUrlLinkValidation = (errorMessage: string) => ({
  validator: (rule, value, callback) => {
    if (!value) {
      callback()
    }

    const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g

    if (regex.test(value)) {
      callback()
    } else {
      callback(new Error(errorMessage))
    }
  },
  trigger: ['blur']
})

export const phoneNumberRules = {
  phoneNumber: [
    { required: true, message: 'Please enter valid phone number', trigger: 'blur' },
    strictPhoneNumberValidation
  ]
}
export const formValidate = (formName: string, that: any): boolean => {
  let isValid: boolean = false;

  (that.$refs[formName] as ElForm).validate((valid: boolean): void => {
    isValid = valid
  })
  return isValid
}
