import axios, { AxiosRequestConfig } from 'axios'

import { UserModule } from '@/store/modules/user'
import { LanguageModule } from '@/store/modules/language'
import { ElMessage } from 'element-ui/types/message'
import { Notification as ElNotification } from 'element-ui'
import { USER_THIRD_PARTY_INTEGRATION_TOKENS_EXPIRED } from '@/utils/error-codes'
import { APP_DOMAIN } from '@/utils/constants'

export interface ErrorResponse {
  message: string
  statusCode: number
  success: boolean
  errorCode?: string
}

export const handleError = (error: unknown, log: ElMessage) => {
  const e = error as ErrorResponse
  const message = e.message || UNEXPECTED_ERROR_MESSAGE
  log.error(message)
  setTimeout(() => {
    if (e.errorCode && e.errorCode === USER_THIRD_PARTY_INTEGRATION_TOKENS_EXPIRED) {
      window.location.href = `${APP_DOMAIN}/#/recruiter-settings/app-integration`
    }
  }, 2000)
}

export const isCurrentPageIsPubic = (): boolean => {
  const publicPages = ['login', 'otp/user', 'careers', 'agency', 'profile-builder', 'plugin']
  const currentPath = window.location.toString()
  return publicPages.some((path) => currentPath.includes(path))
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean
}

export const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred. Please try again later.'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120 * 1000
})

let isRefreshing = false
// Define the type for the subscriber callbacks
type SubscriberCallback = (accessToken: string) => void;

// Array to hold subscriber callbacks
let subscribers: SubscriberCallback[] = []

function onAccessTokenFetched(accessToken: string) {
  subscribers.forEach(callback => callback(accessToken))
  subscribers = []
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

function SendLogoutNotification() {
  UserModule.ResetToken()
  if (isCurrentPageIsPubic()) { // for public pages we don't show this update
    location.reload()
    return
  }
  ElNotification({
    title: 'Your session has expired.',
    message: `
     <div>
          <div style="padding: 2px; margin-bottom: 10px; line-height: normal; text-align: left;">
            You were logged out due to inactivity. Please log in again.
          </div>
          <button
            style="border: 1px solid #5138ee;
            background-color: #5138ee;
            color: white;
            cursor: pointer;
            border-radius: 4px;
            text-decoration: none;
            width: 50px;
            height: 27px;"
          >
            Okay
          </button>
    </div>
    `,
    type: 'warning',
    dangerouslyUseHTMLString: true,
    showClose: false,
    onClick() {
      location.reload()
    }
  })
  setTimeout(() => {
    location.reload()
  }, 2000) // adding 2 sec, user can read the content
}

async function refreshToken() {
  const exception: ErrorResponse = {
    message: 'Session Expired Please login!',
    statusCode: 400,
    success: false
  }

  // for impersonation
  if (window.location.href.includes('authToken')) {
    window.location.replace(`${APP_DOMAIN}/#/login`)
    return Promise.reject(exception)
  }

  if (!UserModule.refreshToken) {
    SendLogoutNotification()
    return Promise.reject(exception)
  }
  try {
    const response = await axios.get(`${process.env.VUE_APP_BASE_API}/auth/login/refresh-token`, {
      headers: {
        Authorization: `Bearer ${UserModule.refreshToken}`,
        'Accept-Language': LanguageModule.language
      }
    })
    const { accessToken, refreshToken } = response.data
    if (accessToken) {
      await UserModule.UpdateTokenAfterLogin({ status: true, token: { accessToken, refreshToken }, devRevSessionToken: '' })
      return accessToken
    }
  } catch (error) {
    SendLogoutNotification()
    return Promise.reject(exception)
  }
}

service.interceptors.request.use(
  config => {
    if (UserModule.token) {
      config.headers.Authorization = `Bearer ${UserModule.token}`
    }
    config.headers['Accept-Language'] = LanguageModule.language
    return config
  }
)

service.interceptors.response.use(
  response => response,
  async error => {
    const { config, response } = error
    const originalRequest = config as CustomAxiosRequestConfig

    if (response?.status === 499 && !originalRequest._retry) {
      originalRequest._retry = true
      if (!isRefreshing) {
        isRefreshing = true
        refreshToken().then(newToken => {
          isRefreshing = false
          onAccessTokenFetched(newToken)
        }).catch(() => {
          return Promise.reject(error)
        })
      }

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(accessToken => {
          if (originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`
          }
          resolve(service(originalRequest))
        })
      })
      return retryOriginalRequest
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      message: response?.data.message || UNEXPECTED_ERROR_MESSAGE,
      statusCode: response?.status || 500,
      success: false,
      errorCode: response?.data.errorCode
    })
  }
)

export default service
