export const AddEditJobEnglish = {
  jobs: 'Jobs',
  published: 'Published',
  closed: 'Closed',
  drafted: 'Draft',
  editJob: 'Edit Job',
  gotoJobReports: 'Go to job reports',
  publish: 'Publish',
  nextApplication: 'Next: Application',
  cannotPublishNote: 'Add all required fields to publish the job.',
  jobInfoNote: 'Create job description by adding basic information related to this job',
  previewCandidatePortal: 'Preview candidate portal',
  previewPortalRequiredNote: 'Add all required fields to preview the candidate portal',
  jobApplicationSettings: 'Show advanced settings',
  jobPublishType: 'Where do you want to publish this job {required}',
  addCandidateToTalentPool: 'Allow employees to add candidates to the Talent pool from the employee portal.',
  generateUsingAI: 'Generate using AI',
  saveAndProceed: 'Save and proceed',
  recruiterGPTLoading: 'Recruiter GPT at Work, please wait a few seconds...',
  pleaseFillRequired: 'Please fill all required fields',
  generate: 'Generate',
  proceed: 'Proceed',
  areYouSure: 'Are you sure?',
  areYouSureToDeleteThisStage: 'Are you sure you want to delete this stage?',
  careerPageExternal: 'Career page (External)',
  employeePortal: 'Employee portal (Internal)',
  jobDescriptionAIWarning: 'The job description you\'ve written manually will be discarded and replaced with the AI-generated description.',
  areYouSureToProceed: 'Are you sure you want to proceed?',
  questions: {
    skillExperience: 'Skill experience',
    enterSkill: 'Enter skill',
    jobFunction: 'Job function',
    enterJobFunction: 'Enter job function',
    enterIndustry: 'Enter industry',
    answerType: 'Answer type',
    numeric: 'Numeric',
    singleSelect: 'Single select',
    multiSelect: 'Multi select',
    yesNo: 'Yes/No',
    freeText: 'Free text',
    anyOfThem: 'Any of them',
    allOfThem: 'All of them',
    range: 'Range',
    maximum: 'Maximum',
    minimum: 'Minimum',
    location: 'Location',
    enterLocation: 'Enter location'
  },
  labels: {
    jobName: 'Job Name {required}',
    jobDisplayName: 'Job display name for candidates {required}',
    jobTitle: 'Job title {required}',
    jobDescription: 'Job description {required}',
    typeOfPosition: 'Job type {required}',
    department: 'Department {required}',
    selectDepartment: 'Select department',
    selectLocation: 'Select location',
    location: 'Location {required}',
    remotePolicy: 'What\'s your remote policy for this job? {required}',
    workPlaceType: 'Workplace type {required}',
    remote: 'Remote',
    onsite: 'Onsite',
    inOfficeOnly: 'In Office only',
    flexible: 'In Office (WFH flexibility)',
    hybrid: 'Hybrid',
    keyRequirementsOfJob: 'Key requirements for the job',
    keyPerksBenefits: 'Key job perks and benefits',
    addNewLocation: 'Add new location',
    addNewDepartment: 'Add new department'
  },
  placeHolders: {
    addRoleTitle: 'Add Role Title',
    addJobDescription: 'Add job description',
    selectPosition: 'Select the type of position',
    addKeyRequirements: 'Add the key requirements',
    addKeyPerksBenefits: 'Add key job perks and benefits'
  },
  sectionsTab: {
    jobInformation: 'Job Information',
    stages: 'Stages',
    application: 'Application',
    collaborators: 'Collaborators'
  },
  saveAndNext: {
    application: 'Save & Next: Application',
    hiringFlow: 'Save & Next: Hiring flow',
    collaborators: 'Save & Next: Collaborators',
    publish: 'Save & Next: Publish'
  },
  successMessages: {
    generatedJobDescription: 'Successfully generated the Job Description',
    jobJourneyUpdated: 'Job information saved successfully',
    applicationInfoSavedSuccessfully: 'Application saved successfully',
    hiringFlowSavedSuccessfully: 'Hiring flow saved successfully'
  },
  errorMessages: {
    gptFailed: 'Recruiter GPT seems busy and failed to respond. Please try again.'
  },
  formRules: {
    inputRoleTitle: 'Please input role title',
    inputJobDesc: 'Please input job description',
    selectPosition: 'Please select type of position',
    selectDepartment: 'Please select department',
    selectLocation: 'Please select location'
  },
  hiringFlow: {
    title: 'Hiring flow',
    sourcedCandidate: 'Candidate that are sourced',
    appliedCandidates: 'Candidate that are applied',
    addStage: 'Add stage',
    deleteStage: 'Delete stage',
    candidateTasks: 'Candidate tasks',
    preview: 'Preview',
    collectInfoFromCandidates: 'Collect information form candidates',
    modules: {
      addScreeningQuestion: 'Add screening questions',
      addScreeningQuiz: 'Add screening quiz',
      collectInformation: 'Collect information',
      uploadFile: 'Ask to upload file',
      askToRecordVideo: 'Ask to record a video',
      addCustomPage: 'Add custom page',
      autoSendInvitationEmail: 'Auto send invitation email'
    }
  },
  application: {
    candidateApplicationTasks: 'Candidate application tasks',
    autoShortListCandidates: 'Auto-shortlist candidates',
    collectInformation: 'Collect information',
    email: 'Email',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    linkedinLink: 'LinkedIn link',
    portfolioLink: 'Portfolio link',
    cvFile: 'CV file',
    candidateApplicationForm: 'Candidate application form',
    fields: {
      mandatory: 'Mandatory',
      optional: 'Optional',
      off: 'Off'
    }
  },
  publishPage: {
    publishJob: 'Publish Job | Publish Job ?',
    publishJobsOnCareerPage: 'Publish job on career page',
    publishJobNote: 'Make job live for candidates to access it',
    enterSourceName: 'Please enter source name',
    mainPortalLink: 'Main portal link',
    cannotPublishJob: 'You cannot publish the job because',
    jobPublished: 'Job published on career page',
    jobClosed: 'Job closed on career page',
    addLink: 'Add Link',
    willBePublished: 'Will be published within 24 hours',
    addCustomLink: 'Add Custom Link',
    addSourceName: 'Add source name',
    addAgency: 'Add Agencies',
    generate: 'Generate',
    refreshAgencyList: 'Refresh agency list',
    saveAgencies: 'Save agencies',
    publishedOn: 'published on {date}',
    viewJobHistory: 'View job activity history',
    addedLinkShowHere: 'Added links will show here.',
    customLinksHeading: 'Connect with job boards',
    areYouSureToPublishJob: 'Are you sure you want to publish this job again?',
    agenciesCollaborateNote: 'Add external members to collaborate for this job. They can only submit candidates',
    customLinksNote: 'Connect with job board and track source by creating custom links.',
    clickHereToKnowMore: 'Click here to know more',
    closedJobsRemovedFromCareer: 'Closed jobs are removed from the Career page. You can reopen this job in future if you want to use it again.',
    areYouSureToCloseJob: 'Are you sure you want to close this job?',
    errorText: {
      dontHaveJobOwner: 'Your job does not have any job owners. Please add a job owner.',
      noCandidateTasksAdded: 'You have not added any candidate task in the {stage} stage. Add at least one candidate task.'
    },
    successMessages: {
      generatedCustomLink: 'Successfully generated custom link.',
      publishedJob: 'Successfully published job.',
      closedJob: 'Successfully closed job',
      updatedAgencies: 'Successfully updated agencies',
      deletedCustomLink: 'Successfully delete custom link.'
    },
    copyLink: 'Copy Link',
    deleteLink: 'Delete Link',
    closeJob: 'Close Job | Close Job ?',
    linkCopied: 'Link Copied',
    linkNotCopied: 'Link Not Copied'
  },
  addCollaborators: {
    title: 'Add Collaborators',
    description: 'Specify collaborators for this job. Invite new members if you don\'t see them in the list.',
    addNewTeamMember: 'Add new team member',
    refreshUsersList: 'Refresh users list',
    fetchingData: 'Fetching data...',
    savedCollaboratorSettings: 'Collaborators saved successfully',
    jobOwner: {
      label: 'Job owner {required}',
      placeholder: 'Select owner'
    },
    recruiter: {
      label: 'Recruiter {required}',
      placeHolder: 'Select recruiter'
    },
    jobHiringManager: {
      label: 'Hiring manager',
      placeholder: 'Select hiring manager',
      optional: '(optional)'
    },
    collaborators: {
      label: 'Collaborators with access to this job and candidates',
      placeholder: 'Add team members'
    },
    membersPermissionCard: {
      inviteSent: 'Invite sent',
      fullAccess: 'Full access members can edit all jobs, and access confidential information.',
      editJob: 'Edit this job',
      sendAndViewOfferLetters: 'Send & view offer letters',
      moveStageAndSendEmail: 'Move stages & send emails',
      selectStages: 'Select stages',
      viewNotesEmails: 'View secret notes & emails',
      limitedAccess: 'Confidential access can not be removed if the collaborator is allowed to edit the job.',
      accessCandidateProfile: 'Access candidate profile'
    }
  },
  stages: {
    stageWelcome: 'Stage Welcome',
    allStages: 'All Stages',
    jobReports: 'Job Reports',
    pleaseEnterStageName: 'Please enter stage name',
    stageNameCannotBeEmpty: 'Stage name cannot be empty',
    candidatesSourced: {
      title: 'Candidates that are sourced',
      note: 'All candidates referred by employees or submitted by agencies start from this stage'
    },
    stageName: 'Stage {no}: {name}',
    candidatesWhoApply: {
      title: 'Candidates who apply',
      note: 'All candidates coming from job boards or career pages start from this stage'
    },
    buttons: {
      addStage: 'Add stage',
      previewCandidatePortal: 'Preview candidate portal',
      findQuizMatching: 'Find quiz matching the job'
    },
    placeHolders: {
      enterStageName: 'Enter stage name',
      chooseShortlistingTasks: 'Choose predefined shortlisting tasks',
      startDate: 'Start Date',
      endDate: 'End Date',
      selectTemplate: 'Select Template',
      noEmailTemplateSelected: 'No Email template selected'
    },
    tooltips: {
      addWelcomeMessageNote: 'Add welcome message to preview the candidate portal',
      addCandidateTasksNote: 'Add candidate tasks to preview the candidate portal',
      editScreeningQuestions: 'Edit screening questions',
      editCandidateInfoQuestions: 'Edit candidate information questions',
      editSkillBasedTest: 'Edit skill based test',
      editVideoQuestions: 'Edit video questions',
      editWelcomeMessageAndVideo: 'Edit welcome message and video'
    },
    insights: {
      totalInStage: 'Total in Stage',
      progressed: 'Progressed',
      movedBack: 'MovedBack',
      archived: 'Archived',
      qualificationRate: 'Qualification Rate',
      completionRate: 'Completion Rate',
      inProgress: 'In Progress',
      started: 'Started',
      completed: 'Completed',
      qualifyingScore: 'Qualifying Score',
      totalQuestions: 'Total Questions',
      currentlyToReview: 'Currently To Review',
      currentlyInProgress: 'Currently In Progress',
      currentlyInPipeline: 'Currently In Pipeline',
      candidatesHavingExperienceGreaterThan: 'Candidates having experience greater than or equal to {years} years',
      candidatesHavingExperienceLessThan: 'Candidates having experience less than {years} years',
      candidateWillingToRelocate: 'Candidates currently located in or willing to relocate to {location}',
      candidateNotWillingToRelocate: 'Candidates currently neither located nor willing to relocate to {location}',
      candidateRelocateToTimezone: 'Candidates currently having or willing to relocate to timezone {timezone}',
      candidateNotRelocateToTimezone: 'Candidates currently neither located nor willing to relocate to timezone {timezone}'
    },
    warnings: {
      addAtleastOneActivity: '* Add at least one activity to publish this page'
    },
    mustHave: 'Must Have:',
    mustSelectOption: 'Must select option',
    multiSelectAns: 'Must select any of them | Must select all of them',
    expectedAnswerInRange: 'Expected answer to be in range {min} - {max}',
    expectedAnswerMinimum: 'Expected answer should be greater than {min}',
    expectedAnswerMaximum: 'Expected answer should be less than {max}',
    qualified: 'Qualified',
    editTemplate: 'Edit Template',
    removeTemplate: 'Remove Template',
    timeGuideline: 'Time Guideline',
    allowedTime: 'Allowed Time',
    recommendedTime: 'Recommended Time',
    evaluation: 'Evaluation',
    underQualified: 'Under Qualified',
    addQuestions: 'Add Questions',
    candidatesQualification: 'Candidates {qualification}',
    responsesReceived: '{no} responses received',
    candidateMustHaveExperience: 'Candidate must have {no} {year} of experience',
    screeningQuestions: 'Screening by Questions',
    screenOutBasedOn: 'Screen out candidates based on their location, minimum experience, & other criteria.',
    screenOutUnRequiredCandidates: 'Screen out candidates who do not meet the minimum skills required for the role.',
    collectRelevantInfo: 'Collect relevant information like expected salary, benefits, current salary, etc.',
    videoQuestions: 'Video Questions',
    videoCollectionNote: 'Gather recorded video responses from candidates, enabling them to showcase their communication skills, presentation abilities, and suitability for the role.',
    screeningSkillBasedTest: 'Screening by skill based test',
    candidateTasks: 'Candidate Tasks',
    applicationStageNote: 'Screen out underqualified candidates using knock out questions and skill based tests.',
    autoProgressAvailable: 'Auto-progress available',
    shortlistingType: 'Shortlisting Type',
    lastStageNote: 'Last stage of recruitment pipeline, move candidates who got hired',
    candidateInformationCollection: 'Candidate information collection',
    emailSendingNote: 'This email will be sent to the candidates who are moved to this stage.',
    welcomeMessage: 'Welcome message on the candidate portal',
    welcomeCandidateToStage: 'Welcome candidates to the stage',
    stageWelcomeEmail: 'Stage Welcome email',
    welcomeCandidateWithVideo: 'Welcome your candidates with a message or a video',
    min: '{number} minimum'
  },
  dialogs: {
    stageLinkNotFound: 'Stage link is missing for candidate. Please select the email with stage link',
    areYouSure: 'Are you sure?',
    editStageName: 'Edit stage name',
    addNewStage: 'Add new stage',
    yourUploadsDeleted: 'Your Uploads will be deleted',
    module: {
      resetModule: 'Reset Module',
      resetModuleNote: 'Are you sure you want to reset this module?'
    },
    deleteStage: {
      description: 'This action will remove the existing candidate tasks. If you want to keep the current tasks, add a new stage instead.',
      warning: 'This action cannot be undone. Are you sure?',
      noQuestionsWarning: 'There are no Questions added. Stage will reset to Empty'
    },
    cannotDeleteStage: {
      description: 'You cannot delete this stage',
      info: 'Move candidates out of this stage or archive them before deleting the stage.'
    },
    jobDescriptionLost: 'Your changes will be lost and the job will not be saved.',
    buttons: {
      resetModule: 'Reset Module'
    },
    candidatePortalJobPreview: 'Candidate portal Job preview',
    templatePreview: 'Template Preview',
    selectEmailTemplate: 'Select email template',
    manageTemplate: 'Manage Template',
    refreshTemplateList: 'Refresh template list',
    unsavedChangedWarning: 'You have unsaved changes.Click on Save as Draft to save the changes. Are you still sure to exit?',
    saveYourDetailsWarning: 'Please save job details to switch to this tab.',
    stageNotFound: 'Stage not found',
    successMessages: {
      removedModule: 'Successfully removed module!',
      jobPublished: 'Job published successfully',
      savedSuccessfully: 'Successfully saved.',
      deletedStage: 'Successfully deleted stage'
    },
    errorMessages: {
      jobRoleNotFound: 'Job role not found'
    },
    jobHistory: {
      title: 'Job activity history'
    }
  },
  previewPortal: {
    tipNote: 'Top tips for recording your video responses ',
    startRecording: 'Start Recording',
    stopRecording: 'Stop Recording',
    gotoInstructions: 'Go to Instructions',
    aboutQuiz: 'About Quiz',
    totalQuestion: 'Total number of questions',
    quizBuiltToTestBasicKnowledge: 'This quiz is built to test basics required for this role',
    quizNote: 'You have to spend about 15 minutes of your uninterrupted time to take the quiz and compete your application.',
    videoInterviewPoints: [
      'Ensure you are in a quiet, distraction-free area',
      'Check you have enough charge on your device and a good data connection.',
      'Practice to feel comfortable with the process.',
      'Make eye contact with the camera whenever possible.',
      'Remember to grant access to your camera and microphone if requested.'
    ],
    quizInstructionPoints: [
      'Don’t navigate to other tabs or search the web while taking the test.',
      'Use a regular browser window (no incognito mode).',
      'Make your best guess on every question, using as much of the provided time as you like. Your score is not impacted by how fast you answer.',
      'Unanswered questions are considered incorrect.'
    ]
  },
  welcomePageNote: `
    <h1>Hello!</h1>
    <p>We are inviting you to undergo a one-way video interview with us.</p>
    <p>You will have the opportunity to practice before you start. When you begin, you will have a certain number of retakes available for each question and these will range from 1 to 3.Once you have retaken your question, you cannot retrieve your previous version.</p>
    <p>We will be using your videos to share with our hiring managers in order to give you the opportunity to meet with them in the next round, if successful!
    <br> If you face any technical issues, please contact
    <b><a href="mailto:support@whitecarrot.io">support@whitecarrot.io</a></b>.</p>
    <h2>Good luck!!!</h2>
    `,
  stagesPage: {
    preScreen: 'Pre-screen candidates',
    preScreenSubtitle: 'Auto-progress qualified candidates to the next stage and archive under-qualified candidates.',
    screenUsingQuestions: 'Screen using questions',
    addQuestions: 'Add questions',
    recommended: '(Recommended)',
    screenUsingQuiz: 'Screen using a skill based test',
    addSkillBasedTest: 'Add skill-based test',
    collectInformation: 'Collect information',
    collectInformationSubtitle: 'Collect more information, video and files from candidates',
    askQuestions: 'Ask questions',
    askToRecordVideo: 'Ask to record a video',
    welcomePage: 'Add a welcome message',
    welcomePageSubtitle: 'Welcome candidates who are moved to this stage.',
    addAWelcomeMessage: 'Add a welcome message',
    automation: {
      autoSendAcknowledgmentMail: 'After candidate submits the tasks, auto-send an acknowledgement mail',
      invTooltip: 'You cannot turn it off, when there are candidate tasks in this stage',
      invitationSpan: {
        s1: 'For candidates entering this stage, auto-send a'
      },
      rejTooltip: 'To turn it on, add pre-screening tasks to this stage',
      rejectionSpan: {
        s1: 'For candidates who are under-qualified in pre-screening, auto-send a',
        s2: 'after',
        s3: 'days.'
      },
      arcTooltip: 'To turn it on, add at-least one candidate task to this stage.',
      archiveSpan: {
        s1: 'For candidate who don\'t complete their tasks after',
        s2: 'days, archive them as "Unresponsive".'
      },
      infoMessages: {
        validNumber: 'Please enter a valid number. It should be greater than 0'
      },
      updatedSuccessfully: 'The automation has been updated successfully!',
      stageInvitationSavedSuccessfully: 'Stage invitation email saved successfully',
      stageInvitationDeletedSuccessfully: 'Stage invitation email deleted successfully'
    },
    previewPortalToolTip: 'Add candidate tasks to preview the candidate portal',
    recommendedForStage: 'Recommended for this stage',
    tasksReorderedMessage: 'Tasks reordered successfully!',
    removeTasksTooltip: 'To turn it off, remove all the candidate tasks in this section.',
    advAutomations: 'Advanced stage automations',
    showAdvancedAutomationSettings: 'Show advanced automation settings'
  },
  addAnotherJob: 'Add another job board',
  createPostingOnJobBoard: 'Go to the job board to create a posting: <span style="color: #5138ee">{url}<span> | Go to the job board to create a posting',
  indeedLinkToKnowMore: 'If you do not find the application link option, <a href="{url}" style="color: #5138ee" target="_blank">click here</a> to know how to enable it.',
  pasteTheLink: {
    linkedin: 'In step 2 of the job posting process on LinkedIn, select the external website for receiving applicants and paste the copied ATS application link into the website address field',
    indeed: 'Turn off “receive applications on Indeed” in set preferences step and paste the copied ATS link as the application link.',
    otherBoards: 'Paste the link as the application link in the job board posting form'
  },
  onceCandidateApply: 'Once candidates apply, track the source of their application on the candidate list',
  postThisLater: 'I’ll post this later',
  havePostedTheJob: 'I have posted the job on {jobName} | I have posted the job',
  jobBoardName: 'Job board name',
  publishedDate: 'Published date',
  applications: 'Applications',
  hires: 'Hires',
  tooltip: {
    clickToLearnAboutJobBoards: 'Click here to learn more about connecting with job boards'
  },
  talentPool: {
    linkedInMandatoryNote: 'Make it mandatory to add a LinkedIn link while applying',
    portfolioMandatoryNote: 'Make it mandatory to add a Portfolio link while applying'
  }
}

export const AddEditJobArabic = {
  jobs: 'وظائف',
  published: 'نشرت',
  closed: 'مغلقة',
  drafted: 'مسودة',
  editJob: 'تحرير الوظيفة',
  nextApplication: 'التالي: التطبيق',
  gotoJobReports: 'الانتقال إلى تقارير الوظائف',
  publish: 'نشر',
  cannotPublishNote: 'أضف جميع الحقول المطلوبة لنشر الوظيفة.',
  jobInfoNote: 'قم بإنشاء وصف الوظيفة عن طريق إضافة المعلومات الأساسية المتعلقة بهذه الوظيفة',
  previewCandidatePortal: 'معاينة بوابة المرشح',
  previewPortalRequiredNote: 'أضف جميع الحقول المطلوبة لمعاينة بوابة المرشح',
  jobApplicationSettings: 'إعدادات طلب الوظيفة',
  jobPublishType: 'أين تريد نشر هذه الوظيفة؟ {مطلوب}',
  addCandidateToTalentPool: 'السماح للموظفين بإضافة مرشحين إلى مجموعة المواهب من بوابة الموظف.',
  generateUsingAI: 'توليد باستخدام الذكاء الاصطناعي',
  saveAndProceed: 'حفظ والمتابعة',
  recruiterGPTLoading: 'جاري استخدام Recruiter GPT، يرجى الانتظار لبضع ثوانٍ...',
  pleaseFillRequired: 'يرجى ملء جميع الحقول المطلوبة',
  generate: 'توليد',
  proceed: 'المتابعة',
  areYouSure: 'هل أنت متأكد؟',
  careerPageExternal: 'صفحة الوظائف (خارجي)',
  employeePortal: 'بوابة الموظفين (داخلي)',
  jobDescriptionAIWarning: 'سيتم تجاهل وصف الوظيفة الذي كتبته يدويًا واستبداله بالوصف الذي تم إنشاءه بواسطة الذكاء الاصطناعي.',
  areYouSureToProceed: 'هل أنت متأكد أنك تريد المتابعة؟',
  questions: {
    skillExperience: 'خبرة المهارة',
    enterSkill: 'أدخل المهارة',
    jobFunction: 'الوظيفة',
    enterJobFunction: 'أدخل الوظيفة',
    enterIndustry: 'أدخل الصناعة',
    answerType: 'نوع الإجابة',
    numeric: 'رقمي',
    singleSelect: 'اختيار فردي',
    multiSelect: 'اختيار متعدد',
    yesNo: 'نعم/لا',
    freeText: 'نص حر',
    anyOfThem: 'أي منهم',
    allOfThem: 'جميعهم',
    range: 'النطاق',
    maximum: 'الحد الأقصى',
    minimum: 'الحد الأدنى',
    location: 'الموقع',
    enterLocation: 'أدخل الموقع'
  },
  labels: {
    jobName: 'اسم الوظيفة {required}',
    jobDisplayName: 'اسم الوظيفة المعروض للمرشحين {required}',
    jobDescription: 'وصف الوظيفة {required}',
    typeOfPosition: 'نوع الوظيفة {required}',
    department: 'القسم {required}',
    location: 'الموقع {required}',
    selectDepartment: 'اختر القسم',
    selectLocation: 'اختر الموقع',
    remotePolicy: 'ما هي سياستك للعمل عن بعد في هذه الوظيفة؟ {required}',
    workPlaceType: 'نوع مكان العمل {required}',
    remote: 'عن بُعد',
    onsite: 'في الموقع',
    fullyRemote: 'عن بعد بشكل كامل',
    inOfficeOnly: 'فقط في المكتب',
    flexible: 'في المكتب (مرونة في العمل من المنزل)',
    hybrid: 'نظام حضور هجبن',
    keyRequirementsOfJob: 'المتطلبات الرئيسية للوظيفة',
    keyPerksBenefits: 'فوائد وامتيازات رئيسية للوظيفة',
    addNewLocation: 'أضف موقعًا جديدًا',
    addNewDepartment: 'أضف قسم جديد'
  },
  placeHolders: {
    addRoleTitle: 'إضافة عنوان الدور',
    addJobDescription: 'إضافة وصف الوظيفة',
    selectPosition: 'تحديد نوع الوظيفة',
    addKeyRequirements: 'إضافة المتطلبات الرئيسية',
    addKeyPerksBenefits: 'إضافة فوائد وامتيازات رئيسية للوظيفة'
  },
  sectionsTab: {
    jobInformation: 'معلومات الوظيفة',
    stages: 'مراحل',
    application: 'تطبيق',
    collaborators: 'المتعاونون'
  },
  saveAndNext: {
    application: 'احفظ وانتقل: التطبيق',
    hiringFlow: 'احفظ وانتقل: سير العمل للتوظيف',
    collaborators: 'احفظ وانتقل: المتعاونون',
    publish: 'احفظ وانتقل: النشر'
  },
  successMessages: {
    generatedJobDescription: 'تم إنشاء وصف الوظيفة بنجاح',
    jobJourneyUpdated: 'تم حفظ معلومات الوظيفة بنجاح',
    applicationInfoSavedSuccessfully: 'تم حفظ معلومات التطبيق بنجاح',
    hiringFlowSavedSuccessfully: 'تم حفظ مسار التوظيف بنجاح'
  },
  errorMessages: {
    gptFailed: 'يبدو أن Recruiter GPT مشغول وفشل في الرد. يرجى المحاولة مرة أخرى.'
  },
  formRules: {
    inputRoleTitle: 'الرجاء إدخال عنوان الدور',
    inputJobDesc: 'الرجاء إدخال وصف الوظيفة',
    selectPosition: 'الرجاء تحديد نوع الوظيفة',
    selectDepartment: 'الرجاء إدخال القسم',
    selectLocation: 'الرجاء إدخال الموقع'
  },
  hiringFlow: {
    title: 'عملية التوظيف',
    sourcedCandidate: 'المرشحون المستقدمون',
    appliedCandidates: 'المرشحون الذين تقدموا',
    addStage: 'إضافة مرحلة',
    deleteStage: 'حذف مرحلة',
    candidateTasks: 'مهام المرشح',
    preview: 'معاينة',
    collectInfoFromCandidates: 'جمع المعلومات من المرشحين',
    modules: {
      addScreeningQuestion: 'إضافة أسئلة الفحص',
      addScreeningQuiz: 'إضافة اختبار فحص',
      collectInformation: 'جمع المعلومات',
      uploadFile: 'طلب رفع ملف',
      askToRecordVideo: 'طلب تسجيل فيديو',
      addCustomPage: 'إضافة صفحة مخصصة',
      autoSendInvitationEmail: 'إرسال دعوة بريد إلكتروني تلقائيًا'
    }
  },
  application: {
    candidateApplicationTasks: 'مهام تقديم الطلب للمرشح',
    autoShortListCandidates: 'الفرز التلقائي للمرشحين',
    collectInformation: 'جمع المعلومات',
    email: 'البريد الإلكتروني',
    fullName: 'الاسم الكامل',
    phoneNumber: 'رقم الهاتف',
    linkedinLink: 'رابط LinkedIn',
    portfolioLink: 'رابط المحفظة',
    cvFile: 'ملف CV',
    candidateApplicationForm: 'استمارة تقديم المرشحين',
    fields: {
      mandatory: 'إلزامي',
      optional: 'اختياري',
      off: 'معطل'
    }
  },
  publishPage: {
    publishJob: 'نشر الوظيفة | نشر الوظيفة ؟',
    publishJobsOnCareerPage: 'نشر الوظائف على صفحة الوظائف',
    publishJobNote: 'جعل الوظيفة متاحة للمرشحين للوصول إليها',
    enterSourceName: 'الرجاء إدخال اسم المصدر',
    mainPortalLink: 'رابط البوابة الرئيسية',
    cannotPublishJob: 'لا يمكنك نشر الوظيفة لأن',
    jobPublished: 'تم نشر الوظيفة',
    jobClosed: 'تم إغلاق الوظيفة',
    addLink: 'إضافة رابط',
    willBePublished: 'سيتم نشره في غضون 24 ساعة',
    addCustomLink: 'إضافة رابط مخصص',
    saveAgencies: 'حفظ الوكالات',
    addSourceName: 'إضافة اسم المصدر',
    addAgency: 'إضافة وكالات',
    generate: 'إنشاء',
    refreshAgencyList: 'تحديث قائمة الوكالات',
    publishedOn: 'نُشرت في {تاريخ}',
    viewJobHistory: 'عرض سجل نشاط العمل',
    addedLinkShowHere: 'سيتم عرض الروابط المضافة هنا.',
    customLinksHeading: 'الاتصال بمواقع العمل',
    areYouSureToPublishJob: 'هل أنت متأكد أنك تريد نشر هذه الوظيفة مرة أخرى؟',
    agenciesCollaborateNote: 'أضف أعضاء خارجيين للتعاون في هذه الوظيفة. يمكنهم فقط تقديم المرشحين',
    customLinksNote: 'اتصل بموقع العمل وتتبع المصدر عن طريق إنشاء روابط مخصصة.',
    clickHereToKnowMore: 'انقر هنا لمعرفة المزيد',
    closedJobsRemovedFromCareer: 'يتم إزالة الوظائف المغلقة من صفحة المسار المهني. يمكنك إعادة فتح هذه الوظيفة في المستقبل إذا كنت ترغب في استخدامها مرة أخرى.',
    areYouSureToCloseJob: 'هل أنت متأكد أنك تريد إغلاق هذه الوظيفة؟',
    errorText: {
      dontHaveJobOwner: 'لا تحتوي وظيفتك على أي أصحاب وظائف. يرجى إضافة مالك وظيفة.',
      noCandidateTasksAdded: 'لم تقم بإضافة أي مهام مرشح في مرحلة {مرحلة}. يرجى إضافة مهمة مرشح واحدة على الأقل.'
    },
    successMessages: {
      generatedCustomLink: 'تم إنشاء الرابط المخصص بنجاح.',
      publishedJob: 'تم نشر الوظيفة بنجاح.',
      closedJob: 'تم إغلاق الوظيفة بنجاح',
      updatedAgencies: 'تم تحديث الوكالات بنجاح',
      deletedCustomLink: 'تم حذف الرابط المخصص بنجاح.'
    },
    copyLink: 'نسخ الرابط',
    deleteLink: 'حذف الرابط',
    closeJob: 'إغلاق الوظيفة | إغلاق الوظيفة ؟',
    linkCopied: 'تم نسخ الرابط',
    linkNotCopied: 'لم يتم نسخ الرابط'
  },
  addCollaborators: {
    title: 'إضافة متعاونين',
    description: 'حدد متعاونين لهذه الوظيفة. قم بدعوة أعضاء جدد إذا لم ترهم في القائمة.',
    addNewTeamMember: 'إضافة عضو جديد في الفريق',
    saveProceed: 'حفظ والمتابعة',
    refreshUsersList: 'تحديث قائمة المستخدمين',
    fetchingData: 'جارٍ جلب البيانات...',
    savedCollaboratorSettings: 'تم حفظ إعدادات المتعاونين.',
    jobOwner: {
      label: 'مالك الوظيفة *',
      placeholder: 'اختر المالك'
    },
    recruiter: {
      label: 'مسؤول التوظيف {required}',
      placeHolder: 'اختر مسؤول التوظيف'
    },
    jobHiringManager: {
      label: 'مدير التوظيف',
      placeholder: 'اختر مدير التوظيف',
      optional: '(اختياري)'
    },
    collaborators: {
      label: 'المتعاونون ذوو الوصول إلى هذه الوظيفة والمرشحين',
      placeholder: 'أضف أعضاء الفريق'
    },
    membersPermissionCard: {
      inviteSent: 'تم إرسال الدعوة',
      fullAccess: 'الأعضاء ذوو الوصول الكامل يمكنهم تعديل جميع الوظائف والوصول إلى المعلومات السرية.',
      editJob: 'تحرير هذه الوظيفة',
      sendAndViewOfferLetters: 'إرسال وعرض رسائل العرض',
      moveStageAndSendEmail: 'نقل المراحل وإرسال رسائل البريد الإلكتروني',
      selectStages: 'اختر المراحل',
      viewNotesEmails: 'عرض الملاحظات والرسائل السرية',
      limitedAccess: 'الوصول السري لا يمكن إزالته إذا كان المتعاون مسموحًا له بتحرير الوظيفة.',
      accessCandidateProfile: 'الوصول إلى ملف المرشح'
    }
  },
  stages: {
    stageWelcome: 'مرحباً بك في المرحلة',
    allStages: 'جميع المراحل',
    jobReports: 'تقارير الوظائف',
    pleaseEnterStageName: 'يرجى إدخال اسم المرحلة',
    stageNameCannotBeEmpty: 'اسم المرحلة لا يمكن أن يكون فارغًا',
    candidatesSourced: {
      title: 'المرشحون الذين تم الحصول عليهم',
      note: 'جميع المرشحين الذين يتم الإشارة إليهم من قبل الموظفين أو تقديمهم من قبل الوكالات يبدأون من هذه المرحلة'
    },
    stageName: 'المرحلة {no}: {name}',
    candidatesWhoApply: {
      title: 'المرشحون الذين يتقدمون بطلب',
      note: 'جميع المرشحين القادمين من لوحات الوظائف أو صفحات الوظائف يبدأون من هذه المرحلة'
    },
    buttons: {
      addStage: 'إضافة مرحلة',
      previewCandidatePortal: 'معاينة بوابة المرشحين',
      findQuizMatching: 'العثور على اختبار يتناسب مع الوظيفة'
    },
    placeHolders: {
      enterStageName: 'أدخل اسم المرحلة',
      chooseShortlistingTasks: 'اختر المهام القصيرة المحددة مسبقًا',
      startDate: 'تاريخ البدء',
      endDate: 'تاريخ الانتهاء',
      selectTemplate: 'اختر القالب',
      noEmailTemplateSelected: 'لم يتم تحديد قالب البريد الإلكتروني'
    },
    tooltips: {
      addWelcomeMessageNote: 'إضافة رسالة ترحيب لمعاينة بوابة المرشحين',
      addCandidateTasksNote: 'إضافة مهام المرشح لمعاينة بوابة المرشحين',
      editScreeningQuestions: 'تحرير أسئلة الفحص',
      editCandidateInfoQuestions: 'تحرير أسئلة معلومات المرشح',
      editSkillBasedTest: 'تحرير اختبار قائم على المهارات',
      editVideoQuestions: 'تحرير أسئلة الفيديو',
      editWelcomeMessageAndVideo: 'تحرير رسالة الترحيب والفيديو'
    },
    insights: {
      totalInStage: 'الإجمالي في المرحلة',
      progressed: 'تقدم',
      movedBack: 'تم الرجوع',
      archived: 'تم الأرشفة',
      qualificationRate: 'معدل التأهيل',
      completionRate: 'معدل الانتهاء',
      inProgress: 'قيد التقدم',
      started: 'بدأت',
      completed: 'اكتملت',
      qualifyingScore: 'الدرجة المؤهلة',
      totalQuestions: 'إجمالي الأسئلة',
      currentlyToReview: 'حالياً للمراجعة',
      currentlyInProgress: 'حالياً قيد التقدم',
      currentlyInPipeline: 'حالياً في الأنبوب',
      candidatesHavingExperienceGreaterThan: 'المرشحون الذين يمتلكون خبرة أكبر من أو تساوي {years} سنوات',
      candidatesHavingExperienceLessThan: 'المرشحون الذين يمتلكون خبرة أقل من {years} سنوات',
      candidateWillingToRelocate: 'المرشحون الموجودين حالياً في {location} أو مستعدين للانتقال إليها',
      candidateNotWillingToRelocate: 'المرشحون الذين لا يوجدون حالياً في {location} ولا يرغبون في الانتقال إليها',
      candidateRelocateToTimezone: 'المرشحون الذين يوجدون حالياً في منطقة زمنية {timezone} أو مستعدين للانتقال إليها',
      candidateNotRelocateToTimezone: 'المرشحون الذين لا يوجدون حالياً في منطقة زمنية {timezone} ولا يرغبون في الانتقال إليها'
    },
    warnings: {
      addAtleastOneActivity: '* يجب إضافة نشاط واحد على الأقل لنشر هذه الصفحة'
    },
    mustHave: 'يجب أن يكون لديك:',
    mustSelectOption: 'يجب اختيار خيار ',
    multiSelectAns: 'يجب اختيار أي منهم | يجب اختيارهم جميعًا',
    expectedAnswerInRange: 'يجب أن يكون الجواب ضمن النطاق {min} - {max}',
    expectedAnswerMinimum: 'يجب أن يكون الجواب أكبر من {min}',
    expectedAnswerMaximum: 'يجب أن يكون الجواب أقل من {max}',
    qualified: 'مؤهل',
    editTemplate: 'تحرير القالب',
    removeTemplate: 'إزالة القالب',
    timeGuideline: 'الإرشاد الزمني',
    allowedTime: 'الوقت المسموح به',
    recommendedTime: 'الوقت الموصى به',
    evaluation: 'التقييم',
    underQualified: 'غير مؤهل تحت',
    addQuestions: 'إضافة أسئلة',
    candidatesQualification: 'مرشحون {qualification}',
    responsesReceived: 'تم استلام {no} من الردود',
    candidateMustHaveExperience: 'يجب أن يمتلك المرشح {no} {year} من الخبرة',
    screeningQuestions: 'الفرز بواسطة الأسئلة',
    screenOutBasedOn: 'استبعاد المرشحين استنادًا إلى موقعهم، والحد الأدنى من الخبرة، ومعايير أخرى.',
    screenOutUnRequiredCandidates: 'استبعاد المرشحين الذين لا يلبون المهارات الأدنى المطلوبة للدور.',
    collectRelevantInfo: 'جمع المعلومات ذات الصلة مثل الراتب المتوقع، والفوائد، والراتب الحالي، إلخ.',
    videoQuestions: 'أسئلة الفيديو',
    videoCollectionNote: 'جمع استجابات الفيديو المسجلة من المرشحين، مما يمكنهم من عرض مهارات التواصل، وقدرات العرض، وملاءمتهم للدور.',
    screeningSkillBasedTest: 'الفرز بواسطة اختبار قائم على المهارات',
    candidateTasks: 'مهام المرشح',
    applicationStageNote: 'استبعاد المرشحين غير المؤهلين باستخدام أسئلة الإقصاء واختبارات قائمة على المهارات.',
    autoProgressAvailable: 'التقدم التلقائي متاح',
    shortlistingType: 'نوع القائمة القصيرة',
    lastStageNote: 'المرحلة الأخيرة من أنبوب التوظيف، نقل المرشحين الذين تم توظيفهم',
    candidateInformationCollection: 'جمع معلومات المرشح',
    emailSendingNote: 'سيتم إرسال هذا البريد الإلكتروني إلى المرشحين الذين تم نقلهم إلى هذه المرحلة.',
    welcomeMessage: 'رسالة الترحيب على بوابة المرشحين',
    welcomeCandidateToStage: 'ترحيب المرشحين بالمرحلة',
    stageWelcomeEmail: 'رسالة ترحيب المرحلة',
    welcomeCandidateWithVideo: 'ترحيب المرشحين برسالة أو فيديو',
    min: '{number} الحد الأدنى'
  },
  dialogs: {
    areYouSure: 'هل أنت متأكد؟',
    stageLinkNotFound: 'رابط المرحلة مفقود للمرشح. يرجى اختيار البريد الإلكتروني الذي يحتوي على رابط المرحلة',
    yourUploadsDeleted: 'سيتم حذف رفعك',
    editStageName: 'تعديل اسم المرحلة',
    module: {
      resetModule: 'إعادة تعيين الوحدة',
      resetModuleNote: 'هل أنت متأكد أنك تريد إعادة تعيين هذه الوحدة؟'
    },
    deleteStage: {
      description: 'هذا الإجراء سيقوم بإزالة المهام المرشحة الحالية. إذا كنت ترغب في الاحتفاظ بالمهام الحالية، قم بإضافة مرحلة جديدة بدلاً من ذلك.',
      warning: 'لا يمكن التراجع عن هذا الإجراء. هل أنت متأكد؟',
      noQuestionsWarning: 'لا توجد أسئلة مضافة. ستتم إعادة تعيين المرحلة إلى فارغة'
    },
    cannotDeleteStage: {
      description: 'لا يمكنك حذف هذه المرحلة',
      info: 'انقل المرشحين خارج هذه المرحلة أو قم بأرشفتهم قبل حذف المرحلة.'
    },
    jobDescriptionLost: 'ستفقد التغييرات الخاصة بك ولن يتم حفظ الوظيفة.',
    buttons: {
      resetModule: 'إعادة تعيين الوحدة'
    },
    candidatePortalJobPreview: 'معاينة الوظيفة في بوابة المرشحين',
    templatePreview: 'معاينة القالب',
    selectEmailTemplate: 'اختر قالب البريد الإلكتروني',
    manageTemplate: 'إدارة القالب',
    refreshTemplateList: 'تحديث قائمة القوالب',
    unsavedChangedWarning: 'لديك تغييرات غير محفوظة. انقر فوق "حفظ كمسودة" لحفظ التغييرات. هل أنت متأكد ما زلت ترغب في الخروج؟',
    saveYourDetailsWarning: 'يرجى حفظ تفاصيل الوظيفة للتبديل إلى هذا العلامة التبويب.',
    stageNotFound: 'المرحلة غير موجودة',
    successMessages: {
      removedModule: 'تمت إزالة الوحدة بنجاح!',
      jobPublished: 'تم نشر الوظيفة بنجاح',
      savedSuccessfully: 'تم الحفظ بنجاح.',
      deletedStage: 'تم حذف المرحلة بنجاح'
    },
    errorMessages: {
      jobRoleNotFound: 'الدور الوظيفي غير موجود'
    },
    jobHistory: {
      title: 'سجل نشاط العمل'
    }
  },
  previewPortal: {
    tipNote: 'أفضل النصائح لتسجيل ردود الفيديو الخاصة بك',
    startRecording: 'بدء التسجيل',
    stopRecording: 'إيقاف التسجيل',
    gotoInstructions: 'الانتقال إلى التعليمات',
    aboutQuiz: 'حول الاختبار',
    totalQuestion: 'إجمالي عدد الأسئلة',
    quizBuiltToTestBasicKnowledge: 'تم بناء هذا الاختبار لاختبار المفاهيم الأساسية المطلوبة لهذا الدور',
    quizNote: 'عليك أن تقضي حوالي 15 دقيقة من وقتك دون انقطاع لإجراء الاختبار وإكمال طلبك.',
    videoInterviewPoints: [
      'تأكد من أنك في منطقة هادئة وخالية من التشتت',
      'تحقق مما إذا كان لديك شحن كافٍ في جهازك واتصال جيد بالبيانات.',
      'تمارس لتشعر بالراحة مع العملية.',
      'تحافظ على الاتصال بالعين مع الكاميرا في كل مرة يكون ذلك ممكناً.',
      'تذكر أن تمنح الوصول إلى الكاميرا والميكروفون إذا طلب ذلك.'
    ],
    quizInstructionPoints: [
      'لا تتصفح علامات تبويب أخرى أو تبحث في الويب أثناء إجراء الاختبار.',
      'استخدم نافذة متصفح عادية (بدون وضع التصفح الخفي).',
      'قدم أفضل تخمين لديك على كل سؤال، باستخدام الوقت المقدم قدر الإمكان. لا يتأثر درجتك بمدى سرعة إجابتك.',
      'تُعتبر الأسئلة غير المجابة خاطئة.'
    ]
  },
  welcomePageNote: `
    <h1>مرحبًا!</h1>
    <p>نحن ندعوك لإجراء مقابلة فيديو من اتجاه واحد معنا.</p>
    <p>سوف تكون لديك الفرصة للتدريب قبل أن تبدأ. عندما تبدأ، ستكون لديك عدد معين من محاولات الإعادة لكل سؤال وهذه ستتراوح بين 1 و 3. بمجرد أن تقوم بإعادة السؤال، لن تتمكن من استرداد الإصدار السابق الخاص بك.</p>
    <p>سنستخدم فيديوهاتك لمشاركتها مع مديري التوظيف لدينا من أجل منحك الفرصة للقاءهم في الجولة التالية، في حال نجاحك!
    <br> إذا واجهت أي مشكلات تقنية، يرجى الاتصال بـ
    <b><a href="mailto:support@whitecarrot.io">support@whitecarrot.io</a></b>.</p>
    <h2>حظًا موفقًا!!!</h2>
  `,
  stagesPage: {
    preScreen: 'فحص أولي للمرشحين',
    preScreenSubtitle: 'تقدم تلقائيًا بالمرشحين المؤهلين إلى المرحلة التالية وأرشفة المرشحين غير المؤهلين.',
    screenUsingQuestions: 'فحص باستخدام الأسئلة',
    addQuestions: 'إضافة أسئلة',
    recommended: '(موصى به)',
    screenUsingQuiz: 'فحص باستخدام اختبار مبني على المهارات',
    addSkillBasedTest: 'إضافة اختبار مبني على المهارات',
    collectInformation: 'جمع المعلومات',
    collectInformationSubtitle: 'جمع المزيد من المعلومات، الفيديو، والملفات من المرشحين',
    askQuestions: 'طرح الأسئلة',
    askToRecordVideo: 'طلب تسجيل فيديو',
    welcomePage: 'إضافة رسالة ترحيب',
    welcomePageSubtitle: 'ترحيب بالمرشحين الذين تم نقلهم إلى هذه المرحلة.',
    addAWelcomeMessage: 'إضافة رسالة ترحيب',
    automation: {
      autoSendAcknowledgmentMail: 'بعد أن يقدم المرشح المهام، أرسل بريدًا إلكترونيًا تلقائيًا لتأكيد الاستلام',
      invTooltip: 'لا يمكنك إيقاف تشغيله، عندما يوجد مهام مرشح في هذه المرحلة',
      invitationSpan: {
        s1: 'للمرشحين الداخلين إلى هذه المرحلة، إرسال تلقائي لـ'
      },
      rejTooltip: 'لتفعيله، أضف مهام فحص أولي إلى هذه المرحلة',
      rejectionSpan: {
        s1: 'للمرشحين الذين هم تحت المستوى المطلوب في الفحص الأولي، إرسال تلقائي لـ',
        s2: 'بعد',
        s3: 'أيام.'
      },
      arcTooltip: 'لتفعيله، أضف مهمة مرشح واحدة على الأقل إلى هذه المرحلة.',
      archiveSpan: {
        s1: 'للمرشح الذي لا يكمل مهامه بعد',
        s2: 'أيام، أرشفهم كـ "Unresponsive".'
      },
      infoMessages: {
        validNumber: 'يرجى إدخال رقم صحيح. يجب أن يكون أكبر من 0'
      },
      updatedSuccessfully: 'تم تحديث الأتمتة بنجاح'
    },
    previewPortalToolTip: 'أضف مهام المرشحين لمعاينة بوابة المرشحين',
    recommendedForStage: 'موصى به لهذه المرحلة',
    tasksReorderedMessage: 'تم إعادة ترتيب المهام بنجاح!',
    removeTasksTooltip: 'لإيقافه، قم بإزالة جميع مهام المرشحين في هذا القسم',
    advAutomations: 'أتمتة المراحل المتقدمة',
    showAdvancedAutomationSettings: 'عرض إعدادات الأتمتة المتقدمة',
    addAnotherJob: 'أضف لوحة وظائف أخرى',
    createPostingOnJobBoard: 'قم بإنشاء منشور على لوحة الوظائف',
    pasteTheLink: 'في الخطوة 2 من عملية نشر الوظيفة، اختر الموقع الخارجي لاستقبال المتقدمين والصق رابط طلب ATS المنسوخ في حقل عنوان الموقع | الصق الرابط كرابط التقديم في نموذج نشر الوظيفة على لوحة الوظائف',
    onceCandidateApply: 'بمجرد أن يتقدم المرشحون، تتبع مصدر طلباتهم في قائمة المرشحين.',
    postThisLater: 'سأنشر هذا لاحقًا',
    havePostedTheJob: 'لقد نشرت الوظيفة',
    jobBoardName: 'اسم لوحة الوظائف',
    publishedDate: 'تاريخ النشر',
    applications: 'الطلبات',
    hires: 'التعيينات'
  },
  createPostingOnJobBoard: 'اذهب إلى لوحة الوظائف لإنشاء منشور: <span style="color: #5138ee">{url}<span> | اذهب إلى لوحة الوظائف لإنشاء منشور',
  havePostedTheJob: 'لقد نشرت الوظيفة على {jobName} | لقد نشرت الوظيفة',
  tooltip: {
    clickToLearnAboutJobBoards: 'انقر هنا لمعرفة المزيد عن الاتصال بلوحات الوظائف',
    advAutomations: 'أتمتة المراحل المتقدمة'
  },
  talentPool: {
    linkedInMandatoryNote: 'اجعل إضافة رابط LinkedIn إلزاميًا أثناء التقديم',
    portfolioMandatoryNote: 'اجعل إضافة رابط الحافظة إلزاميًا أثناء التقديم'
  },
  indeedLinkToKnowMore: 'إذا لم تجد خيار رابط التقديم، <a href="{url}" style="color: #5138ee">انقر هنا</a> لمعرفة كيفية تفعيله.',
  pasteTheLink: {
    linkedin: 'في الخطوة 2 من عملية نشر الوظيفة على LinkedIn، اختر الموقع الخارجي لاستقبال المتقدمين والصق رابط التقديم المنسوخ من ATS في حقل عنوان الموقع.',
    indeed: 'قم بإيقاف تشغيل "استلام الطلبات على Indeed" في خطوة إعداد التفضيلات والصق رابط ATS المنسوخ كرابط للتقديم.',
    otherBoards: 'الصق الرابط كرابط التقديم في نموذج نشر الوظيفة على لوحة الوظائف'
  }
}
