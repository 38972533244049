var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-2 items-start justify-start w-1/4 relative sidebar"},[_c('div',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getCompanySectionPermissions),expression:"getCompanySectionPermissions"}],staticClass:"border-solid border-[#e9e9ec] pt-0 pr-0 pb-3 pl-0 flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative",staticStyle:{"border-width":"0 0 1px 0"}},[_c('div',{staticClass:"relative self-stretch",staticStyle:{"font":"600 14px/20px 'Inter', sans-serif","color":"#010113"}},[_vm._v(" "+_vm._s(_vm.$t('recruiterSettings.company'))+" ")]),_c('div',{staticClass:"flex flex-col items-start justify-start self-stretch shrink-0 relative"},[_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getCareerBrandPagePermissions),expression:"getCareerBrandPagePermissions"}],staticClass:"text-left relative",attrs:{"link":{
          name: _vm.$t('recruiterSettings.companyBrand'),
          to: `/recruiter-settings/brands`,
          active: 'brands',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }}}),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getTeamsPagePermissions),expression:"getTeamsPagePermissions"}],staticClass:"text-left relative",attrs:{"data-testid":"team-members","link":{
        name: _vm.$t('recruiterSettings.teamMembers'),
        to: `/recruiter-settings/teams`,
        active: 'teams',
        class: 'p-2',
        inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
      }}}),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getLocationAndDepartmentPermissions),expression:"getLocationAndDepartmentPermissions"}],staticClass:"text-left relative",attrs:{"data-testid":"company-locations","link":{
        name: _vm.$t('recruiterSettings.locationAndDepartments'),
        to: `/recruiter-settings/locations-departments`,
        active: 'locations-departments',
        class: 'p-2',
        inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
      }}})],1)]),_c('div',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getRecruitingPermissions),expression:"getRecruitingPermissions"}],staticClass:"border-solid border-[#e9e9ec] pt-3 pr-0 pb-3 pl-0 flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative",staticStyle:{"border-width":"0px 0px 1px 0px"}},[_c('div',{staticClass:"text-left relative",staticStyle:{"font":"600 14px/20px 'Inter', sans-serif","color":"#010113"}},[_vm._v(" "+_vm._s(_vm.$t('recruiterSettings.recruiting'))+" ")]),_c('div',{staticClass:"flex flex-col items-start justify-start self-stretch shrink-0 relative"},[_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.stagePipelineArchivalReasonPermissions),expression:"stagePipelineArchivalReasonPermissions"}],staticClass:"text-left relative",attrs:{"link":{
        name: _vm.$t('recruiterSettings.defaultPipelineAndArchivalReasons'),
        to: `/recruiter-settings/stage-pipeline`,
        active: 'stage-pipeline',
        class: 'p-2',
        inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
      }}}),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getCompanyBookingLinkManagePermissions),expression:"getCompanyBookingLinkManagePermissions"}],staticClass:"text-left relative",attrs:{"link":{
          name: _vm.$t('recruiterSettings.interviewBookingLinks'),
          to: `/recruiter-settings/interview-booking`,
          active: 'interview-booking',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }}}),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getInterviewFeedbackTemplateManagePermissions),expression:"getInterviewFeedbackTemplateManagePermissions"}],staticClass:"text-left relative",attrs:{"link":{
          name: _vm.$t('recruiterSettings.interviewScorecardTemplates'),
          to: `/recruiter-settings/interview-scorecard-templates`,
          active: 'interview-scorecard-templates',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }}}),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getCompanyEmailTemplateManagePermissions),expression:"getCompanyEmailTemplateManagePermissions"}],staticClass:"text-left relative",attrs:{"link":{
        name: _vm.$t('recruiterSettings.emailTemplates'),
        to: `/recruiter-settings/manage-emails`,
        active: 'manage-emails',
        class: 'p-2',
        inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
      }}}),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getOfferLetterTemplateManagePermissions),expression:"getOfferLetterTemplateManagePermissions"}],staticClass:"text-left relative",attrs:{"link":{
        name: _vm.$t('listOffers.title'),
        to: `/recruiter-settings/offers`,
        active: 'offers',
        class: 'p-2',
        inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
      }}}),(_vm.canShowIntegrationsRoute())?_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getCompanyHrmsIntegrationManagePermissions),expression:"getCompanyHrmsIntegrationManagePermissions"}],staticClass:"text-left relative",attrs:{"link":{
        name: _vm.$t('recruiterSettings.integrations'),
        to: `/recruiter-settings/company-integrations`,
        active: 'company-integrations',
        class: 'p-2',
        inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
      }}}):_vm._e(),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getAgenciesManagePermissions),expression:"getAgenciesManagePermissions"}],staticClass:"text-left relative",attrs:{"link":{
        name: _vm.$t('recruiterSettings.agencies'),
        to: `/recruiter-settings/agencies`,
        active: 'agencies',
        class: 'p-2',
        inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
      }}})],1)]),_c('div',{staticClass:"pt-3 pr-0 pb-3 pl-0 flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative"},[_c('div',{staticClass:"text-left relative self-stretch",staticStyle:{"font":"600 14px/20px 'Inter', sans-serif","color":"#010113"}},[_vm._v(" "+_vm._s(_vm.$t('recruiterSettings.personal'))+" ")]),_c('div',{staticClass:"flex flex-col items-start justify-start self-stretch shrink-0 relative"},[_c('LinkButton',{staticClass:"text-left relative",attrs:{"link":{
          name: _vm.$t('recruiterSettings.profile'),
          to: `/recruiter-settings/profile`,
          active: 'profile',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }}}),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getCalendarEmailIntegrationsManagePermissions),expression:"getCalendarEmailIntegrationsManagePermissions"}],staticClass:"text-left relative",attrs:{"link":{
          name: _vm.$t('recruiterSettings.calendarAndEmailIntegration'),
          to: `/recruiter-settings/app-integration`,
          active: 'app-integration',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }}}),_c('LinkButton',{directives:[{name:"permissions",rawName:"v-permissions",value:(_vm.getNotificationPermissions),expression:"getNotificationPermissions"}],staticClass:"text-left relative",attrs:{"link":{
          name: _vm.$t('recruiterSettings.notificationPreferences'),
          to: `/recruiter-settings/notification-preferences`,
          active: 'notification-preferences',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }