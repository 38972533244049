export const ScreeningQuizEnglish = {
  testRecommendedFor: 'Tests recommended by Recruiter GPT for {role}',
  mustHaveSkills: 'Must Have Skills:',
  testPreparedByMasters: 'This test is prepared by subject matter experts.',
  noOfQuestions: 'Number of questions:',
  countNotFindPerfectQuiz: 'Could not find what you are looking for?',
  pickAnother: 'Pick another test',
  mustHaveScore: 'Must have score',
  reviewTest: 'Review test',
  estimatedTime: 'Estimated Time',
  selectTest: 'Select test',
  searchByRole: 'Search by role',
  searchByDepartment: 'Search by department',
  jobTitle: 'Job Title',
  testNotAdded: 'Test not added',
  viewQuestionsLibrary: 'View question library',
  dontWantToAddAnyTest: 'Don’t want to add any test?',
  areYouSureToDelete: 'Are you sure to delete this?',
  removeTest: 'Remove test',
  noThanks: 'No thanks',
  recommendedTests: 'Recommended tests',
  enterPercentage: 'Enter percentage',
  recommendedQuizScoreTo: 'Recommended - If you set it to 60%, the difficulty on the test is adjusted to show you the top 30-40% of the candidate pool.',
  weDontRecommendLessTime: 'We don’t recommend screening test time exceeding {minutes} minutes.',
  couldNotFindRoleOrSkill: 'Could not find the relevant skill or role',
  aboutTheSkill: 'About the skill',
  willPickNoOfQuestion: 'We will pick {no} questions from this skill library. The questions are randomized to prevent cheating.',
  selectTheCorrectAnswer: 'Select the correct answer',
  isRaleMatching: 'Is this role not matching your requirements?',
  screeningBySkillBasedTest: 'Screening by skill based test',
  screenOutNote: 'Screen out candidates based on very foundational / basic knowledge required for the role.',
  exploreNote: 'Feel free to explore our test library for other exciting options!',
  screenOutUnderQualified: 'As per JD, this test will screen out the underqualified candidates'
}

export const ScreeningQuizArabic = {
  testRecommendedFor: 'الاختبارات الموصى بها من قبل Recruiter GPT لـ {role}',
  mustHaveSkills: 'المهارات اللازمة:',
  testPreparedByMasters: 'تم إعداد هذا الاختبار من قبل خبراء في الموضوع.',
  noOfQuestions: 'عدد الأسئلة:',
  countNotFindPerfectQuiz: 'تعذر العثور على ما تبحث عنه؟',
  pickAnother: 'اختر اختبارًا آخر',
  mustHaveScore: 'يجب أن يكون لديك درجة',
  reviewTest: 'مراجعة الاختبار',
  estimatedTime: 'الوقت المقدر',
  selectTest: 'اختر الاختبار',
  searchByRole: 'البحث حسب الدور',
  searchByDepartment: 'البحث حسب القسم',
  jobTitle: 'عنوان الوظيفة',
  testNotAdded: 'الاختبار غير مضاف',
  viewQuestionsLibrary: 'عرض مكتبة الأسئلة',
  dontWantToAddAnyTest: 'لا ترغب في إضافة أي اختبار؟',
  areYouSureToDelete: 'هل أنت متأكد من حذف هذا؟',
  removeTest: 'إزالة الاختبار',
  noThanks: 'لا شكرا',
  recommendedTests: 'الاختبارات الموصى بها',
  enterPercentage: 'أدخل النسبة المئوية',
  recommendedQuizScoreTo: 'موصى به - إذا ضبطته على 60٪ ، يتم ضبط صعوبة الاختبار ليعرض لك أفضل 30-40٪ من بركة المرشحين.',
  weDontRecommendLessTime: 'نحن لا نوصي بزمن اختبار الفحص يزيد عن {minutes} دقيقة.',
  couldNotFindRoleOrSkill: 'تعذر العثور على المهارة أو الدور ذي الصلة',
  aboutTheSkill: 'عن المهارة',
  willPickNoOfQuestion: 'سنختار {no} أسئلة من مكتبة هذه المهارة. تتم ترتيب الأسئلة بشكل عشوائي لمنع الغش.',
  selectTheCorrectAnswer: 'اختر الإجابة الصحيحة',
  isRaleMatching: 'هل هذا الدور لا يتطابق مع متطلباتك؟',
  screeningBySkillBasedTest: 'الفحص بواسطة اختبار قائم على المهارة',
  screenOutNote: 'فصل المرشحين بناءً على المعرفة الأساسية جدًا / الأساسية المطلوبة للدور.',
  exploreNote: 'لا تتردد في استكشاف مكتبتنا للاختبارات لخيارات مثيرة أخرى!',
  screenOutUnderQualified: 'وفقًا لوصف الوظيفة، سيؤدي هذا الاختبار إلى فصل المرشحين غير المؤهلين'
}
