export const CareerArabic = {
  careerPage: 'الصفحة المهنية',
  subTitle: 'قم بإعادة توجيه المرشحين إلى صفحة الوظائف المستضافة بواسطة Whitecarrot أو قم بإنشاء صفحتك الخاصة',
  tooltipText: 'انقر هنا للتعرف على المزيد حول صفحة التوظيف',
  pageLiveAt: 'صفحة حياتك المهنية مباشرة على الرابط أدناه',
  customiseCareerPage: 'استخدم الرابط أدناه لإعادة توجيه صفحة الوظائف على موقع شركتك',
  tooltip: {
    atsHostedCareerPage: 'انقر هنا لمعرفة المزيد عن صفحة الوظائف المستضافة بواسطة نظام تتبع المتقدمين',
    moreAboutApiIntegration: 'انقر هنا لمعرفة المزيد عن تكامل API'
  },
  useApiTokenToDisplayJobs: 'استخدم رمز API أدناه لعرض الوظائف مع التخصيص الخاص بك على موقعك الإلكتروني',
  successMessage: {
    careerPageCopied: 'تم نسخ رابط صفحة الوظائف إلى الحافظة',
    tokenCopied: 'تم نسخ رمز API إلى الحافظة'
  }
}

export const CareerEnglish = {
  careerPage: 'Career page',
  subTitle: 'Redirect candidates to the Whitecarrot hosted career page OR build your own page',
  tooltipText: 'Click here to learn more about careers page',
  pageLiveAt: 'Your career page is live on below link',
  customiseCareerPage: 'Use the below link to redirect the careers page on your company website',
  tooltip: {
    atsHostedCareerPage: 'Click here to learn more about ATS-hosted career page',
    moreAboutApiIntegration: 'Click here to learn more about API integration'
  },
  useApiTokenToDisplayJobs: 'Use the Company ID provided below to list jobs through our API.',
  successMessage: {
    careerPageCopied: 'Career page link copied to clipboard',
    tokenCopied: 'Company ID is copied to clipboard'
  }
}
