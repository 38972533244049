import { render, staticRenderFns } from "./WcIconSend.vue?vue&type=template&id=0abf62f2&scoped=true"
import script from "./WcIconSend.vue?vue&type=script&lang=ts"
export * from "./WcIconSend.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abf62f2",
  null
  
)

export default component.exports