export const JobsEnglish = {
  jobs: 'Jobs',
  searchByTitle: 'Search by title',
  skipToPublish: 'Skip to publish',
  department: 'Department | Department ({idx})',
  owner: 'Owner | Owner ({idx})',
  recruiter: 'Recruiter | Recruiters ({count})',
  hiringManager: 'Hiring manager | Hiring managers ({count})',
  addNewJob: 'Add new job',
  selectedFilters: 'Selected Filters',
  noJobsFound: 'No jobs found',
  noJobsOverviewDescription: 'All the published, draft, and closed jobs will show up here!',
  editTalentPool: 'Edit Talent Pool',
  companyRoleStatusEnum: {
    published: 'Published',
    closed: 'Closed',
    draft: 'Draft'
  },
  jobsStatusEnum: {
    draftJobs: 'Draft Jobs',
    publishedJobs: 'Published Jobs',
    closedJobs: 'Closed Jobs',
    talentPool: 'Talent Pool'
  },
  companyJobPublishType: {
    internal: 'Internal',
    external: 'External',
    talentPool: 'Talent Pool'
  },
  collectApplication: {
    title: 'Collect application',
    tooltip: 'Collect basic information from the candidate',
    linkedInProfileLink: 'LinkedIn profile link',
    portfolio: 'Portfolio',
    cv: 'Candidate CV',
    cvMandatoryText: 'Candidate CV is mandatory you can\'t turn it off'
  },
  addCandidateTasks: {
    title: 'Add candidate tasks',
    tooltipText: 'Add tasks to screen candidates or collect information from them'
  },
  jobsViewDescription: 'View job description',
  reportIcon: 'report-icon',
  viewJobsReport: 'View job reports',
  editJob: 'Edit job',
  toolTip: {
    viewCandidates: 'View candidates',
    toolTipSeq1: 'All candidates referred by employees or submitted by agencies start from this stage',
    toolTipSeq2: 'All candidates coming from job boards or career pages start from this stage'
  },
  options: {
    closeJob: 'Close job | Close talent pool',
    publishJob: 'Publish job | Publish talent pool',
    cloneJob: 'Clone job',
    deleteJob: 'Delete job',
    viewJob: 'View job'
  },
  deleteDialog: {
    title: 'Are you sure you want to delete the Job'
  },
  closeDialog: {
    title: 'Are you sure you want to close this job | Are you sure you want to close the talent pool',
    body: 'Closed jobs are removed from the Career page. You can reopen this job in future if you want to use it again. | This will remove the talent pool from the career page and employee portal. You can re-open it, if you want to use it again.'
  },
  republishDialog: {
    title: 'Are you sure you want to publish this job again | Are you sure you want to publish the talent pool again'
  },
  jobJourneyDialog: {
    edit: 'Edit Job Journey',
    cloneAsDraft: 'Clone as Draft',
    addANew: 'Add a new Job Journey',
    syncJobs: 'Sync Jobs'
  },
  cloneDialog: {
    jobPosting: 'Select Job Posting',
    selectFromAts: 'Select from ATS',
    displayName: 'Job Display Name for Candidates',
    addRoleTitle: 'Add Role Title',
    selectOwner: 'Select Owner',
    owner: 'Owner',
    department: 'Department',
    location: 'Location',
    cloneAsDraft: 'Clone as draft'
  },
  loading: 'Loading...',
  errorMessage: {
    successfullyClosed: 'Successfully closed job. | Successfully closed talent pool',
    successfullyPublished: 'Successfully published job. | Successfully published talent pool',
    enterJobName: 'Please enter Job display name',
    selectOwnerName: 'Please select owner name',
    enterDepartmentName: 'Please enter department name',
    enterLocation: 'Please enter location',
    successfullyDeletedSample: 'Sample data deleted successfully',
    successfullyDeletedJobRole: 'Role deleted successfully',
    unableToDeleteJobRole: 'Cannot delete this job. Please move the candidates to another job and try again.'
  },
  shareTalentPool: 'Share talent pool'
}
export const JobsArabic = {
  jobs: 'وظائف',
  skipToPublish: 'تخطي للنشر',
  searchByTitle: 'البحث حسب العنوان',
  department: 'القسم | القسم ({idx})',
  owner: 'المالك | المالك ({idx})',
  recruiter: 'المُجنِّد | المُجنِّدون ({count})',
  hiringManager: 'مدير التوظيف | مدراء التوظيف ({count})',
  addNewJob: 'إضافة وظيفة جديدة',
  selectedFilters: 'الفلاتر المحددة',
  noJobsFound: 'لم يتم العثور على وظائف',
  noJobsOverviewDescription: 'جميع الوظائف المنشورة والمسودات والمغلقة ستظهر هنا!',
  editTalentPool: 'تحرير تجمع المواهب',
  companyRoleStatusEnum: {
    published: 'منشور',
    closed: 'مغلق',
    draft: 'مسودة'
  },
  companyJobPublishType: {
    internal: 'داخلي',
    external: 'خارجي',
    talentPool: 'تجمع المواهب'
  },
  collectApplication: {
    title: 'جمع الطلب',
    tooltip: 'جمع المعلومات الأساسية من المرشح',
    linkedInProfileLink: 'رابط ملف LinkedIn الشخصي',
    portfolio: 'المحفظة',
    cv: 'السيرة الذاتية للمرشح',
    cvMandatoryText: 'السيرة الذاتية للمرشح إلزامية لا يمكنك إيقاف تشغيلها'
  },
  addCandidateTasks: {
    title: 'إضافة مهام للمرشح',
    tooltipText: 'إضافة مهام لفحص المرشحين أو جمع المعلومات منهم'
  },
  jobsViewDescription: 'عرض وصف الوظيفة',
  reportIcon: 'أيقونة التقرير',
  viewJobsReport: 'عرض تقارير الوظائف',
  editJob: 'تعديل الوظيفة',
  toolTip: {
    viewCandidates: 'عرض المرشحين',
    toolTipSeq1: 'يبدأ جميع المرشحين المشاركين من قبل الموظفين أو تقديمهم من وكالات من هذه المرحلة',
    toolTipSeq2: 'يبدأ جميع المرشحين القادمين من لوحات الوظائف أو صفحات الوظائف من هذه المرحلة'
  },
  options: {
    closeJob: 'إغلاق الوظيفة | إغلاق بركة المواهب',
    publishJob: 'نشر الوظيفة | نشر بركة المواهب',
    cloneJob: 'استنساخ الوظيفة',
    deleteJob: 'حذف الوظيفة',
    viewJob: 'عرض الوظيفة'
  },
  deleteDialog: {
    title: 'هل أنت متأكد أنك تريد حذف الوظيفة'
  },
  closeDialog: {
    title: 'هل أنت متأكد أنك تريد إغلاق هذه الوظيفة | هل أنت متأكد أنك تريد إغلاق بركة المواهب',
    body: 'يتم إزالة الوظائف المغلقة من صفحة الوظائف. يمكنك إعادة فتح هذه الوظيفة في المستقبل إذا كنت ترغب في استخدامها مرة أخرى. | سيتم إزالة بركة المواهب من صفحة الوظائف وبوابة الموظفين. يمكنك إعادة فتحها، إذا كنت ترغب في استخدامها مرة أخرى.'
  },
  republishDialog: {
    title: 'هل أنت متأكد أنك تريد نشر هذه الوظيفة مرة أخرى | هل أنت متأكد أنك تريد نشر بركة المواهب مرة أخرى'
  },
  jobJourneyDialog: {
    edit: 'تعديل رحلة الوظيفة',
    cloneAsDraft: 'استنساخ كمسودة',
    addANew: 'إضافة رحلة وظيفية جديدة',
    syncJobs: 'مزامنة الوظائف'
  },
  cloneDialog: {
    jobPosting: 'حدد نشر الوظيفة',
    selectFromAts: 'اختر من ATS',
    displayName: 'اسم العرض للوظيفة للمرشحين',
    addRoleTitle: 'إضافة عنوان الدور',
    selectOwner: 'حدد المالك',
    owner: 'المالك',
    department: 'القسم',
    location: 'الموقع',
    cloneAsDraft: 'استنساخ كمسودة'
  },
  loading: 'جار التحميل...',
  errorMessage: {
    successfullyClosed: 'تم إغلاق الوظيفة بنجاح. | تم إغلاق بركة المواهب بنجاح',
    successfullyPublished: 'تم نشر الوظيفة بنجاح. | تم نشر بركة المواهب بنجاح',
    enterJobName: 'يرجى إدخال اسم العرض للوظيفة',
    selectOwnerName: 'يرجى تحديد اسم المالك',
    enterDepartmentName: 'يرجى إدخال اسم القسم',
    enterLocation: 'يرجى إدخال الموقع',
    successfullyDeletedSample: 'تم حذف نموذج البيانات بنجاح',
    successfullyDeletedJobRole: 'تم حذف الدور بنجاح',
    unableToDeleteJobRole: 'لا يمكن حذف هذه المهمة. يرجى نقل المرشحين إلى وظيفة أخرى والمحاولة مرة أخرى.'
  },
  shareTalentPool: 'مشاركة بركة المواهب'
}
