export const AddCandidateArabic = {
  addCandidate: 'أضف المرشحين',
  addCandidateFromCv: 'أضف مرشح من سيرته الذاتية',
  uploadCvAndCreateCandidate: 'قم بتحميل أو سحب السيرة الذاتية وسنقوم بإنشاء مرشح.',
  formPlaceholders: {
    selectJob: 'اختر الوظيفة',
    selectStage: 'اختر مرحلة التوظيف',
    selectOrigin: 'اختر المصدر',
    enterSource: 'اختر المورد',
    enterFirstName: 'ادخل الاسم الأول',
    enterLastName: 'ادخل اسم العائلة',
    enterEmail: 'ادخل الايميل',
    enterPhoneNumber: 'ادخل رقم الهاتف',
    enterLinkedin: 'ادخل رابط الحساب على LinkedIn',
    enterPortfolio: 'ادخل رابط ملف الأعمال',
    tellAboutThem: 'أخبر عنهم',
    comments: 'التعليقات'
  },
  formLabels: {
    job: 'الوظيفة',
    stage: 'مرحلة التوظيف',
    origin: 'المصدر',
    source: 'المورد',
    firstName: 'الاسم الأول',
    lastName: 'اسم العائلة',
    email: 'الايميل',
    phone: 'الهاتف',
    linkedin: 'رابط LinkedIn',
    portfolio: 'ملف الأعمال',
    uploadCV: 'تحميل السيرة الذاتية',
    howDoYouKnowThem: 'كيف تعرفهم؟',
    additionalComments: 'تعليقات إضافية',
    tags: 'العلامات'
  },
  invitationWillBeSent: 'سيتم إرسال دعوة ترحيبية للمرشح لمرحلة التوظيف الجديدة عبر الايميل.',
  uploadResume: 'رفع السيرة الذاتية',
  fileUploadedSuccessfully: 'تم رفع الملف بنجاح',
  replaceDialog: {
    confirmation: 'هل تريد استبدال المدخلات السابقة؟',
    parsingCompleted: 'تم تحليل السيرة الذاتية.'
  },
  formErrors: {
    selectJob: 'رجاءً اختر الوظيفة',
    selectStage: 'رجاءً اختر مرحلة التوظيف',
    selectOrigin: 'رجاءً اختر المصدر',
    enterFirstName: 'رجاءً ادخل الاسم الأول',
    enterLastName: 'رجاءً ادخل اسم العائلة',
    tellAboutThem: 'يرجى التحدث عنهم'
  },
  parsingResume: 'جارِ تحليل السيرة الذاتية...',
  autoFillCompleted: 'أكملنا التعبئة التلقائية للبيانات. رجاءً راجع البيانات التي قمنا بملئها أدناه.',
  cannotViewThisFileType: '🚫 لا يمكننا عرض صيغة ملف .docx.',
  candidateAddedSuccessfully: 'تم إضافة المرشح بنجاح',
  fileParsingInfo: 'عذرًا، لا يمكننا تحليل هذا الملف. يرجى ملء النموذج يدويًا.',
  newCandidate: 'مرشح جديد',
  attachAResume: 'أرفق سيرة ذاتية لهذا المرشح',
  dragAndDropAResume: 'قم بسحب وإفلات السيرة الذاتية هنا لإرفاقها بالملف الشخصي',
  duplicateCandidate: 'هذا المرشح موجود بالفعل في شركتك. تم العثور على {count} طلبات'
}

export const AddCandidateEnglish = {
  addCandidate: 'Add candidate | Add candidates',
  addCandidateFromCv: 'Add candidate from CV',
  uploadCvAndCreateCandidate: 'Upload or drag the CV and we will create a candidate.',
  formPlaceholders: {
    selectJob: 'Select job',
    selectStage: 'Select stage',
    selectOrigin: 'Select origin',
    enterSource: 'Enter source',
    enterFirstName: 'Enter first name',
    enterLastName: 'Enter last name',
    enterEmail: 'Enter email',
    enterPhoneNumber: 'Enter phone number',
    enterLinkedin: 'Enter linkedin url',
    enterPortfolio: 'Enter portfolio url',
    tellAboutThem: 'Please tell about them',
    comments: 'Comments'
  },
  formLabels: {
    job: 'Job',
    stage: 'Stage',
    origin: 'Origin',
    source: 'Source',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    phone: 'Phone',
    linkedin: 'Linkedin',
    portfolio: 'Portfolio',
    uploadCV: 'Upload CV',
    howDoYouKnowThem: 'How do you know them?',
    additionalComments: 'Additional comments',
    tags: 'Tags'
  },
  invitationWillBeSent: 'An invitation email would be sent to the candidate for this stage.',
  uploadResume: 'Upload resume',
  fileUploadedSuccessfully: 'File uploaded successfully',
  replaceDialog: {
    confirmation: 'Do you want to replace previous values?',
    parsingCompleted: 'Resume parsing is completed.'
  },
  formErrors: {
    selectJob: 'Please select job',
    selectStage: 'Please select stage',
    selectOrigin: 'Please select origin',
    enterFirstName: 'Please enter first name',
    enterLastName: 'Please enter last name',
    tellAboutThem: 'Please tell about them'
  },
  parsingResume: 'Parsing resume...',
  autoFillCompleted: 'Autofill completed! Please review the information we have filled in below.',
  cannotViewThisFileType: 'We cannot view .docx file type 🚫.',
  candidateAddedSuccessfully: 'Candidate added successfully',
  fileParsingInfo: 'Sorry, we cannot parse this file. Please fill in the form manually.',
  newCandidate: 'New Candidate',
  attachAResume: 'Attach a resume to this candidate',
  dragAndDropAResume: 'Drag and drop a resume here to attach it to the profile',
  duplicateCandidate: 'This candidate already exists in your company. {count} applications found'
}
