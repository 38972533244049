
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ required: false, type: String }) readonly title!: string;
  @Prop({ default: 'md', type: String }) readonly size!: 'xs' | 'sm' | 'md' | 'lg' | 'mdl' | 'normal'; // Adjust as per your size options
  @Prop({ default: '400', type: [String, Number] }) readonly weight!: string | number;
  @Prop({ default: 'rgba(4, 4, 16, 0.87)', type: String }) readonly color!: string;

  get typographyStyle(): Record<string, string> {
    const sizeMap = {
      xs: { fontSize: '0.75rem', lineHeight: '1rem', letterSpacing: '-0.00525rem' },
      smd: { fontSize: '0.8rem', lineHeight: '1.25rem', letterSpacing: '-0.00525rem' },
      normal: { fontSize: '0.813rem', lineHeight: '1.25rem', letterSpacing: '-0.00525rem' }, // 13 px
      sm: { fontSize: '0.875rem', lineHeight: '1.25rem', letterSpacing: '-0.00525rem' },
      sml: { fontSize: '0.8125rem', lineHeight: '1.25rem', letterSpacing: '-0.00525rem' },
      md: { fontSize: '1rem', lineHeight: '1.5rem', letterSpacing: '-0.009rem' },
      mdl: { fontSize: '1.375rem', lineHeight: '1.75rem', letterSpacing: '-0.02338rem' },
      lg: { fontSize: '1.75rem', lineHeight: '2.25rem', letterSpacing: '-0.03325rem' },
      vlg: { fontSize: '2.2rem', lineHeight: '1.75rem', letterSpacing: '-0.017rem' }
      // Add more sizes as needed
    }

    const sizeStyles = sizeMap[this.size] || sizeMap.md

    return {
      color: this.color,
      fontFamily: 'Inter, sans-serif',
      fontSize: sizeStyles.fontSize,
      fontWeight: this.weight.toString(),
      lineHeight: sizeStyles.lineHeight,
      letterSpacing: sizeStyles.letterSpacing
    }
  }
}
