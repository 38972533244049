
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'

@Component({
  components: { WcTypography }
})
export default class ColoredAlphabetIcon extends Vue {
  @Prop({ required: true }) text!: string
  @Prop({ default: '7' }) width!: string
  @Prop({ default: '7' }) height!: string
  @Prop({ default: '' }) styles!: string
  @Prop({ default: '' }) bgColor!: string

  get coloredLetter() {
    if (!this.text) return null

    const alphabetColors = {
      a: '#C62828', // Deep red
      b: '#AD1457', // Dark pink
      c: '#6A1B9A', // Deep purple
      d: '#4527A0', // Dark indigo
      e: '#283593', // Deep blue
      f: '#1565C0', // Dark blue
      g: '#0277BD', // Dark light blue
      h: '#00838F', // Deep cyan
      i: '#00695C', // Dark teal
      j: '#2E7D32', // Dark green
      k: '#558B2F', // Dark lime
      l: '#9E9D24', // Lime green
      m: '#F9A825', // Mustard yellow
      n: '#FF8F00', // Dark orange
      o: '#EF6C00', // Deep orange
      p: '#D84315', // Dark deep orange
      q: '#4E342E', // Brown
      r: '#424242', // Dark grey
      s: '#37474F', // Blue grey
      t: '#616161', // Medium grey
      u: '#1B5E20', // Deep green
      v: '#004D40', // Deep teal
      w: '#01579B', // Dark blue
      x: '#1A237E', // Indigo
      y: '#311B92', // Deep purple
      z: '#880E4F', // Deep magenta
      0: '#78909C', // Color for '0'
      1: '#6D4C41', // Color for '1'
      2: '#5D4037', // Color for '2'
      3: '#D81B60', // Color for '3'
      4: '#1E88E5', // Color for '4'
      5: '#FFC107', // Color for '5'
      6: '#00ACC1', // Color for '6'
      7: '#8E24AA', // Color for '7'
      8: '#D4E157', // Color for '8'
      9: '#FB8C00', // Color for '9'
      '+': '#C0CA33' // Color for '+'
    }

    const defaultColor = '#263238'

    const words = this.text.split(' ')
    const initials = words.slice(0, 2).map(word => word[0]).join('')
    const firstInitial = initials[0]?.toLowerCase()
    const color = firstInitial && /^[a-z]$/.test(firstInitial) ? alphabetColors[firstInitial] : defaultColor

    return {
      letters: initials,
      color: color
    }
  }
}
