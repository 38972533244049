
import { Component, Vue, Watch } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { LanguageEnum, LanguageModule } from '@/store/modules/language'
import { getLanguage, setLanguage } from '@/utils/cookies'
import WcIconQuestionMark from '@/components/UILibrary/Icons/WcIconQuestionMark.vue'
import ChatBotDialog from '@/components/Chatbot/ChatBotDialog.vue'
import Banner from '@/components/UILibrary/Banner.vue'
import { timeUntil } from '@/utils/date'
import { Notification } from 'element-ui'
import { ElNotificationComponent } from 'element-ui/types/notification'
import { isMobile } from '@/utils/general'
import { setSmartlookUser } from '@/smartlook'
import { isProduction } from '@/utils/constants'
import { isCurrentPageIsPubic } from '@/utils/request'
import Button from '@/components/UILibrary/Buttons/Button.vue'

@Component({
  name: 'App',
  components: { Button, ChatBotDialog, WcIconQuestionMark, Banner },
  methods: {
    isMobile,
    UserModule() {
      return UserModule
    }
  },
  computed: {
    getCurrentLanguage() {
      return LanguageModule.language
    }
  }
})
export default class extends Vue {
  // refresh variables
  refreshing = false
  registration = null as ServiceWorkerRegistration | null
  showUpdateBanner = false
  timer: number | null = null;
  timeRemaining = ''
  activeNotification = null as ElNotificationComponent | null
  isVisible = false

  get isImpersonatedSession() {
    return UserModule.impersonatedMode
  }

  get loggedInUserEmail(): string {
    return UserModule.email
  }

  @Watch('loggedInUserEmail')
  setSmartlookSession(): void {
    const email = this.loggedInUserEmail
    if (email && isProduction) {
      const userId = UserModule.id
      const companyId = UserModule.companyId
      const fullName = UserModule.name
      setSmartlookUser(email, {
        userId,
        companyId,
        fullName
      })
    }
  }

  @Watch('isImpersonatedSession')
  startTimer(value: boolean) {
    if (value) {
      this.timer = window.setInterval(() => {
        const sessionEndsIn = UserModule.sessionEndsIn
        if (sessionEndsIn) {
          this.timeRemaining = `Session ends in: ${timeUntil(sessionEndsIn)}`
        }
        if (sessionEndsIn && new Date(sessionEndsIn) < new Date()) {
          UserModule.LogOut()
          window.location.reload()
        }
      }, 1000)
    }
  }

  showVersionUpdated() {
    // @ts-expect-error Element UI types are not correct
    Notification.closeAll()
    this.activeNotification = this.$notify({
      title: 'New version is available 🎉',
      customClass: 'wc-socket-version-update',
      message: `
      <div>
          <div style="padding: 2px; margin-bottom: 10px; line-height: normal; text-align: left;">
            We've made a few updates to the ATS application. Please click on refresh for the changes to take effect.
          </div>
          <button
            style="border: 1px solid #5138ee;
            background-color: #5138ee;
            color: white;
            cursor: pointer;
            border-radius: 4px;
            text-decoration: none;
            width: 50px;
            height: 27px;"
          >
            Refresh
          </button>
    </div>
      `,
      showClose: true,
      duration: 0,
      onClick: () => {
        this.refreshApp()
      },
      onClose: () => {
        this.showUpdateBanner = true
      },
      dangerouslyUseHTMLString: true
    })
  }

  @Watch('getCurrentLanguage')
  onLanguageChanged(newLanguage: string, oldLanguage: string) {
    if (newLanguage !== oldLanguage) {
      const direction = newLanguage === LanguageEnum.ENGLISH ? 'ltr' : 'rtl'
      document.body.dir = direction
      document.dir = direction
      document.documentElement.lang = newLanguage
      this.$i18n.locale = newLanguage
      setLanguage(newLanguage)
    }
  }

  initializeScript(sessionToken: string) {
    const appId = process.env.VUE_APP_DEV_REV_ID
    // Remove old script elements if they exist
    const oldScripts = document.querySelectorAll('.dev-rev-plug-app')
    oldScripts.forEach(script => script.remove())

    // Create and append new script elements
    const plugScript = document.createElement('script')
    plugScript.setAttribute('src', 'https://plug-platform.devrev.ai/static/plug.js')
    plugScript.setAttribute('type', 'text/javascript')
    plugScript.classList.add('dev-rev-plug-app')
    document.body.appendChild(plugScript)

    plugScript.onload = () => {
      const initScript = document.createElement('script')
      initScript.type = 'text/javascript'
      initScript.classList.add('dev-rev-plug-app')
      initScript.text = `
          window.plugSDK.init({
            app_id: '${appId}',
            session_token: '${sessionToken}',
          });
        `
      document.body.appendChild(initScript)
    }
  }

  mounted(): void {
    const chosenLanguage = getLanguage() as LanguageEnum
    if (chosenLanguage) {
      LanguageModule.setLanguage(chosenLanguage)
    }
    this.listenAppUpdates()
    this.startTimer(this.isImpersonatedSession)
  }

  updateAvailable(event: CustomEvent) {
    if (isCurrentPageIsPubic()) {
      return
    }
    this.registration = event.detail
    this.showVersionUpdated()
  }

  // Called when the user accepts the update
  refreshApp() {
    if (this.activeNotification) {
      this.activeNotification.close()
    }

    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) {
      return
    }
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    this.showUpdateBanner = false
  }

  listenAppUpdates() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener('swUpdated', this.updateAvailable)

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })

    // Check if there's a waiting service worker on load
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration && registration.waiting) {
        if (!this.showUpdateBanner) {
          this.showUpdateBanner = true
        }
        this.registration = registration
      }
    })

    window.addEventListener('beforeunload', () => {
      const pageAccessedByReload = (
        (window.performance.navigation && window.performance.navigation.type === 1) ||
        window.performance
          .getEntriesByType('navigation')
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .map((nav) => nav.type)
          .includes('reload')
      )
      if (pageAccessedByReload) {
        navigator.serviceWorker.getRegistration().then(reg => {
          const swReq = reg as ServiceWorkerRegistration
          if (swReq.waiting) {
            swReq.waiting.postMessage({ type: 'SKIP_WAITING' })
          }
        })
      }
    })
  }

  beforeDestroy() {
    // Clear the timer when the component is destroyed
    if (this.timer) {
      window.clearInterval(this.timer)
    }
  }
}
