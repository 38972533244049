<template>
  <div class="flex items-start w-full bg-headlineBackground max-h-screen flex-column">
    <div v-if="shouldShowRecruiterNav && !isMobile()" class="rec-navbar-container fixed-top bg-headlineBackground w-full">
      <RecruiterNav :user="userDetails" />
    </div>
    <div class="flex items-start w-full bg-headlineBackground max-h-screen">
      <div id = "container-div" class= "w-full bg-headlineBackground transition duration-200">
          <FadeTransition :duration="200" origin="center top" mode="out-in">
            <RouterView></RouterView>
          </FadeTransition>
      </div>
    </div>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions'
import RecruiterNav from '@/components/RecruiterNavbar/RecruiterNav.vue'
import { UserModule } from '@/store/modules/user'
import Permissions from '@/directive/permission'
import { isMobile } from '@/utils/general'

export default {
  components: {
    FadeTransition,
    RecruiterNav
  },
  directives: {
    Permissions
  },
  data() {
    return {
      companyId: '',
      userDetails: {
        username: '',
        email: '',
        avatar: ''
      }
    }
  },
  methods: {
    isMobile,
    async getCompanyId() {
      this.companyId = UserModule.companyId || ''
      this.userDetails = {
        username: UserModule.name,
        email: UserModule.email,
        avatar: UserModule.avatar
      }
    }
  },
  mounted() {
    this.getCompanyId()
  },

  computed: {
    shouldShowRecruiterNav() {
      return ((this.$route.path.indexOf('job-role') === -1) && (!this.$route.path.includes('recruiter/interview-scheduling')))
    }
  }
}
</script>
<style lang="scss">
.rec-navbar-container {
  z-index: 1500;
}
</style>
