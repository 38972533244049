import Resource from '@/api/resource'
import request from '@/utils/request'

class ArchivalReasonsResource extends Resource {
  constructor() {
    super('archival-reasons')
  }

  getArchivalReasonsForCompany() {
    return request({
      url: '/' + this.uri + '/company',
      method: 'get'
    })
  }

  createNewArchivalReason(payload) {
    return request({
      url: '/' + this.uri + '/',
      method: 'post',
      data: payload
    })
  }

  deleteArchivalReason(id: string) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'delete'
    })
  }
}

export { ArchivalReasonsResource as default }
