import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route, RouteConfig } from 'vue-router'
import store from '@/store'
import { UserModule } from '@/store/modules/user'
import { RolesEnum } from '@/views/recruiter/models/roles-model'
import { WILDCARD_ROUTE } from '@/routes/wild-card.routes'
import { ADMIN_ROUTES } from '@/routes/admin-routes'
import { RECRUITER_ROUTES } from '@/routes/recruiter-routes'

export const hasPermission = (route: RouteConfig | Route): boolean => {
  const userNewPermissions = UserModule.permissionV1
  const userPermissions = UserModule.permissions // TODO-permissions resolve after v1
  const userRoles = UserModule.roles
  if (route.meta) {
    let hasPermission = true
    let hasRole = true
    if (route.meta.roles || route.meta.permissions) {
      hasPermission = true
      hasRole = true
      if (route.meta.permissions) {
        hasPermission = false
        hasPermission = route.meta.permissions.some((permission: string) => userPermissions.includes(permission)) // TODO-permissions resolve after v1
        hasPermission = hasPermission || route.meta.permissions.some((permission: string) => userNewPermissions.includes(permission))
      }
      if (route.meta.roles) {
        // TODO-permissions resolve after v1
        hasRole = true
        hasRole = route.meta.roles.some((role: string) => userRoles.includes(role))
      }
    }
    return hasPermission && hasRole
  } else {
    return true
  }
}

export const filterRoutes = (routes: RouteConfig[]): RouteConfig[] => {
  const res: RouteConfig[] = []
  routes.forEach(route => {
    const r = { ...route }
    if (hasPermission(r)) {
      if (r.children) {
        r.children = filterRoutes(r.children)
      }
      res.push(r)
    }
  })
  return res
}

export const checkRole = (role: RolesEnum): boolean => {
  return UserModule.roles.includes(role)
}

export interface IPermissionState {
  routes: RouteConfig[]
}

@Module({ dynamic: true, store, name: 'permission', namespaced: true })
class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = []

  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.routes = [...filterRoutes(routes), ...WILDCARD_ROUTE]
  }

  @Action
  public GenerateRoutes() {
    if (checkRole(RolesEnum.WC_ADMIN)) {
      this.SET_ROUTES(ADMIN_ROUTES)
    } else if (checkRole(RolesEnum.COMPANY_ADMIN) || checkRole(RolesEnum.COMPANY_INTERVIEWER) || checkRole(RolesEnum.COMPANY_EMPLOYEE)) {
      this.SET_ROUTES(RECRUITER_ROUTES)
    }
  }

  @Action
  public GenerateRoutesV2() {
    this.SET_ROUTES(RECRUITER_ROUTES)
  }
}

export const PermissionModule = getModule(Permission)
