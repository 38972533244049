import { CandidateListEnglish } from '@/lang/pages/candidateList'
import { BookingLinkEnglish, InterviewBookingEnglish } from '@/lang/pages/bookingLink'
import { ProfileBuilderEnglish } from '@/lang/pages/profileBuilder'
import { SendOfferEnglish } from '@/lang/pages/sendOffer'
import { ListOffersEnglish } from '@/lang/pages/offerLetters'
import { AddEditJobEnglish } from '@/lang/pages/addEditJob'
import { InterviewEnglish } from '@/lang/pages/interview'
import { QuizEnglish } from '@/lang/pages/quiz'
import { StageModulesEnglish } from '@/lang/pages/stageModules'
import { ExtensionPagesEnglish } from '@/lang/pages/extensionPages'
import { CalendarIntegrationPageEnglish } from '@/lang/pages/calendarIntegrationPage'
import { SignupEnglish } from '@/lang/pages/signup'
import { LoginEnglish } from '@/lang/pages/login'
import { OtpEnglish } from '@/lang/pages/otp'
import { userAssignmentEnglish } from '@/lang/pages/userAssignment'
import { ScreeningQuizEnglish } from '@/lang/pages/screeningQuiz'
import { JobsEnglish } from '@/lang/pages/jobs'
import { DummyDataEnglish } from '@/lang/pages/dummyData'
import { AgenciesEnglish } from '@/lang/pages/agencies'
import { CandidateTimelineEnglish } from '@/lang/pages/candidateTimeline'
import { TeamMembersEnglish } from '@/lang/pages/teamMembers'
import { CompanyBrandEnglish } from '@/lang/pages/companyBrand'
import { JobDescriptionEnglish } from '@/lang/pages/jobDescription'
import { CareerEditEnglish } from '@/lang/pages/careerEdit'
import { CareerEnglish } from '@/lang/pages/career'
import { RecruiterSettingsEnglish } from '@/lang/pages/recruiterSettings'
import { InterviewPageEnglish } from '@/lang/pages/interviewPage'
import { RecruiterNavigationEnglish } from '@/lang/pages/recruiterNavigation'
import { ReferralsEnglish } from '@/lang/pages/referrals'
import { RoleTypesEnumEnglish } from '@/lang/pages/roleTypesEnum'
import { WorkPlaceTypesEnumEnglish } from '@/lang/pages/workPlaceTypesEnum'
import { MobileNavbarMenuEnglish } from '@/lang/pages/mobileNavbarMenu'
import { EmailComponentEnglish } from '@/lang/pages/emailComponent'
import { AddCandidateEnglish } from '@/lang/pages/addCandidate'
import { InputValidationsEnglish } from '@/lang/pages/inputValidations'
import { FileUploadErrorsEnglish } from '@/lang/pages/fileUploadErrors'
import { GeneralEnglish } from '@/lang/pages/general'
import { TestEnglish } from '@/lang/pages/test'
import { StagePipelineEnglish } from '@/lang/pages/stagePipeline'
import { InterviewSchedulingEnglish } from '@/lang/pages/interviewScheduling'
import { JobReportsEnglish } from '@/lang/pages/jobReports'
import { ManageEmailsEnglish } from '@/lang/pages/manageEmails'
import { ProfileDropdownEnglish } from '@/lang/pages/profileDropdown'
import { VideoConferencingIntegrationEnglish } from '@/lang/pages/videoConferencingIntegration'
import { HrmsIntegrationEnglish } from '@/lang/pages/hrmsIntegration'
import { AppIntegrationsEnglish } from '@/lang/pages/appIntegrations'
import { FormPlaceholdersEnglish } from '@/lang/pages/formPlaceholders'
import { ProfilePageEnglish } from '@/lang/pages/profilePage'
import { ManageScoreCardEnglish } from '@/lang/pages/manageScoreCardTemplates'
import { SetReminderEnglish } from '@/lang/pages/setReminder'
import { HomePageEnglish } from '@/lang/pages/homePage'
import { TalentPoolEnglish } from '@/lang/pages/talentPool'
import { NotificationPreferencesEnglish } from '@/lang/pages/notificationPreferences'
import { LocationsAndDepartmentsEnglish } from '@/lang/pages/locationsAndDepartments'
import { JobBoardIntegrationsEnglish } from '@/lang/pages/jobBoardIntegrations'

export default {
  homePage: HomePageEnglish,
  addEditJob: AddEditJobEnglish,
  candidateList: CandidateListEnglish,
  userAssignment: userAssignmentEnglish,
  sendOffer: SendOfferEnglish,
  listOffers: ListOffersEnglish,
  agencies: AgenciesEnglish,
  stageModules: StageModulesEnglish,
  screeningQuiz: ScreeningQuizEnglish,
  extensionPage: ExtensionPagesEnglish,
  teamMembers: TeamMembersEnglish,
  companyBrand: CompanyBrandEnglish,
  jobDescription: JobDescriptionEnglish,
  careerEdit: CareerEditEnglish,
  career: CareerEnglish,
  recruiterSettings: RecruiterSettingsEnglish,
  interviewBooking: InterviewBookingEnglish,
  bookingLink: BookingLinkEnglish,
  interviewPage: InterviewPageEnglish,
  recruiterNavigation: RecruiterNavigationEnglish,
  referrals: ReferralsEnglish,
  roleTypesEnum: RoleTypesEnumEnglish,
  workPlaceTypesEnum: WorkPlaceTypesEnumEnglish,
  mobileNavbarMenu: MobileNavbarMenuEnglish,
  candidateTimeline: CandidateTimelineEnglish,
  emailComponent: EmailComponentEnglish,
  addCandidate: AddCandidateEnglish,
  inputValidations: InputValidationsEnglish,
  fileUploadErrors: FileUploadErrorsEnglish,
  general: GeneralEnglish,
  test: TestEnglish,
  profileBuilder: ProfileBuilderEnglish,
  stagePipeline: StagePipelineEnglish,
  interviewScheduling: InterviewSchedulingEnglish,
  interviewScorecardTemplate: ManageScoreCardEnglish,
  jobReports: JobReportsEnglish,
  manageEmails: ManageEmailsEnglish,
  quiz: QuizEnglish,
  profileDropdown: ProfileDropdownEnglish,
  videoConferencingIntegration: VideoConferencingIntegrationEnglish,
  hrmsIntegration: HrmsIntegrationEnglish,
  interview: InterviewEnglish,
  calendarIntegration: CalendarIntegrationPageEnglish,
  appIntegrations: AppIntegrationsEnglish,
  formPlaceholders: FormPlaceholdersEnglish,
  signup: SignupEnglish,
  login: LoginEnglish,
  profilePage: ProfilePageEnglish,
  otp: OtpEnglish,
  jobs: JobsEnglish,
  dummyData: DummyDataEnglish,
  setReminder: SetReminderEnglish,
  talentPool: TalentPoolEnglish,
  notificationPreferences: NotificationPreferencesEnglish,
  locationsAndDepartments: LocationsAndDepartmentsEnglish,
  jobBoardIntegrations: JobBoardIntegrationsEnglish
}
