export const InputValidationsArabic = {
  enterValidEmailAddress: 'رجاءً ادخل ايميل صحيح',
  enterValidPhoneNumber: 'رجاءً ادخل رقم هاتف صحيح',
  enterValidLinkedinUrl: 'رجاءً ادخل رابط LinkedIn صحيح',
  enterValidLink: 'رجاءً ادخل رابط صحيح',
  enterFirstName: 'رجاءً ادخل الاسم الأول',
  enterLastName: 'رجاءً ادخل اسم العائلة'
}

export const InputValidationsEnglish = {
  enterValidEmailAddress: 'Please enter a valid email address',
  enterValidPhoneNumber: 'Please enter a valid phone number',
  enterValidLinkedinUrl: 'Please enter a valid linkedin url',
  enterValidLink: 'Please enter a valid link',
  enterFirstName: 'Please enter first name',
  enterLastName: 'Please enter last name'
}
