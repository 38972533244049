export const FileUploadErrorsArabic = {
  resumeUploadSizeError: 'لا يمكن أن يتجاوز حجم السيرة الذاتية {size}',
  resumeType: 'يجب أن تكون صيغة السيرة الذاتية {type1} أو {type2}',
  unableToUploadResume: 'تعثر رفع السيرة الذاتية. رجاءً حاول لاحقًا.'
}

export const FileUploadErrorsEnglish = {
  resumeUploadSizeError: 'Resume size can not exceed {size}!',
  resumeType: 'Resume must be a {type1} or a {type2}!',
  unableToUploadResume: 'Unable to upload resume. Please try again later.'
}
