export const TestArabic = {
  hello0: '{msg} العالم',
  hello1: '{0} العالم',
  hello2: 'مرحبا <br /> العالم',
  car: 'سيارة | سيارات',
  apple: 'لا توجد تفاحات | تفاحة واحدة | {count} تفاحات',
  the_world: 'العالم',
  dio: 'ديو:',
  linked: '@:test.dio @:test.the_world !!!!',
  homeAddress: 'عنوان المنزل',
  missingHomeAddress: 'يرجى تقديم @.lower:test.homeAddress'
}

export const TestEnglish = {
  hello0: '{msg} world',
  hello1: '{0} world',
  hello2: 'hello <br /> world',
  car: 'car | cars',
  apple: 'no apples | one apple | {count} apples',
  the_world: 'the world',
  dio: 'DIO:',
  linked: '@:test.dio @:test.the_world !!!!',
  homeAddress: 'Home Address',
  missingHomeAddress: 'Please provide @.lower:test.homeAddress'
}
