import io from 'socket.io-client'

export function GetSocket() {
  const domain = process.env.VUE_APP_BASE_API || 'http://localhost:3005'
  try {
    return io(domain.replace('/api', ''), { transports: ['websocket'] })
  } catch (err) {
    //
  }
}
