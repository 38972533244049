export const StagePipelineArabic = {
  heading: 'قالب خط الأنابيب للمرحلة الافتراضية وأسباب الأرشفة',
  tooltipText: 'انقر هنا لمعرفة المزيد عن الخط الأساسي الافتراضي',
  pipelineTitle: 'قالب خط الأنابيب للمرحلة الافتراضية',
  pipelineDescription: 'تحتوي هذه الخطوة على مراحل التوظيف المختلفة. تستطيع تحرير مراحل التوظيف وإعادة ترتيبها وإعادة تسميتها في هذه الخطوة.',
  anyChangesWillEffectNewJobs: 'أي تغييرات يتم إجراؤها هنا لن تؤثر على مراحل الوظائف الحالية؛ ستنطبق فقط على الوظائف الجديدة.',
  addStageButton: 'إضافة مرحلة جديدة',
  deleteContent: 'هذه المرحلة مقفلة ولا يمكن حذفها.',
  addNewStage: 'إضافة مرحلة جديدة',
  editStage: 'تحرير المرحلة',
  deleteStageDescription: 'يحذف هذا الإجراء المرحلة المحددة نهائيًا من مراحل عمليات التوظيف',
  deleteStage: 'هل تريد حقًا حذف هذه المرحلة؟',
  deleteConfirmation: 'لن تستطيع التراجع عن هذا الإجراء. هل تريد المتابعة؟',
  stageName: 'اسم المرحلة',
  inputStageName: 'رجاءً ادخل اسم المرحلة',
  archivalReasons: {
    title: 'أسباب الأرشفة',
    new: 'إضافة سبب جديد',
    description: 'أضف أسباب الأرشفة التي ستظهر أثناء أرشفة المرشح.',
    placeholder: 'رجاءًً ادخل سبب الأرشفة',
    infoDefault: 'لا يمكنك تعديل أو حذف الأسباب الافتراضية للأرشفة',
    infoCandidatesArchived: 'تم أرشفة مرشحين بهذا السبب',
    addaNewReason: 'أضف سبب أرشفة جديد',
    limit: 'الحد الأقصى لعدد الأحرف لسبب الأرشفة {limit}',
    edit: 'حرر سبب الأرشفة',
    deleteConfirmation: 'هل أنت متأكد من حذف سبب الأرشفة؟',
    inputReason: 'رجاءًً ادخل سبب الأرشفة'
  },
  successMessages: {
    changesSaved: 'تم حفظ التغييرات',
    deletedReason: 'تم حذف سبب الأرشفة بنجاح',
    addedReason: 'تم إضافة سبب الأرشفة بنجاح!',
    updatedReason: 'تم تحديث سبب الأرشفة بنجاح!'
  }
}

export const StagePipelineEnglish = {
  heading: 'Default stage pipeline template & archival reasons',
  tooltipText: 'Click here to learn more about default pipeline',
  pipelineTitle: 'Default stage pipeline template',
  pipelineDescription: 'This pipeline contains recruiting stages. You can rearrange, edit, & rename the stages here.',
  anyChangesWillEffectNewJobs: 'Any changes made here will not impact the stages of existing jobs; they will only apply to new jobs.',
  addStageButton: 'Add new stage',
  deleteContent: 'This is a locked stage, cannot be deleted.',
  addNewStage: 'Add a new stage',
  deleteStageDescription: 'This action will delete the stage permanently from the recruiting pipeline-',
  deleteStage: 'Delete this stage?',
  deleteConfirmation: 'This action cannot be undone. Are you sure?',
  editStage: 'Edit Stage',
  stageName: 'Stage Name',
  inputStageName: 'Please input Stage Name',
  archivalReasons: {
    title: 'Archival reasons',
    new: 'Add new reason',
    description: 'Add archival reasons that should be shown while archiving a candidate.',
    placeholder: 'Enter reason',
    infoDefault: 'You cannot edit or delete default archival reasons',
    infoCandidatesArchived: 'There are candidates archived with this reason',
    addaNewReason: 'Add new archival reason',
    limit: 'Maximum {limit} characters',
    edit: 'Edit archival reason',
    deleteConfirmation: 'Are you sure to delete this?',
    inputReason: 'Please input Archival Reason'
  },
  successMessages: {
    changesSaved: 'Changes saved',
    deletedReason: 'Successfully deleted reason',
    addedReason: 'Archival reason successfully added!',
    updatedReason: 'Archival reason successfully updated!'
  }
}
