export const TeamMembersEnglish = {
  title: 'Team members',
  subTitle: 'Invite your team member and manage their access type.',
  tooltipText: 'Click here to learn more about inviting team members',
  noTeamMembersFound: 'No Team members found',
  editThisJob: 'Edit this job',
  sendAndViewOfferLetters: 'Send & view offer letters',
  viewSecretNotesMails: 'View secret notes & emails',
  confidentiality: 'Confidential access can not be removed if the collaborator is allowed to edit the job.',
  accessCandidateProfile: 'Access candidate profile',
  owner: 'Owner',
  invitedUsersNote: {
    note: 'Users who signed in or invited with the <b>{domain}</b> domain email address will show in this list.',
    prefix: 'Users who signed in or invited with the',
    suffix: 'domain email address will show in this list.'
  },
  dialog: {
    email: 'Email',
    inputEmail: 'Enter email',
    firstName: 'First name',
    lastName: 'Last name',
    accessType: 'Access Type',
    selectAccessType: 'Select access type',
    fullAccessDesc: {
      description: 'Complete access to add / remove members, edit all jobs, view secret notes, candidate list etc.',
      usageDesc: 'Typically used for Talent team members.'
    },
    limitedAccessDesc: {
      description: 'Access to view jobs and candidates only when added as a collaborator to that Job.',
      usageDesc: 'Typically used for Hiring Managers and Interviewers.'
    },
    employeeDesc: {
      description: 'No access to view jobs and candidates. Only accessible to referral portal.',
      usageDesc: 'Typically used for Employees.'
    },
    assignedJobs: 'Assigned Jobs',
    assignedJobNote: 'Choose the job listing(s) that this member is allowed to access and send candidates for.',
    assignMore: 'Assign more job',
    inviteUser: 'Invite User',
    sendInivitation: 'Send Invitation Again',
    sendInvite: 'Send Invite',
    deleteNote: 'Are you sure you want to delete this user?',
    inviteNote: 'Are you sure you want to resend invitation mail?'
  },
  buttons: {
    inviteNewUser: 'Invite a new user'
  },
  search: {
    nameOrEmail: 'Search by name or email',
    accessTypeText: 'Access type | Access type({length}) | Access types({length})',
    accessTypes: {
      COMPANY_INTERVIEWER: 'Limited access',
      COMPANY_ADMIN: 'Full access',
      COMPANY_EMPLOYEE: 'Employee'
    }
  },
  table: {
    header: {
      fullName: 'NAME',
      roleType: 'ACCESS TYPE',
      jobsAssigned: 'JOBS ASSIGNED',
      lastActive: 'LAST ACTIVE',
      status: 'STATUS',
      actions: 'ACTIONS'
    }
  },
  errors: {
    enterEmail: 'Please enter email',
    invalidEmail: 'Please enter a valid email',
    enterFirstName: 'Please enter first name',
    enterLastName: 'Please enter last name',
    invalidCompanyId: 'Invalid company id',
    selectAccessType: 'Please select an access type',
    userExist: 'User email already exists'
  },
  successMessages: {
    invitedUser: 'Invited User Successfully!',
    updatedUser: 'User updated Successfully!',
    deletedUser: 'Deleted User Successfully!',
    inviteSent: 'Invite sent successfully!'
  }
}

export const TeamMembersArabic = {
  title: 'أعضاء فريقك',
  subTitle: 'ادعو أعضاء فريقك وأدر نوع وصولهم.',
  tooltipText: 'انقر هنا لـ تعرف على المزيد حول دعوة أعضاء الفريق',
  noTeamMembersFound: 'لم يتم العثور على أعضاء في الفريق',
  invitedUsersNote: {
    prefix: 'يظهر في هذه القائمة المستخدمين الذين سجلوا دخولهم أو تمت دعوتهم باستخدام',
    suffix: 'الايميل.'
  },
  dialog: {
    email: 'البريد الإلكتروني',
    inputEmail: 'ادخل البريد الإلكتروني',
    firstName: 'الاسم الأول',
    lastName: 'الاسم الأخير',
    accessType: 'نوع الإذن',
    selectAccessType: 'اختر نوع الإذن',
    fullAccessDesc: {
      description: 'إذن كامل بإضافة / إزالة الأعضاء، والتعديل على الوظائف، وعرض الملاحظات الخفية وقائمة المرشحين، إلخ.',
      usageDesc: 'عادةً ما يُستخدم لأعضاء فريق جذب المواهب.'
    },
    limitedAccessDesc: {
      description: 'إذن بعرض الوظائف والمرشحين فقط عند إضافته كموظف متعاون.',
      usageDesc: 'عادةً ما يُستخدم لمديري التوظيف والقائمين على إجراء مقابلات التوظيف.'
    },
    employeeDesc: {
      description: 'إذن بالوصول إلى بوابة الترشيح والتزكية فقط، ولا يوجد إذن لعرض الوظائف والمرشحين.',
      usageDesc: 'عادةً ما يُستخدم للموظفين.'
    },
    assignedJobs: 'الوظائف المعينة',
    assignedJobNote: 'اختر قائمة الوظائف التي يُسمح لهذا العضو بالوصول إليها وإرسال المرشحين إليه.',
    assignMore: 'تعيين وظيفة أخرى',
    inviteUser: 'دعوة مستخدم',
    sendInivitation: 'إعادة إرسال الدعوة',
    sendInvite: 'إرسال دعوة',
    inviteNote: 'هل أنت متأكد من إعادة إرسال الدعوة؟',
    deleteNote: 'هل أنت متأكد من حذف هذا المستخدم؟'
  },
  buttons: {
    inviteNewUser: 'دعوة مستخدم جديد'
  },
  search: {
    nameOrEmail: 'البحث بالاسم أو الايميل',
    accessTypeText: 'أنواع الوصول({length}) | أنواع الوصول({length}) | نوع الوصول',
    accessTypes: {
      COMPANY_INTERVIEWER: 'إذن محدود',
      COMPANY_ADMIN: 'إذن كامل',
      COMPANY_EMPLOYEE: 'موظف'
    }
  },
  table: {
    header: {
      fullName: 'الاسم',
      roleType: 'نوع الإذن',
      jobsAssigned: 'الوظائف المعينة للموظف',
      lastActive: 'آخر نشاط للموظف',
      status: 'حالة الموظف',
      actions: 'إجراءات'
    }
  },
  errors: {
    enterEmail: 'الرجاء إدخال الايميل',
    invalidEmail: 'الرجاء إدخال ايميل صحيح',
    enterFirstName: 'الرجاء إدخال الاسم الأول',
    enterLastName: 'الرجاء إدخال الاسم الأخير',
    invalidCompanyId: 'معرف الشركة غير صحيح',
    selectAccessType: 'الرجاء تحديد نوع الإذن',
    userExist: 'ايميل هذا المستخدم موجود بالفعل'
  },
  successMessages: {
    invitedUser: 'تمت دعوة المستخدم بنجاح!',
    updatedUser: 'تم تحديث حالة المستخدم بنجاح!',
    deletedUser: 'تم حذف المستخدم بنجاح.',
    inviteSent: 'تم إرسال الدعوة بنجاح!'
  }
}
