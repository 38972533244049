export const TalentPoolEnglish = {
  joinTalentPool: 'Join our talent pool',
  notFindAnything: 'Did not find anything that suits you. Join our pool.',
  addToTalentPool: 'Add to our talent pool',
  careerPage: 'Career Page',
  employeePortal: 'Employee Portal',
  addTalentPoolQuestion: 'Add general talent pool questions',
  talentPoolDesc: 'Collection information from candidates interested in joining talent pool'
}
export const TalentPoolArabic = {
  joinTalentPool: 'انضم إلى تجمع المواهب لدينا',
  notFindAnything: 'لم تجد شيئًا يناسبك. انضم إلى بركة المواهب لدينا.',
  addToTalentPool: 'أضف إلى بركة المواهب لدينا',
  careerPage: 'الصفحة المهنية',
  employeePortal: 'بوابة الموظف',
  addTalentPoolQuestion: 'إضافة أسئلة عامة لبركة المواهب',
  talentPoolDesc: 'جمع المعلومات من المرشحين الذين يرغبون في الانضمام إلى بركة المواهب'
}
