export const SignupEnglish = {
  notRegistered: 'This company domain <b>{domain}</b> is not registered with us.<br/>Are you sure you are using the right email address?',
  alreadyAMember: 'Already a member?',
  login: 'Log in',
  signupTo: 'Join <b>{companyName}</b>',
  errorMessage: {
    firstName: 'Please enter first name',
    lastName: 'Please enter last name',
    email: 'Please enter email address',
    validEmail: 'Enter a valid email'
  }
}
export const SignupArabic = {
  notRegistered: 'هذا النطاق الخاص بالشركة <b>{domain}</b> غير مسجل لدينا.<br/>هل أنت متأكد من استخدام البريد الإلكتروني الصحيح؟',
  alreadyAMember: 'هل أنت بالفعل عضو؟',
  login: 'تسجيل الدخول',
  signupTo: 'انضم إلى <b>{companyName}</b>',
  errorMessage: {
    firstName: 'يرجى إدخال الاسم الأول',
    lastName: 'يرجى إدخال الاسم الأخير',
    email: 'يرجى إدخال عنوان البريد الإلكتروني',
    validEmail: 'أدخل بريدًا إلكترونيًا صالحًا'
  }
}
