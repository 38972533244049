import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { Socket as SocketIOClient } from 'socket.io-client'

export interface ISocket {
  socket: SocketIOClient | undefined
}

@Module({ dynamic: true, store, name: 'socket', namespaced: true })
class Socket extends VuexModule implements ISocket {
  public socket: SocketIOClient | undefined;

  @Mutation
  public SET_SOCKET(socket: SocketIOClient): void {
    this.socket = socket
  }

  @Action
  public setSocket(socket: SocketIOClient): void {
    this.SET_SOCKET(socket)
  }

  get currentSocket(): SocketIOClient | undefined {
    return this.socket
  }
}

export const SocketModule = getModule(Socket)
