import { PermissionsEnum } from '@/permissions/permissions-enum'

export const CAREER_PAGE_PERMISSIONS = [
  PermissionsEnum.COMPANY_CAREER_PAGE_LIST,
  PermissionsEnum.COMPANY_CAREER_PAGE_EDIT
]

export const COMPANY_BRAND_PERMISSIONS = [
  PermissionsEnum.COMPANY_BRANDING_EDIT,
  PermissionsEnum.COMPANY_BRANDING_LIST
]

export const CAREER_BRAND_PAGE_PERMISSIONS = [
  ...CAREER_PAGE_PERMISSIONS,
  ...COMPANY_BRAND_PERMISSIONS
]

export const COMPANY_BOOKING_LINKS_MANAGE = [
  PermissionsEnum.COMPANY_BOOKING_LINK_ADD,
  PermissionsEnum.COMPANY_BOOKING_LINK_EDIT,
  PermissionsEnum.COMPANY_BOOKING_LINK_DELETE
]

export const COMPANY_EMAIL_TEMPLATES_MANAGE = [
  PermissionsEnum.COMPANY_EMAIL_TEMPLATES_ADD,
  PermissionsEnum.COMPANY_EMAIL_TEMPLATES_EDIT,
  PermissionsEnum.COMPANY_EMAIL_TEMPLATES_DELETE
]

export const COMPANY_HRMS_INTEGRATIONS_MANAGE = [
  PermissionsEnum.COMPANY_HRMS_INTEGRATIONS_ADD,
  PermissionsEnum.COMPANY_HRMS_INTEGRATIONS_EDIT,
  PermissionsEnum.COMPANY_HRMS_INTEGRATIONS_DELETE
]

export const COMPANY_CALENDAR_EMAIL_INTEGRATIONS_MANAGE = [
  PermissionsEnum.COMPANY_INTEGRATE_CALENDAR_EMAIL_ADD,
  PermissionsEnum.COMPANY_INTEGRATE_CALENDAR_EMAIL_DELETE,
  PermissionsEnum.COMPANY_INTEGRATE_CALENDAR_EMAIL_LIST
]

export const COMPANY_LOCATION_MANAGE_PERMISSIONS = [
  PermissionsEnum.COMPANY_LOCATION_ADD,
  PermissionsEnum.COMPANY_LOCATION_EDIT,
  PermissionsEnum.COMPANY_LOCATION_DELETE
]

export const COMPANY_DEPARTMENTS_MANAGE_PERMISSIONS = [
  PermissionsEnum.COMPANY_DEPARTMENT_ADD,
  PermissionsEnum.COMPANY_DEPARTMENT_EDIT,
  PermissionsEnum.COMPANY_DEPARTMENT_DELETE
]

export const COMPANY_STAGE_PIPELINE_MANAGE_PERMISSIONS = [
  PermissionsEnum.COMPANY_DEFAULT_STAGE_PIPELINE_ADD,
  PermissionsEnum.COMPANY_DEFAULT_STAGE_PIPELINE_EDIT,
  PermissionsEnum.COMPANY_DEFAULT_STAGE_PIPELINE_DELETE
]

export const COMPANY_ARCHIVAL_REASONS_MANAGE_PERMISSIONS = [
  PermissionsEnum.COMPANY_DEFAULT_ARCHIVAL_REASON_ADD,
  PermissionsEnum.COMPANY_DEFAULT_ARCHIVAL_REASON_EDIT,
  PermissionsEnum.COMPANY_DEFAULT_ARCHIVAL_REASON_DELETE
]

export const STAGE_PIPELINE_ARCHIVAL_REASON_PERMISSIONS = [
  ...COMPANY_STAGE_PIPELINE_MANAGE_PERMISSIONS,
  ...COMPANY_ARCHIVAL_REASONS_MANAGE_PERMISSIONS
]
export const COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_MANAGE = [
  PermissionsEnum.COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_ADD,
  PermissionsEnum.COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_EDIT,
  PermissionsEnum.COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_DELETE
]

export const COMPANY_OFFER_LETTER_TEMPLATE_MANAGE = [
  PermissionsEnum.COMPANY_OFFER_LETTERS_TEMPLATES_ADD,
  PermissionsEnum.COMPANY_OFFER_LETTERS_TEMPLATES_EDIT,
  PermissionsEnum.COMPANY_OFFER_LETTERS_TEMPLATES_DELETE
]

export const COMPANY_AGENCY_MANAGE_PERMISSIONS = [
  PermissionsEnum.COMPANY_AGENCY_ADD,
  PermissionsEnum.COMPANY_AGENCY_EDIT,
  PermissionsEnum.COMPANY_AGENCY_DELETE
]

export const COMPANY_TEAM_MEMBERS_MANAGE = [
  PermissionsEnum.COMPANY_TEAM_MEMBERS_ADD,
  PermissionsEnum.COMPANY_TEAM_MEMBERS_EDIT,
  PermissionsEnum.COMPANY_TEAM_MEMBERS_DELETE,
  PermissionsEnum.COMPANY_TEAM_MEMBERS_RESENT_PARTNER_INVITE
]

export const COMPANY_LOCATIONS_DEPARTMENTS_MANAGE = [
  ...COMPANY_LOCATION_MANAGE_PERMISSIONS,
  ...COMPANY_DEPARTMENTS_MANAGE_PERMISSIONS
]

export const USER_NOTIFICATIONS_MANAGE = [
  PermissionsEnum.USER_NOTIFICATION_PREFERENCES_EDIT,
  PermissionsEnum.USER_NOTIFICATION_PREFERENCES_LIST
]
