export const LoginEnglish = {
  notRegistered: 'This company domain <b>{domain}</b> is not registered with us.<br/>Are you sure you are using the right email address?',
  registeredComeFirst: '<b>{companyName}</b> is a registered client and is using {text}.<br>You seem to have come here for the first time.',
  usingManagementFirst: 'Using Hiring Management for the first time?',
  loginTo: 'Log in to <b>{companyName}</b>',
  errorMessage: {
    email: 'Please enter email address',
    validEmail: 'Enter a valid email'
  }
}
export const LoginArabic = {
  notRegistered: 'هذا النطاق الخاص بالشركة <b>{domain}</b> غير مسجل لدينا.<br/>هل أنت متأكد من استخدام البريد الإلكتروني الصحيح؟',
  registeredComeFirst: '<b>{companyName}</b> هي عميل مسجل وتستخدم {text}.<br>يبدو أنك قد جئت هنا لأول مرة.',
  usingManagementFirst: 'هل تستخدم إدارة التوظيف لأول مرة؟',
  loginTo: 'تسجيل الدخول إلى <b>{companyName}</b>',
  errorMessage: {
    email: 'يرجى إدخال عنوان البريد الإلكتروني',
    validEmail: 'أدخل بريدًا إلكترونيًا صالحًا'
  }
}
