export const HrmsIntegrationArabic = {
  integrations: 'التكاملات',
  integrationsDescription: 'قم بتكامل لوحات الوظائف ونظام HRMS الخاص بك للحصول على المرشحين وتوظيف الموظفين الجدد بسلاسة.',
  title: 'HRMS',
  tooltipText: 'انقر هنا لمعرفة المزيد عن تكامل hrms',
  description: 'قم بإعداد تكاملات نظام إدارة الموارد البشرية لإضافة المرشحين الذين تم توظيفهم إلى نظام إدارة الموارد البشرية في منشأتك.',
  integrationInfo: {
    title: 'كيفية ربط نظام إدارة الموارد البشرية الخاص بك:',
    step1: 'انسخ مفتاح واجهة برمجة التطبيق (API) من نظام إدارة الموارد البشرية الخاص بك.',
    step2: 'الصق مفتاح واجهة برمجة التطبيق (API)',
    step3: 'بمجرد إتمام الربط يمكنك إضافة مرشح تم توظيفه مباشرةً إلى نظام إدارة الموارد البشرية من خلال نظام إدارة التوظيف في منصة بيزات.Bayzat'
  },
  integrationTable: {
    selectYourHrms: 'اختر نظام إدارة الموارد البشرية الخاص بك',
    apiKey: 'مفتاح واجهة برمجة التطبيق (API)',
    integrationName: 'اسم التكامل',
    form: {
      selectYourHRMS: 'رجاءً اختر نظام إدارة الموارد البشرية الخاص بك',
      inputApiKey: 'رجاءً ادخل مفتاح واجهة برمجة التطبيق (API) الخاص بك',
      inputIntegrationName: 'رجاءً ادخل اسم التكامل',
      enterYourApiKey: 'ادخل مفتاح واجهة برمجة التطبيق (API) الخاص بك',
      enterIntegrationName: 'ادخل اسم التكامل',
      subdomain: 'أدخل المجال الفرعي لنظام إدارة الموارد البشرية'
    },
    addAnotherIntegration: 'أضف تكاملًا آخر',
    integrate: 'ربط'
  },
  jobBoards: 'لوحات الوظائف',
  jobBoardsDescription: 'قم بإعداد لوحات الوظائف الخاصة بك لنشر الوظائف على لوحات الوظائف المختلفة عبر نظام تتبع المتقدمين (ATS).',
  linkedin: {
    title: 'LinkedIn',
    companyId: 'معرف شركة LinkedIn',
    enterCompanyId: 'أدخل معرف الشركة الخاص بك',
    step1: 'سيتم نشر جميع الوظائف المنشورة خارجياً من نظام تتبع المتقدمين (ATS) على LinkedIn.',
    step2: 'سيتم مزامنة الوظائف المنشورة حديثاً والتحديثات على الوظائف الحالية في غضون 24 ساعة.',
    step3: 'تعرف على المزيد حول تكامل LinkedIn.',
    termsNote: 'ملاحظة: بنشر وظيفتك على LinkedIn، فإنك توافق على',
    termsAndConditions: 'شروط وأحكام الوظائف في LinkedIn',
    pleaseEnterLinkedInCompanyId: 'يرجى إدخال معرف الشركة الخاص بك في LinkedIn',
    successfullySaved: 'تم الحفظ بنجاح'
  }
}

export const HrmsIntegrationEnglish = {
  integrations: 'Integrations',
  integrationsDescription: 'Integrate your job boards and HRMS to seamlessly acquire candidates and onboard new employees.',
  title: 'HRMS',
  tooltipText: 'Click here to learn more about hrms integration',
  description: 'Setup your HRMS integrations to add hired candidates as employees to HRMS.',
  integrationInfo: {
    title: 'How to integrate your HRMS?',
    step1: 'Copy the API key from your HRMS',
    step2: 'Paste the API key',
    step3: 'Once integrated, you can directly add a hired candidate to your HRMS as an employee from Bayzat Hiring Management System'
  },
  integrationTable: {
    selectYourHrms: 'Select your HRMS',
    apiKey: 'API Key',
    integrationName: 'Integration Name',
    form: {
      selectYourHRMS: 'Please select your HRMS',
      inputApiKey: 'Please input your API key',
      inputIntegrationName: 'Please input your integration name',
      enterYourApiKey: 'Enter your API key',
      enterIntegrationName: 'Enter your integration name',
      subdomain: 'Enter HRMS subdomain'
    },
    addAnotherIntegration: 'Add another integration',
    integrate: 'Integrate'
  },
  jobBoards: 'Job boards',
  jobBoardsDescription: 'Setup your job boards to post jobs on various job boards via ATS.',
  linkedin: {
    title: 'LinkedIn',
    companyId: 'LinkedIn Company ID',
    enterCompanyId: 'Enter your company ID',
    step1: 'This will post all external published jobs from ATS to LinkedIn.',
    step2: 'Newly published jobs and updates to existing ones will be synced within 24 hours.',
    step3: 'Learn more about LinkedIn integration.',
    termsNote: 'Note: By posting your job to LinkedIn, you agree to',
    termsAndConditions: 'LinkedIn\'s Jobs Terms & Conditions',
    pleaseEnterLinkedInCompanyId: 'Please enter your linkedin company ID',
    successfullySaved: 'Successfully saved'
  }
}
