
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'WcIconSend',
  components: {
  },
  props: {
    color: {
      default: '#5138ee',
      type: String
    },
    size: {
      type: String,
      default: 'lg'
    }
  },
  computed: {
    handleSize() {
      switch (this.$props.size) {
        case 'lg': return 20
        case 'md': return 16
        case 'sm': return 14
        case 'xs': return 11
        default: return 20
      }
    }
  }
})
export default class extends Vue {

}
