export const OtpEnglish = {
  to: '<b>{companyName}</b>',
  otpSentToEmail: 'An email with the code has been sent to the above email. Please enter the code below to continue.',
  termsAndCondition: 'By continuing, you acknowledge that you have read and understood, and agree to Whitecarrot <a href="https://www.whitecarrot.io/terms" target="_blank"><b>Terms</b></a> of use and <a href="https://www.whitecarrot.io/privacy" target="_blank"><b>Privacy Policy</b></a>.',
  didntGetCode: 'Didn\'t get code or facing issues?',
  resendAllowIn: 'Resend allowed in {countdown}',
  loginTo: 'Log in to <b>{companyName}</b>',
  join: 'Join <b>{companyName}</b>',
  type: {
    signup: 'Sign up',
    login: 'Log in'
  },
  errorMessage: {
    otp: 'Please enter otp',
    accessDenied: 'Access Denied'
  }
}
export const OtpArabic = {
  to: '<b>{companyName}</b>',
  loginTo: 'تسجيل الدخول إلى <b>{companyName}</b>',
  otpSentToEmail: 'تم إرسال بريد إلكتروني بالرمز إلى البريد الإلكتروني أعلاه. يرجى إدخال الرمز أدناه للمتابعة.',
  termsAndCondition: 'بالمتابعة، فإنك تقر بأنك قد قرأت وفهمت، وتوافق على Whitecarrot <a href="https://www.whitecarrot.io/terms" target="_blank"><b>شروط الاستخدام</b></a> و <a href="https://www.whitecarrot.io/privacy" target="_blank"><b>سياسة الخصوصية</b></a>.',
  join: 'انضم إلى <b>{companyName}</b>',
  resendAllowIn: 'إعادة إرسال مسموح بها في {countdown}',
  didntGetCode: 'هل لم تتلقى الرمز أو تواجه مشكلة؟',
  type: {
    signup: 'التسجيل',
    login: 'تسجيل الدخول'
  },
  errorMessage: {
    otp: 'يرجى إدخال رمز التحقق',
    accessDenied: 'تم الرفض'
  }
}
