import { Route, RouteConfig } from 'vue-router'
import { checkRole } from '@/store/modules/permission'
import { RolesEnum } from '@/views/recruiter/models/roles-model'
import Layout from '@/layout/index.vue'

export const getAccessibleRoute = (): string => {
  if (checkRole(RolesEnum.WC_ADMIN)) {
    return '/admin/dashboard'
  } else if (checkRole(RolesEnum.COMPANY_ADMIN)) {
    return '/recruiter/home'
  } else if (checkRole(RolesEnum.COMPANY_INTERVIEWER)) {
    return '/recruiter/home'
  } else if (checkRole(RolesEnum.COMPANY_EMPLOYEE)) {
    return '/recruiter/employee-portal'
  }
  return ''
}

export const beforeEnterUser = (to: Route, from: Route, next): void => {
  const accessibleRoute = getAccessibleRoute()
  if (accessibleRoute) {
    next(accessibleRoute)
  } else {
    next()
  }
}

export const PUBLIC_ROUTES: RouteConfig[] = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
    beforeEnter: (to: Route, from: Route, next): void => {
      const accessibleRoute = getAccessibleRoute()
      if (accessibleRoute) {
        next(accessibleRoute)
      } else {
        next(`/login?redirect=${to.path}`)
      }
    }
  },
  {
    path: '/login',
    beforeEnter: beforeEnterUser,
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
    meta: {
      title: 'Whitecarrot ATS Login',
      hidden: true
    }
  },
  {
    path: '/signup',
    alias: '/signup/bayzat',
    beforeEnter: beforeEnterUser,
    component: () => import(/* webpackChunkName: "signup" */ '@/views/auth/signup.vue'),
    meta: {
      title: 'Signup',
      hidden: true
    }
  },
  {
    path: '/login/bayzat',
    beforeEnter: beforeEnterUser,
    component: () => import(/* webpackChunkName: "loginBayzat" */ '@/views/auth/Login.vue'),
    meta: {
      title: 'Bayzat ATS Login',
      hidden: true
    }
  },
  {
    path: '/logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/views/logout/UserLogout.vue'),
    meta: {
      title: 'Logout',
      hidden: true
    }
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/NotFound.vue'),
    meta: {
      title: 'Not Found',
      hidden: true
    }
  },
  {
    path: '/otp/user/:userId',
    component: () => import(/* webpackChunkName: "otpVerification" */ '@/views/auth/otp-verification.vue'),
    meta: {
      title: 'OTP Verify',
      hidden: true
    }
  },
  {
    path: '/not-found',
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/not-found.vue'),
    meta: {
      title: 'Not Found',
      hidden: true
    }
  },
  {
    path: '/impersonate',
    component: () => import(/* webpackChunkName: "impersonate" */ '@/views/auth/Impersonate.vue'),
    meta: {
      title: 'Impersonate',
      hidden: true
    }
  },
  {
    path: '/careers',
    component: Layout,
    redirect: '/recruiter',
    meta: { hidden: true },
    children: [
      {
        path: ':companyName',
        component: () => import(/* webpackChunkName: "careersHome" */ '@/views/careers/CareerView.vue'),
        meta: {
          hidden: false,
          title: 'Careers'
        }
      },
      {
        path: ':companyName/job/:id',
        component: () => import(/* webpackChunkName: "careerView" */ '@/views/careers/view/index.vue'),
        meta: {
          hidden: false,
          title: 'Careers'
        }
      }
    ]
  },
  {
    path: '/view-not-supported',
    component: () => import(/* webpackChunkName: "viewNotSupported" */ '@/views/MobileViewNotSupported.vue'),
    meta: {
      hidden: true,
      title: 'You need to use a desktop to access the application'
    }
  },
  {
    path: '/agency/:agencyId',
    component: () => import(/* webpackChunkName: "agencyPortal" */ '@/views/agencies/AgencyPortal.vue'),
    meta: {
      hidden: true,
      title: 'Agency Portal'
    }
  },
  {
    path: '/agency/:agencyId/job/:jobId',
    component: () => import(/* webpackChunkName: "candidateAgencyPortal" */ '@/views/agencies/SubmitCandidateAgencyPortal.vue'),
    meta: {
      hidden: true,
      title: 'Submit Candidate - Agency Portal'
    }
  },
  {
    path: '/library',
    component: () => import(/* webpackChunkName: "library" */ '@/views/library.vue'),
    meta: {
      title: 'Library',
      hidden: true
    }
  },
  {
    path: '/trail-ended',
    component: () => import(/* webpackChunkName: "trailEnded" */ '@/views/trail-ended.vue'),
    meta: {
      hidden: true,
      title: 'Trail ended'
    }
  }
]
