export const EmailComponentArabic = {
  templateTitle: 'عنوان النموذج',
  templateTitlePlaceholder: 'ادخل عنوان النموذج',
  fromPresets: {
    reachOut: 'يصبح التواصل أكثر فعالية عند استخدامك ايميل الشركة.',
    clickHereToSetup: 'اضغط هنا للإعداد.',
    emailTemplate: 'نموذج الايميل',
    searchTemplatesPlaceholder: 'ابحث في النماذج',
    bookingLink: 'رابط الحجز',
    searchBookingLinksPlaceholder: 'ابحث في روابط الحجز',
    variable: 'عامل متغير',
    presets: 'الإعدادات المسبقة',
    interviewBookingLinkPlaceholder: 'رابط حجز المقابلة',
    addVariable: 'أضف عامل متغير',
    from: 'من',
    addBookingLink: 'إضافة رابط الحجز'
  },
  subject: 'العنوان',
  addAttachments: 'أضف مرفقات',
  makeItSecret: 'اجعله سريًا',
  bodyPlaceholder: 'اكتب ايميلك هنا ',
  previous: 'السابق',
  sendingEmails: 'جارِ إرسال الايميلات...',
  sendEmail: 'إرسال ايميل',
  sendEmailWithOffer: 'إرسال ايميل مع عرض توظيف',
  archiveAndSend: 'أرشفة وإرسال',
  chooseCustomTime: 'اختر وقتًا محددًا',
  scheduleEmail: 'جدولة البريد الإلكتروني',
  selectDate: 'اختر التاريخ',
  selectTime: 'اختر الساعة',
  scheduleSend: 'جدولة الإرسال',
  createTemplate: 'إنشاء نموذج',
  saveAsTemplate: 'حفظ كقالب البريد الإلكتروني',
  saveAsNewEmailTemplate: 'حفظ كقالب بريد إلكتروني جديد',
  saveTemplate: 'حفظ القالب',
  updateTemplate: 'تحديث النموذج',
  cloneTemplate: 'قالب استنساخ',
  subjectIsRequiredError: 'العنوان مطلوب',
  bodyIsRequiredError: 'المحتوى مطلوب',
  recipientIsRequiredError: 'المستلم مطلوب',
  fromEmailIsRequiredError: 'ايميل المرسل مطلوب',
  toEmailPlaceholder: 'اختر ايميل المستلم',
  loadingFileAttachment: 'جارٍ معالجة الملفات المرفوعة. يرجى الانتظار.',
  deletingFileAttachment: 'جاري حذف الملف. يرجى الانتظار.',
  addNewTemplate: 'إضافة/تحرير قالب البريد الإلكتروني',
  actionLeadsDeletingPermanently: 'سيؤدي هذا الإجراء إلى حذف الملف من القالب نهائيًا.',
  actionLeadsRemoveFileWhileSendingEmail: 'سيؤدي هذا الإجراء إلى إزالة الملف أثناء إرسال البريد الإلكتروني',
  integrateEmailInfo: 'قم بتكامل بريدك الإلكتروني لإرسال البريد الإلكتروني من عنوان بريدك الإلكتروني.',
  clickHere: 'انقر هنا للتكامل',
  defaultPlaceholder: 'اكتب نصك هنا...',
  fileSizeRestriction: 'بعض الملفات التي قمت برفعها تتجاوز 5 ميجابايت. يرجى رفع الملفات بحجم أقل من 5 ميجابايت',
  contentExceedSizeInfo: 'المحتوى الذي أدخلته كبير جدًا. يرجى تقليله.',
  offerLinkMissing: 'رابط العرض مفقود في نص البريد الإلكتروني. يرجى إضافة رابط العرض من القائمة المنسدلة للمتغيرات',
  calendarNotIntegrated: 'التقويم غير مدمج.',
  integrateYourCalendar: 'يرجى دمج التقويم الخاص بك',
  inputLocation: 'يرجى إدخال الموقع',
  inputTitle: 'يرجى إدخال العنوان',
  enterBccMails: 'أدخل عناوين البريد الإلكتروني في Bcc',
  enterCcMails: 'أدخل عناوين البريد الإلكتروني في Cc',
  offerSendMailInfo: 'عند نقر المرشح على رابط العرض، سيفتح خطاب العرض في بوابة المرشح. يمكن للمرشح بعد ذلك عرض العرض إما التوقيع عليه أو رفضه.'
}

export const EmailComponentEnglish = {
  templateTitle: 'Template title',
  templateTitlePlaceholder: 'Enter template title',
  fromPresets: {
    reachOut: 'Reach outs are more effective when sent from your company email.',
    clickHereToSetup: 'Click here to setup.',
    emailTemplate: 'Email template',
    searchTemplatesPlaceholder: 'Search templates',
    bookingLink: 'Booking link',
    searchBookingLinksPlaceholder: 'Search booking links',
    variable: 'Variable',
    presets: 'Presets',
    interviewBookingLinkPlaceholder: 'Interview booking link',
    addVariable: 'Add Variable',
    from: 'From',
    addBookingLink: 'Add Booking link'
  },
  subject: 'Subject',
  addAttachments: 'Add attachments',
  makeItSecret: 'Make it secret',
  bodyPlaceholder: 'Write your email here...',
  previous: 'Previous',
  sendingEmails: 'Sending emails...',
  sendEmail: 'Send email',
  sendEmailWithOffer: 'Send email with offer',
  archiveAndSend: 'Archive and send',
  chooseCustomTime: 'Choose custom time',
  scheduleEmail: 'Schedule email',
  selectDate: 'Select date',
  selectTime: 'Select time',
  scheduleSend: 'Schedule send',
  createTemplate: 'Create template',
  saveAsTemplate: 'Save as email template',
  saveAsNewEmailTemplate: 'Save as new email template',
  saveTemplate: 'Save template',
  updateTemplate: 'Update template',
  cloneTemplate: 'Clone template',
  subjectIsRequiredError: 'Subject is required',
  bodyIsRequiredError: 'Body is required',
  recipientIsRequiredError: 'Recipient is required',
  fromEmailIsRequiredError: 'From email is required',
  toEmailPlaceholder: 'Select recipient\'s email',
  loadingFileAttachment: 'Processing the uploaded files. Please wait.',
  deletingFileAttachment: 'Deleting the file. Please wait.',
  addNewTemplate: 'Add/edit email template',
  actionLeadsDeletingPermanently: 'This action will delete the file from template.',
  actionLeadsRemoveFileWhileSendingEmail: 'This action will remove file while sending email',
  integrateEmailInfo: 'Integrate your email to send emails from your email address.',
  clickHere: 'Click here to integrate',
  defaultPlaceholder: 'Type your text here...',
  fileSizeRestriction: 'Few of your uploaded files exceeds 5mb. Please upload the files below 5mb',
  contentExceedSizeInfo: 'The content you\'ve entered is too large. Please reduce it.',
  offerLinkMissing: 'Offer link is missing in the email body. Please add the offer link from the variables drop-down',
  calendarNotIntegrated: 'Calendar not integrated.',
  integrateYourCalendar: 'Please integrate your calendar',
  inputLocation: 'Please input location',
  inputTitle: 'Please input title',
  enterBccMails: 'Enter Bcc mails',
  enterCcMails: 'Enter Cc mails',
  offerSendMailInfo: 'When the candidate clicks on the offer link, it will open the offer letter in the candidate portal. The candidate can then view the offer and either sign or reject it.'
}
