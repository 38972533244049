import Resource from '@/api/resource'
import request from '@/utils/request'
import { AttachmentsPayloadDto, CompanyEmailTemplatesEntity } from '@/views/company-email-templates/models'

class CompanyEmailTemplatesResource extends Resource<CompanyEmailTemplatesEntity> {
  constructor() {
    super('company-email-templates')
  }

  getTemplatesForCompany() {
    return request({
      url: '/' + this.uri + '/company',
      method: 'get'
    })
  }

  updateEmailTemplateAttachment(data: {attachments: AttachmentsPayloadDto[]}, id: string) {
    return request({
      url: '/' + this.uri + '/update-email-template-attachment/' + id,
      method: 'put',
      data
    })
  }
}

export { CompanyEmailTemplatesResource as default }
