export const LocationsAndDepartmentsEnglish = {
  title: 'Locations and departments',
  subTitle: 'Manage your office locations and departments that you can add while creating a job.',
  tooltipText: 'Click here to learn more about locations and departments',
  locations: {
    addNewLocation: 'Add new location',
    description: 'Save your office locations here to add them while creating a job description.',
    subTitle: 'Locations',
    locationName: 'Location name',
    addLocationName: 'Enter city, country',
    addAddress: 'Add address',
    cannotDelete: 'Jobs are linked to this location. You can’t delete it.',
    areYouSureToDelete: 'Are you sure you want to delete location',
    addressNote: 'Add an address to display this location when scheduling an in-person interview.',
    messages: {
      locationDeletedSuccessfully: 'Successfully deleted location',
      locationUpdatedSuccessfully: 'Successfully updated location',
      locationCreatedSuccessfully: 'Successfully created location'
    }
  },
  departments: {
    addNewDepartment: 'Add new department',
    description: 'Save your departments here to add them while creating a job description.',
    subTitle: 'Departments',
    departmentName: 'Department name',
    addDepartmentName: 'Add department name',
    headOfDept: 'Head of department',
    selectHeadOfDept: 'Select head of department',
    cannotDelete: 'Jobs are linked to this department, You can\'t delete it',
    areYouSureToDelete: 'Are you sure you want to delete department',
    messages: {
      departmentDeletedSuccessfully: 'Successfully deleted department',
      departmentUpdatedSuccessfully: 'Successfully updated department',
      departmentCreatedSuccessfully: 'Successfully created department'
    }
  }
}

export const LocationsAndDepartmentsArabic = {
  title: 'المكاتب والإدارات',
  subTitle: 'قم بإدارة مواقع المكاتب والإدارات التي يمكنك إضافتها أثناء إنشاء وظيفة.',
  tooltipText: 'انقر هنا لمعرفة المزيد عن المواقع والإدارات',
  locations: {
    addNewLocation: 'أضف موقع جديد',
    description: 'احفظ مواقع مكاتبك هنا لإضافتها عند إنشاء وصف وظيفي.',
    subTitle: 'المكاتب',
    locationName: 'اسم الموقع',
    addLocationName: 'أضف اسم الموقع',
    addAddress: 'أضف العنوان',
    cannotDelete: 'الوظائف مرتبطة بهذا الموقع. لا يمكنك حذفه.',
    updatedLocation: 'تم تحديث الموقع بنجاح',
    createdLocation: 'تم إنشاء الموقع بنجاح',
    areYouSureToDelete: 'هل أنت متأكد أنك تريد حذف الموقع؟',
    addressNote: 'أضف عنوانًا لعرض هذا الموقع عند جدولة مقابلة شخصية.',
    messages: {
      locationDeletedSuccessfully: 'تم حذف الموقع بنجاح',
      locationUpdatedSuccessfully: 'تم تحديث الموقع بنجاح',
      locationCreatedSuccessfully: 'تم إنشاء الموقع بنجاح'
    }
  },
  departments: {
    addNewDepartment: 'أضف قسم جديد',
    description: 'احفظ أقسامك هنا لإضافتها عند إنشاء وصف وظيفي.',
    subTitle: 'الأقسام',
    departmentName: 'اسم القسم',
    addDepartmentName: 'أضف اسم القسم',
    headOfDept: 'رئيس القسم',
    selectHeadOfDept: 'اختر رئيس القسم',
    cannotDelete: 'الوظائف مرتبطة بهذا القسم، لا يمكنك حذفه',
    areYouSureToDelete: 'هل أنت متأكد أنك تريد حذف القسم؟',
    messages: {
      departmentDeletedSuccessfully: 'تم حذف القسم بنجاح',
      departmentUpdatedSuccessfully: 'تم تحديث القسم بنجاح',
      departmentCreatedSuccessfully: 'تم إنشاء القسم بنجاح'
    }
  }
}
