export const SendOfferEnglish = {
  title: 'Go back to candidate profile',
  goBack: 'candidate profile',
  preview: 'Preview',
  sendEmail: 'Send with email',
  offerDetailsAndPreview: 'Generate and preview offer letter',
  offerDetail: {
    description: 'Select offer template and add actual data to replace with variables in the offer letter document',
    select: 'Select offer letter template',
    placeholder: 'Select template',
    addTemplate: 'Add new offer template',
    addAgency: 'Add new agency',
    refresh: 'Refresh offer templates list',
    addVariables: 'Add offer variables information',
    save: 'Save and proceed',
    noVariables: 'You haven’t added any variables in the document.',
    generateAndPreview: 'Generate and preview offer letter',
    askCandidateToFill: 'Ask candidate to fill'
  },
  messages: {
    refresh: 'Unable to fetch the offer template. Please refresh.',
    missingId: 'Application id is missing from the url',
    noTemplate: 'Template not found',
    uploadOffer: 'Please upload offer letter',
    sentOffer: 'Offer sent successfully',
    noId: 'Application id not found'
  },
  placeholders: {
    enter: 'Enter {label} ',
    input: 'Please input',
    select: 'Select {label}',
    pleaseSelect: 'Please select'
  },
  offerPreview: {
    title: 'Offer letter preview',
    description: 'Review the offer letter. If you want to make any changes, download and replace the updated version',
    noPreview: 'No preview available.',
    noPreviewDesc: 'Select offer letter template and add the variable information to generate the offer letter preview'
  },
  offerSendEmail: {
    description: 'Select email template to send to candidate along with the offer letter',
    sendEmail: 'Send email',
    sendEmailDesc: 'Send email to candidate with the offer letter'
  },
  eSig: {
    info: 'Drag the signature box below and drop it in the document where you want the candidate to sign.',
    noSignatures: 'No signatures added'
  },
  askCandidateToFill: 'Ask candidate to fill',
  candidateSig: 'Add e-sign location for candidates',
  candidateSigInfo: 'Click on the "Add e-signature" button to choose the position where you need the candidate to sign in the offer letter.',
  addESig: 'Add candidate e-signature location in the offer document',
  candidateOffer: {
    congrats: '🎉 Congratulations, you got the offer!',
    info: 'Please take a moment to review and sign the offer letter below.👇',
    uploadSignedCopy: 'Upload signed copy',
    downloadOffer: 'Download offer',
    dropYourSignature: 'Drop your signature here, or',
    supportedTypes: 'Supports: JPG, JPEG, PNG. Max file size: 200KB',
    acceptOfferTitle: 'Are you sure you want to accept this offer?',
    acceptOfferInfo: 'You have chosen to accept the offer for {jobName} role at {companyName}',
    acceptOffer: 'Accept offer',
    rejectOffer: 'Reject offer',
    rejectionReason: 'Rejection reason *',
    enterReason: 'Enter your reason',
    comments: 'Comments',
    enterComments: 'Write your comments for offer rejection',
    enterReasonForRejection: 'Please enter the reason for rejection',
    fillAndSign: 'Fill details and sign your offer',
    signYourOffer: 'Sign your offer',
    fillDetails: 'Fill offer details',
    fileSizeError: 'File size should be less than or equal to {size}',
    offerAccepted: 'Offer accepted successfully',
    offerRejected: 'Offer rejected successfully',
    signAndPreview: 'Sign and preview',
    signOffer: 'Sign offer',
    accept: {
      title: '🎉 Congratulations, you have accepted the offer!',
      downloadOfferLetter: 'Download offer letter',
      acceptOfferInfo: 'You have chosen to accept the offer for {jobName} role at {companyName} on {actionDoneOn}.',
      checkbox: 'I have read and understood the terms and conditions of the offer letter and hereby accept the offer.'
    },
    reject: {
      title: 'You have rejected the offer',
      rejectOfferInfo: 'You have chosen to reject the offer for {jobName} role at {companyName} on {actionDoneOn}',
      checkbox: 'I have decided to decline the offer. I understand that this action cannot be undone.'
    },
    saveAndPreview: 'Save & Preview'
  }
}

export const SendOfferArabic = {
  title: 'العودة إلى ملف المرشح',
  goBack: 'ملف المرشح',
  preview: 'معاينة',
  sendEmail: 'إرسال عبر البريد الإلكتروني',
  offerDetailsAndPreview: 'إنشاء ومعاينة خطاب العرض',
  offerDetail: {
    description: 'حدد قالب العرض وأضف البيانات الفعلية لتحل محل المتغيرات في مستند رسالة العرض',
    select: 'اختر قالب خطاب العرض',
    placeholder: 'حدد القالب',
    addTemplate: 'إضافة قالب عرض جديد',
    addAgency: 'إضافة وكالة جديدة',
    refresh: 'تحديث قائمة قوالب العرض',
    addVariables: 'إضافة معلومات المتغيرات للعرض',
    save: 'حفظ والمتابعة',
    noVariables: 'لم تقم بإضافة أي متغيرات في المستند.',
    generateAndPreview: 'إنشاء ومعاينة خطاب العرض',
    askCandidateToFill: 'اطلب من المرشح أن يملأ'
  },
  messages: {
    refresh: 'غير قادر على جلب قالب العرض. يرجى التحديث.',
    missingId: 'معرف الطلب مفقود من عنوان URL',
    noTemplate: 'القالب غير موجود',
    uploadOffer: 'يرجى تحميل رسالة العرض',
    sentOffer: 'تم إرسال العرض بنجاح',
    noId: 'معرف الطلب غير موجود'
  },
  placeholders: {
    enter: 'أدخل {label}',
    input: 'الرجاء إدخال',
    select: 'اختر {label}',
    pleaseSelect: 'الرجاء التحديد'
  },
  offerPreview: {
    title: 'معاينة خطاب العرض',
    description: 'راجع خطاب العرض. إذا كنت ترغب في إجراء أي تغييرات، قم بتنزيل واستبدال النسخة المحدثة',
    noPreview: 'لا توجد معاينة متاحة.',
    noPreviewDesc: 'اختر قالب خطاب العرض وأضف معلومات المتغيرات لإنشاء معاينة خطاب العرض'
  },
  offerSendEmail: {
    description: 'حدد قالب البريد الإلكتروني لإرساله إلى المرشح مع رسالة العرض',
    sendEmail: 'إرسال البريد الإلكتروني',
    sendEmailDesc: 'إرسال البريد الإلكتروني للمرشح مع خطاب العرض'
  },
  eSig: {
    info: 'اسحب مربع التوقيع أدناه وأسقطه في المستند حيث تريد أن يوقع المرشح.',
    noSignatures: 'لم تتم إضافة توقيعات'
  },
  askCandidateToFill: 'اطلب من المرشح تعبئة',
  candidateSig: 'أضف موقع التوقيع الإلكتروني للمرشحين',
  candidateSigInfo: 'انقر على زر "إضافة توقيع إلكتروني" لاختيار الموضع الذي تحتاج فيه المرشح لتوقيع خطاب العرض.',
  addESig: 'أضف موقع التوقيع الإلكتروني للمرشح في مستند العرض',
  candidateOffer: {
    congrats: '🎉 تهانينا، لقد حصلت على العرض!',
    info: 'يرجى أخذ لحظة لمراجعة وتوقيع خطاب العرض أدناه.👇',
    uploadSignedCopy: 'تحميل النسخة الموقعة',
    downloadOffer: 'تحميل العرض',
    dropYourSignature: 'إسقاط توقيعك هنا، أو',
    supportedTypes: 'يدعم: JPG، JPEG، PNG. الحد الأقصى لحجم الملف: 200 كيلوبايت',
    acceptOfferTitle: 'هل أنت متأكد أنك تريد قبول هذا العرض؟',
    acceptOfferInfo: 'لقد اخترت قبول العرض لدور {jobName} في {companyName}',
    acceptOffer: 'قبول العرض',
    rejectOffer: 'رفض العرض',
    rejectionReason: 'سبب الرفض *',
    enterReason: 'أدخل السبب',
    comments: 'التعليقات',
    enterComments: 'اكتب تعليقاتك لرفض العرض',
    enterReasonForRejection: 'يرجى إدخال سبب الرفض',
    fillAndSign: 'املأ التفاصيل ووقع عرضك',
    signYourOffer: 'وقع عرضك',
    fillDetails: 'املأ تفاصيل العرض',
    fileSizeError: 'يجب أن يكون حجم الملف أقل من أو يساوي {size}',
    offerAccepted: 'تم قبول العرض بنجاح',
    offerRejected: 'تم رفض العرض بنجاح',
    signAndPreview: 'وقع ومعاينة',
    signOffer: 'توقيع العرض',
    accept: {
      title: '🎉 تهانينا، لقد قبلت العرض!',
      downloadOfferLetter: 'تحميل خطاب العرض',
      acceptOfferInfo: 'لقد اخترت قبول العرض لدور {jobName} في {companyName} في {actionDoneOn}.',
      checkbox: 'لقد قرأت وفهمت الشروط والأحكام الواردة في خطاب العرض وأقبل العرض بموجب هذا.'
    },
    reject: {
      title: 'لقد رفضت العرض',
      rejectOfferInfo: 'لقد اخترت رفض العرض لدور {jobName} في {companyName} في {actionDoneOn}',
      checkbox: 'لقد قررت رفض العرض. أفهم أن هذا الإجراء لا يمكن التراجع عنه.'
    },
    saveAndPreview: 'حفظ ومعاينة'
  }
}
