export const ListOffersEnglish = {
  title: 'Offer letter templates',
  subTitle: 'Create offer templates to generate and send your offer letters to candidates in a few clicks.',
  tooltipText: 'Click here to learn more about offer templates',
  addButton: 'Add new template',
  templateName: 'TEMPLATE NAME',
  noListDescription: 'Created offer templates will be listed here.',
  noOfferLetterTemplatesTitle: 'No offer letter templates created yet!',
  noOfferLetterTemplatesDescription: 'All the offer letter templates created will show up here!',
  createNewOfferLetterTemplate: 'Create new offer letter template',
  dialog: {
    deleteTitle: 'Are you sure?',
    description: 'Are you sure you want to delete this offer template?',
    preview: 'Preview',
    lastActivity: 'Last updated by {recruiterName} on {lastActivity}',
    cloneOfferTemplate: 'Clone offer template'
  },
  successMessages: {
    deleted: 'Offer template deleted successfully.',
    created: 'Offer template created successfully',
    updated: 'Offer template updated successfully'
  },
  createOffer: {
    backTo: 'Back to',
    step1: {
      title: 'Step 1: Add template name',
      description: 'Give the template a unique name to help identify it in the future, eg: Sales Manager Offer.',
      name: 'Template name',
      placeholder: 'Enter template name',
      rule: 'Please input template name',
      lengthRule: 'Length should be at least {length} letters',
      addTemplate: 'Add offer letter template'
    },
    step2: {
      title: 'Prepare your offer template with variables',
      description: 'Download a sample offer template or prepare a template with variables based on your previous offers',
      note: 'Please note:',
      variables: 'Identified variables',
      variablesInfo: 'Review and adjust the variable types as needed. These will replace with actual values while creating the offer letter for each candidate.',
      download: 'Download sample offer template',
      noVariables: 'You haven’t added any variables in the document.'
    },
    table: {
      variable: 'VARIABLE',
      type: 'VARIABLE TYPE',
      supportsFill: 'Supports auto-fill?'
    },
    step3: {
      title: 'Step 3: Upload offer template',
      description: 'Upload your company offer letter template document',
      showPreview: 'Show preview',
      offerDocument: 'Offer document',
      replaceFile: 'Replace file',
      fileSize: 'File size is greater than {var} MB',
      upload: 'File uploaded on {date}',
      allowedFile: 'Only .docx file is allowed, maximum {count} file.',
      processing: 'Processing the uploaded file. Please wait.',
      supports: 'Supports:',
      maxSize: 'The maximum size per file is {var} mb',
      dropDocument: 'Drop your document here, or'
    },
    downloadWord: 'Download as word file',
    downloadPdf: 'Download as PDF',
    addESignature: 'Add e-sign',
    replaceESignature: 'Relocate e-sign'
  },
  offerTemplatePreview: 'Offer letter template preview',
  eSign: 'Add an e-signature option in the offer letter while sending it.',
  eSignInfo: 'While sending the offer letter to the candidate, you can select the specific location within the document where the candidate is required to e-sign.',
  candidate: {
    offer: {
      noSignAndFill: `<div class="text-xs p-3 rounded-md" style="background-color: rgba(81, 56, 238, 0.08)">
          <p class="font-bold mb-2">How to accept the offer?</p>
          <ul class="list-disc list-inside space-y-1">
            <li>Read the offer letter carefully.</li>
            <li><a href="{offerLink}" target="_blank" class="text-wcRoyalBlue underline">Download the offer letter.</a></li>
            <li>Sign the offer letter at all designated places.
              <ul class="list-disc list-inside ml-4 space-y-1">
                <li>You can use an <a href="https://smallpdf.com/sign-pdf" target="_blank" class="text-wcRoyalBlue underline">online e-sign tool</a></li>
                <li>Or print and sign it</li>
              </ul>
            </li>
            <li>Upload the signed offer document.</li>
            <li>Review the uploaded offer letter to ensure all details are correct.</li>
            <li>Find and click the checkbox to confirm the terms of the offer at the bottom of your screen.</li>
            <li>Click "Confirm & Accept" at the bottom of the page to finalize your offer acceptance.</li>
          </ul>
        </div>`,
      noSignButFill: `
      <div class="text-xs p-3 rounded-md" style="background-color: rgba(81, 56, 238, 0.08)">
        <p class="font-bold mb-2">How to accept the offer?</p>
        <ul class="list-disc list-inside space-y-1">
          <li>Read the offer letter carefully.</li>
          <li>Fill the offer variables</li>
          <li><a href="{offerLink}" target="_blank" class="text-wcRoyalBlue underline">Download the offer letter.</a></li>
          <li>Sign the offer letter at all designated places.
            <ul class="list-disc list-inside ml-4 space-y-1">
              <li>You can use an <a href="https://smallpdf.com/sign-pdf" target="_blank" class="text-wcRoyalBlue underline">online e-sign tool</a></li>
              <li>Or print and sign it</li>
            </ul>
          </li>
          <li>Upload the signed offer document.</li>
          <li>Review the uploaded offer letter to ensure all details are correct.</li>
          <li>Find and click the checkbox to confirm the terms of the offer at the bottom of your screen.</li>
          <li>Click "Confirm & Accept" at the bottom of the page to finalize your offer acceptance.</li>
        </ul>
      </div>
      `,
      signAndFill: `
      <div class="text-xs p-3 rounded-md" style="background-color: rgba(81, 56, 238, 0.08)">
        <p class="font-bold mb-2">How to accept the offer?</p>
        <ul class="list-disc list-inside space-y-1">
          <li>Read the offer letter carefully.</li>
          <li>Sign the offer letter.</li>
          <li>Review the offer letter to ensure all details are correct.</li>
          <li>Find and click the checkbox to confirm the terms of the offer at the bottom of your screen.</li>
          <li>Click "Confirm & Accept" at the bottom of the page to finalize your offer acceptance.</li>
        </ul>
      </div>
      `
    }
  },
  replaceOfferDetails: 'Replace offer details',
  uploadSignedOfferInfo: 'Please upload the signed offer letter'
}

export const ListOffersArabic = {
  title: 'قوالب رسائل العرض',
  subTitle: 'أنشئ قوالب عروض لإنشاء وإرسال رسائل العرض الخاصة بك إلى المرشحين ببضع نقرات.',
  tooltipText: 'انقر هنا للتعرف على المزيد حول قوالب العروض',
  addButton: 'إضافة قالب جديد',
  templateName: 'اسم القالب',
  noListDescription: 'سيتم سرد القوالب التي تم إنشاؤها هنا.',
  noOfferLetterTemplatesTitle: 'لم يتم إنشاء قوالب خطابات العرض بعد!',
  noOfferLetterTemplatesDescription: 'جميع قوالب خطابات العرض التي تم إنشاؤها ستظهر هنا!',
  createNewOfferLetterTemplate: 'إنشاء قالب خطاب عرض جديد',
  dialog: {
    deleteTitle: 'هل أنت متأكد؟',
    description: 'هل أنت متأكد أنك تريد حذف قالب العرض هذا؟',
    preview: 'معاينة',
    lastActivity: 'آخر تحديث بواسطة {recruiterName} في {lastActivity}',
    cloneOfferTemplate: 'استنساخ قالب العرض'
  },
  successMessages: {
    deleted: 'تم حذف قالب العرض بنجاح.',
    created: 'تم إنشاء قالب رسالة العرض بنجاح',
    updated: 'تم تحديث قالب رسالة العرض بنجاح'
  },
  createOffer: {
    backTo: 'العودة إلى',
    step1: {
      title: 'الخطوة 1: إضافة اسم القالب',
      description: 'قم بإعطاء القالب اسمًا فريدًا للمساعدة في تحديده في المستقبل، مثل: عرض مدير المبيعات.',
      name: 'اسم القالب',
      placeholder: 'أدخل اسم القالب',
      rule: 'الرجاء إدخال اسم القالب',
      lengthRule: 'يجب أن يكون الطول على الأقل {length} حروف',
      addTemplate: 'إضافة قالب رسالة العرض'
    },
    step2: {
      title: 'قم بتحضير قالب العرض الخاص بك مع المتغيرات',
      description: 'قم بتحميل قالب عرض عيني أو إعداد قالب مع المتغيرات استنادًا إلى العروض السابقة',
      note: 'يرجى ملاحظة',
      variables: 'المتغيرات المُحدّدة',
      variablesInfo: 'راجع واضبط أنواع المتغيرات حسب الحاجة. سيتم استبدالها بالقيم الفعلية أثناء إنشاء خطاب العرض لكل مرشح.',
      download: 'تحميل قالب عرض عيني',
      noVariables: 'لم تقم بإضافة أي متغيرات في الوثيقة.'
    },
    table: {
      variable: 'المتغير',
      type: 'نوع المتغير',
      supportsFill: 'هل يدعم التعبئة التلقائية؟'
    },
    step3: {
      title: 'الخطوة 3: قم بتحميل قالب عرض الشركة',
      description: 'قم بتحميل مستند قالب رسالة عرض الشركة الخاص بك',
      showPreview: 'عرض المعاينة',
      replaceFile: 'استبدال الملف',
      fileSize: 'حجم الملف أكبر من {var} ميجابايت',
      upload: 'تم تحميل الملف في {date}',
      allowedFile: 'يُسمح بملف .docx فقط، الحد الأقصى {count} ملف.',
      processing: 'جارٍ معالجة الملف المحمّل. يرجى الانتظار.',
      supports: 'يدعم:',
      maxSize: 'الحجم الأقصى لكل ملف هو {var} ميجابايت',
      dropDocument: 'اسحب مستندك هنا، أو'
    },
    downloadWord: 'تحميل ملف Word',
    downloadPdf: 'تنزيل كملف PDF',
    addESignature: 'إضافة توقيع إلكتروني',
    replaceESignature: 'إعادة تحديد موقع التوقيع الإلكتروني'
  },
  offerTemplatePreview: 'معاينة قالب خطاب العرض',
  eSign: 'أضف خيار التوقيع الإلكتروني في خطاب العرض عند إرساله.',
  eSignInfo: 'أثناء إرسال خطاب العرض إلى المرشح، يمكنك تحديد الموقع المحدد داخل المستند حيث يتعين على المرشح التوقيع إلكترونياً.',
  candidate: {
    offer: {
      noSignAndFill: `<div class="text-xs p-3 rounded-md" style="background-color: rgba(81, 56, 238, 0.08)">
        <p class="font-bold mb-2">كيفية قبول العرض؟</p>
        <ul class="list-disc list-inside space-y-1">
          <li>اقرأ خطاب العرض بعناية.</li>
          <li><a href="{offerLink}" target="_blank" class="text-wcRoyalBlue underline">قم بتنزيل خطاب العرض.</a></li>
          <li>وقّع على خطاب العرض في جميع الأماكن المخصصة.
            <ul class="list-disc list-inside ml-4 space-y-1">
              <li>يمكنك استخدام <a href="https://smallpdf.com/sign-pdf" target="_blank" class="text-wcRoyalBlue underline">أداة التوقيع الإلكتروني عبر الإنترنت</a></li>
              <li>أو قم بطباعته والتوقيع عليه</li>
            </ul>
          </li>
          <li>قم بتحميل مستند العرض الموقع.</li>
          <li>راجع خطاب العرض الذي تم تحميله للتأكد من صحة جميع التفاصيل.</li>
          <li>ابحث وانقر على خانة الاختيار لتأكيد شروط العرض في أسفل الشاشة.</li>
          <li>انقر على "تأكيد وقبول" في أسفل الصفحة لإكمال قبول العرض الخاص بك.</li>
        </ul>
      </div>`,
      noSignButFill: `
      <div class="text-xs p-3 rounded-md" style="background-color: rgba(81, 56, 238, 0.08)">
        <p class="font-bold mb-2">كيفية قبول العرض؟</p>
        <ul class="list-disc list-inside space-y-1">
          <li>اقرأ خطاب العرض بعناية.</li>
          <li>املأ متغيرات العرض</li>
          <li><a href="{offerLink}" target="_blank" class="text-wcRoyalBlue underline">قم بتنزيل خطاب العرض.</a></li>
          <li>وقّع على خطاب العرض في جميع الأماكن المخصصة.
            <ul class="list-disc list-inside ml-4 space-y-1">
              <li>يمكنك استخدام <a href="https://smallpdf.com/sign-pdf" target="_blank" class="text-wcRoyalBlue underline">أداة التوقيع الإلكتروني عبر الإنترنت</a></li>
              <li>أو قم بطباعته والتوقيع عليه</li>
            </ul>
          </li>
          <li>قم بتحميل مستند العرض الموقع.</li>
          <li>راجع خطاب العرض الذي تم تحميله للتأكد من صحة جميع التفاصيل.</li>
          <li>ابحث وانقر على خانة الاختيار لتأكيد شروط العرض في أسفل الشاشة.</li>
          <li>انقر على "تأكيد وقبول" في أسفل الصفحة لإكمال قبول العرض الخاص بك.</li>
        </ul>
      </div>

      `,
      signAndFill: `
      <div class="text-xs p-3 rounded-md" style="background-color: rgba(81, 56, 238, 0.08)">
        <p class="font-bold mb-2">كيفية قبول العرض؟</p>
        <ul class="list-disc list-inside space-y-1">
          <li>اقرأ خطاب العرض بعناية.</li>
          <li>وقّع على خطاب العرض.</li>
          <li>راجع خطاب العرض للتأكد من صحة جميع التفاصيل.</li>
          <li>ابحث وانقر على خانة الاختيار لتأكيد شروط العرض في أسفل الشاشة.</li>
          <li>انقر على "تأكيد وقبول" في أسفل الصفحة لإكمال قبول العرض الخاص بك.</li>
        </ul>
      </div>
      `
    }
  },
  replaceOfferDetails: 'استبدال تفاصيل العرض',
  uploadSignedOfferInfo: 'يرجى تحميل عرض العمل الموقع'
}
