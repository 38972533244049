export const ProfileDropdownArabic = {
  company: 'الشركة',
  companyBrand: 'صفحة العلامة التجارية والمهنية',
  teamMembers: 'أعضاء الفريق',
  recruiting: 'التوظيف',
  defaultPipeline: 'الأنبوب الافتراضي',
  emailTemplates: 'قوالب البريد الإلكتروني',
  interviewBookingLinks: 'روابط حجز المقابلات',
  interviewScorecardTemplates: 'قوالب ردود الفعل المقابلة',
  personal: 'الشخصي',
  calendar: 'التقويم وتكامل البريد الإلكتروني',
  profile: 'الملف الشخصي',
  resources: 'الموارد',
  downloadChromeExtension: 'تحميل إضافة كروم',
  helpCenter: 'مركز المساعدة',
  logout: 'تسجيل الخروج'
}

export const ProfileDropdownEnglish = {
  company: 'COMPANY',
  companyBrand: 'Brand & Career page',
  teamMembers: 'Team members',
  recruiting: 'RECRUITING',
  defaultPipeline: 'Default pipeline',
  emailTemplates: 'Email templates',
  interviewBookingLinks: 'Interview booking links',
  interviewScorecardTemplates: 'Interview feedback templates',
  personal: 'PERSONAL',
  calendar: 'Calendar & email integration',
  profile: 'Profile',
  resources: 'RESOURCES',
  downloadChromeExtension: 'Download chrome extension',
  helpCenter: 'Help center',
  logout: 'Logout'
}
