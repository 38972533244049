
import { Component, Prop, Vue } from 'vue-property-decorator'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import WcIconExternalLink from '@/components/UILibrary/Icons/WcIconExternalLink.vue'
import WcIconWhatsApp from '@/components/UILibrary/Icons/WcIconWhatsApp.vue'
import WcIconFeedback from '@/components/UILibrary/Icons/WcIconFeedback.vue'
import WcIconArrowLeftFull from '@/components/UILibrary/Icons/WcIconArrowLeftFull.vue'
import WcIconSend from '@/components/UILibrary/Icons/WcIconSend.vue'
import LoadingAnimation from '@/components/UILibrary/loading-animation.vue'
import WcIconCross from '@/components/UILibrary/Icons/WcIconCross.vue'
import FullScreenLoading from '@/components/UILibrary/full-screen-loading.vue'
import { formatDate } from '@/utils/date'
import WcIconLike from '@/components/UILibrary/Icons/WcIconLike.vue'
import WcIconUnlike from '@/components/UILibrary/Icons/WcIconUnlike.vue'
import ColoredAlphabetIcon from '@/components/UILibrary/Icons/ColoredAlphabetIcon.vue'
import { UserModule } from '@/store/modules/user'
import { isMobile } from '@/utils/general'
import WcIconHamburger from '@/components/UILibrary/Icons/WcIconHamburger.vue'

@Component({
  name: 'ChatThreadSection',
  methods: {
    isMobile,
    UserModule() {
      return UserModule
    }
  },
  components: {
    WcIconHamburger,
    ColoredAlphabetIcon,
    WcIconUnlike,
    WcIconLike,
    FullScreenLoading,
    WcIconCross,
    LoadingAnimation,
    WcIconSend,
    WcIconArrowLeftFull,
    WcIconFeedback,
    WcIconWhatsApp,
    WcIconExternalLink,
    WcTypography
  }
})
export default class extends Vue {
  @Prop({ required: true, type: Function }) handleDialogClose!: Function;
  @Prop({ required: true, type: Function }) sendQuery!: Function;
  @Prop({ required: true, type: Function }) submitFeedback!: Function;
  @Prop({ required: true, type: Function }) sendSelectedPromptAsQuery!: Function;
  @Prop({ required: true }) suggestedPrompts!: string[]
  @Prop({ required: true }) chatHistory!: {
  id: string // messageId
  conversationId: string
  query: string
  answer: string
  createdAt: string
  role: string
}[]

  @Prop({ required: true }) currentThreadId!: string
  @Prop({ required: true }) userQuery!: string
  @Prop({ required: true }) chatThreadHistoryLoading!: boolean

  inputQuery = '';

  sendInputQuery() {
    const data = this.inputQuery
    this.inputQuery = ''
    this.$emit('input', data)
  }

  sendQueryIfUserClickedEnter(event: Event) {
    this.inputQuery = ''
    this.$emit('input', event)
  }

  formatTime(timestamp: string) {
    // Create a Date object from the timestamp (converted to milliseconds)
    let date = new Date()
    if (timestamp !== 'Now') {
      date = new Date(parseInt(timestamp) * 1000)
    }

    // Format the time as a string in local time
    return formatDate(date, 'MMM DD, YYYY h:mm A')
  }
}
